import React, { } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function TopUp({
  onClose,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'modal.whatWeDo' });
  const ITEMS = [
    {
      id: 1,
      title: t('1'),
    },
    {
      id: 2,
      title: t('2'),
    },
    {
      id: 3,
      title: t('3'),
    },
    {
      id: 4,
      title: t('4'),
    },
    {
      id: 5,
      title: t('5'),
    },
    {
      id: 6,
      title: t('6'),
    },
    {
      id: 7,
      title: t('7'),
    },
    {
      id: 8,
      title: t('8'),
    },
    {
      id: 9,
      title: t('9'),
    },
    {
      id: 10,
      title: t('10'),
    },
    {
      id: 11,
      title: t('11'),
    },
  ];
  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-what-we-do"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="items">
          {ITEMS.map(item => (
            <div className="item">
              <div className="index">{item.id}</div>
              <div className="dots" />
              <div className="title">{item.title}</div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
