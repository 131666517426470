import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useToastContext } from 'auto-design-common';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import useTranslation from '../../hooks/useTranslation';

export default function DeleteLocation({
  id,
  onClose,
  onDone,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tDeleteLocationModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.deleteLocation' });

  const [submitting, setSubmitting] = useState(false);
  const { toastError } = useToastContext();
  const { request } = useAppContext();

  return (
    <Modal
      show
      backdrop="static"
      id="modal-delete-location"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tDeleteLocationModal('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {tDeleteLocationModal('content_1')}
        </div>
        <div>
          {tDeleteLocationModal('content_2')}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onClose}
        >
          {tCommon('cancel')}
        </Button>
        <Button
          variant="danger"
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);
            const { success, error } = await request(api.deleteLocation(id));

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onDone && onDone();
              onClose();
            }
          }}
        >
          {tCommon('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
