import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import NextButton from 'components/Common/NextButton';
import backArrow from 'assets/images/ic-line-arrow-left.svg';
import emptyAvatar from 'assets/images/empty-avatar.jpeg';
import eyeIcon from 'assets/images/ic-fill-eye.svg';
import eyeOffIcon from 'assets/images/ic-fill-eye-off.svg';
import { useAppContext } from 'components/AppContext';
import InputWithRightIcon from 'components/Common/InputWithRightIcon';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import AuthWrapper from './AuthWrapper';
import useTranslation from '../../hooks/useTranslation';

export default function Login() {
  const { tLogin, tError, tCommon } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { login } = useAppContext();
  const { email, avatar } = useMemo(() => QueryString.parse(location.search), [location]);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { showModal } = useModalContext();

  const handleLogin = async () => {
    setError(null);

    const { success, error } = await login(email, password);

    if (!success) {
      setError(error.data?.message || tError('somethingWentWrong'));
    }
  };

  return (
    <AuthWrapper>
      <div className="actions">
        <img
          alt="back"
          src={backArrow}
          className="u-cursorPointer"
          onClick={() => {
            history.replace('/get-started');
          }}
        />
        <div className="user-info">
          <img
            alt="avatar"
            src={avatar || emptyAvatar}
          />
          <span className="email">
            {email}
          </span>
        </div>
      </div>
      <div className="header">
        <div className="title">{tLogin('yayWeFoundYou')}</div>
        <div className="sub-title">{tLogin('enterYourPasswordToCompleteLogin')}</div>
      </div>

      <div className="body">
        <Form.Group className="form-group" hasValidation>
          <Form.Label>{tCommon('password')}</Form.Label>
          <InputWithRightIcon
            placeholder={tLogin('enterYourPassword')}
            type={showPassword ? 'text' : 'password'}
            isInvalid={!!error}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              setError(null);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
            icon={(
              <img
                alt="showPassword"
                className="u-cursorPointer"
                src={showPassword ? eyeOffIcon : eyeIcon}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            )}
          />
          {error && (
            <Form.Text className="u-textNegative">
              {error}
            </Form.Text>
          )}
        </Form.Group>

        <div
          className="forgot-password"
          onClick={() => {
            showModal(ModalKey.RESET_PASSWORD, {
              defaultEmail: email,
            });
          }}
        >
          {tLogin('forgotYourPassword')}
        </div>

        <NextButton
          text={tCommon('login')}
          className="submit-button"
          onClick={handleLogin}
          disabled={password.trim() === ''}
        />
      </div>
    </AuthWrapper>
  );
}
