import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Sidebar from 'components/Common/Sidebar';
import Footer from 'components/Common/Footer';
import Empty from './Empty';
import Location from './Location';

export default function Locations() {
  return (
    <div className="main locations">
      <Sidebar />
      <div className="right-part">
        <div className="content">
          <Switch>
            <Route path="/locations/empty">
              <Empty />
            </Route>
            <Route path="/locations/:locationId">
              <Location />
            </Route>
            <Redirect to="/locations/empty" />
          </Switch>
        </div>
        <Footer />
      </div>
    </div>
  );
}
