import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';
import copy from 'copy-to-clipboard';
import { Icon, useToastContext } from 'auto-design-common';
import successBanner from 'assets/images/success-banner.svg';
import facebookIcon from 'assets/images/facebook.svg';
import mailIcon from 'assets/images/mail.svg';
import linkedinIcon from 'assets/images/linkedin.svg';
import copyLinkIcon from 'assets/images/copy-link.svg';
import qrIcon from 'assets/images/qr-code.svg';
import useTranslation from '../../hooks/useTranslation';

function SocialButton({
  icon,
  onClick,
}) {
  return (
    <div
      className="social-button"
      onClick={onClick}
    >
      <Icon src={icon} />
    </div>
  );
}

export default function PublishSuccessfully({
  onClose,
  url,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tTourPublishSuccessfullyModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.tourPublishSuccessfully' });

  const { toastSuccess } = useToastContext();
  const [showQR, setShowQR] = useState(false);

  const shareFacebook = () => {
    window.open(`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`, '_blank', 'height=600,width=600');
  };

  const sendMail = () => {
    window.open(`https://mail.google.com/mail/u/0/?fs=1&su=Betates Tour&body=${encodeURI(`Check out this 3d tour: ${url}`)}&tf=cm`);
  };

  const shareLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank', 'height=600,width=600');
  };

  const copyUrl = () => {
    copy(url);
    toastSuccess(tCommon('copiedURL'));
  };

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-publish-successfully"
    >
      <Modal.Body>
        <img src={successBanner} alt="Publish successfully" />
        <div className="title">
          {tTourPublishSuccessfullyModal('title')}
        </div>
        <div className="description">
          {tTourPublishSuccessfullyModal('description')}
        </div>
        <div className="buttons">
          <Button
            onClick={() => {
              window.open(url);
            }}
            variant="secondary"
          >
            {tTourPublishSuccessfullyModal('seeThisTour')}
          </Button>
          <Button onClick={onClose}>{tCommon('gotIt')}</Button>
        </div>
        <div className="share-title">
          <div className="separator" />
          <div className="share-text">
            {tTourPublishSuccessfullyModal('share')}
          </div>
          <div className="separator" />
        </div>
        <div className="social-buttons">
          <SocialButton
            icon={facebookIcon}
            onClick={shareFacebook}
          />
          <SocialButton
            icon={mailIcon}
            onClick={sendMail}
          />
          <SocialButton
            icon={linkedinIcon}
            onClick={shareLinkedIn}
          />
          <SocialButton
            icon={copyLinkIcon}
            onClick={copyUrl}
          />
          <SocialButton
            icon={qrIcon}
            onClick={() => {
              setShowQR(true);
            }}
          />
        </div>
        {showQR && (
          <div className="qr">
            <div className="qr-title">
              {tTourPublishSuccessfullyModal('qrCode')}
            </div>
            <QRCodeSVG value={url} />
          </div>
        )}

      </Modal.Body>
    </Modal>
  );
}
