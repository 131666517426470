import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';
import * as api from 'utils/api';
import InputWithDropdown from 'components/Common/InputWithDropdown';
import useSearchLocations from 'components/Common/useSearchLocations';
import locationIcon from 'assets/images/ic-line-location.svg';
import useTranslation from '../../hooks/useTranslation';

export default function EditImage({
  image,
  onClose,
  onDone,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tEditImageModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.editImage' });

  const [submitting, setSubmitting] = useState(false);
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [imageName, setImageName] = useState('');
  const { locations, setLocations } = useSearchLocations({
    searchText: locationName,
    onError: toastError,
  });

  useEffect(() => {
    setImageName(image.name);
    setLocationName(image.location.name || '');
  }, [image]);

  useEffect(() => {
    if (selectedLocation) {
      setLocationName(selectedLocation.name);
    }
  }, [selectedLocation]);

  return (
    <Modal
      show
      backdrop="static"
      id="modal-edit-location"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tEditImageModal('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form.Group className="form-group">
          <Form.Label>{tEditImageModal('location')}</Form.Label>
          <InputWithDropdown
            placeholder={tEditImageModal('locationPlaceholder')}
            suggestions={locations}
            value={locationName}
            onChange={e => {
              setLocationName(e.target.value);
              setSelectedLocation(null);
            }}
            onSuggestionClick={(location) => {
              setSelectedLocation(location);
            }}
            renderSuggestion={({
              suggestion: location,
              onClick,
              isSelected,
            }) => (
              <div
                key={location.id}
                className={classNames(
                  'suggestion location',
                  isSelected && 'selected',
                )}
                onClick={() => {
                  onClick();
                }}
              >
                <img
                  alt="Location"
                  src={locationIcon}
                />
                {' '}
                {location.name}
              </div>
            )}
          />
        </Form.Group>
        <Form.Group
          className="form-group"
          style={{
            marginTop: 16,
          }}
        >
          <Form.Label>
            {tEditImageModal('imageName')}
          </Form.Label>
          <Form.Control
            value={imageName}
            onChange={e => setImageName(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);

            let locationId;

            if (!selectedLocation) {
              const matchedLocation = locations.find(l => l.name === locationName,
              );

              if (matchedLocation) {
                locationId = matchedLocation.id;
              } else {
                const location = await api.createLocation(locationName);
                setLocations([...locations, location]);
                locationId = location.id;
              }
            } else {
              locationId = selectedLocation.id;
            }

            const { success, error } = await request(api.updateImage(image.id, {
              name: imageName,
              locationId,
            }));

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onDone && onDone();
              onClose();
            }
          }}
        >
          {tCommon('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
