import c from 'configuration';

const configs = c as any;

export const extractNameBaseOnTranslation = (item: {
  name: string,
  translation: {
    name?: {
      language: string,
      text: string,
      id: string
    }[]
  }
}) => {
  if (item.translation.name) {
    const translation = item.translation.name.find(translation => translation.language === configs.language);
    if (translation) {
      return translation.text;
    }
  }
  return item.name;
};
