import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';
import * as api from 'utils/api';
import useTranslation from '../../hooks/useTranslation';

export default function AddApiKey({
  onClose,
  onDone,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tAddApiKeyModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.addApiKey' });

  const [submitting, setSubmitting] = useState(false);
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [data, setData] = useState({
    description: '',
  });
  const [apiKey, setApiKey] = useState(null);

  return (
    <Modal
      show
      backdrop="static"
      id="modal-add-api-key"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tAddApiKeyModal('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {apiKey ? (
          <>
            <Form.Group>
              <Form.Label>
                {tCommon('description')}
              </Form.Label>
              <Form.Control
                disabled
                value={apiKey.description}
              />
            </Form.Group>
            <Form.Group style={{
              marginTop: 16,
            }}
            >
              <Form.Label>
                {tCommon('key')}
              </Form.Label>
              <Form.Control
                disabled
                value={apiKey.key}
              />
            </Form.Group>
          </>
        ) : (
          <Form.Group>
            <Form.Label>
              {tCommon('description')}
            </Form.Label>
            <Form.Control
              value={data.description}
              onChange={e => setData({
                ...data,
                description: e.target.value,
              })}
            />
          </Form.Group>
        )}

      </Modal.Body>
      <Modal.Footer>
        {apiKey ? (
          <Button onClick={() => {
            onClose();
          }}
          >
            {tCommon('close')}
          </Button>
        ) : (
          <Button
            variant="primary"
            disabled={submitting}
            onClick={async () => {
              setSubmitting(true);
              const { success, error, result } = await request(api.createApiKey({
                description: data.description,
              }));

              if (!success) {
                toastError(error);
                setSubmitting(false);
              } else {
                onDone();
                setApiKey(result);
              }
            }}
          >
            {tCommon('add')}
          </Button>
        )}

      </Modal.Footer>
    </Modal>
  );
}
