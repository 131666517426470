import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Stage, Layer } from 'react-konva';
import WatermarkItem from './WatermarkItem';

export default forwardRef(({
  size,
  images,
  onChange,
  erasing,
}, ref) => {
  const [selectedId, setSelectedId] = useState(null);
  const stageRef = useRef();
  const stage = stageRef.current;

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
    }
  };

  useImperativeHandle(ref, () => ({
    getDataURL: () => {
      setSelectedId(null);
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(stage?.toDataURL());
        });
      });
    },
  }), [stage]);

  useEffect(() => {
    if (erasing) {
      setSelectedId(null);
    }
  }, [erasing]);

  return (
    <Stage
      ref={stageRef}
      width={size.width}
      height={size.height}
      onMouseDown={checkDeselect}
      onTouchStart={checkDeselect}
      style={{
        position: 'absolute',
        zIndex: erasing ? 2 : 4,
      }}
    >
      <Layer>
        {images
          .map((image) => (
            <WatermarkItem
              stageSize={size}
              key={image.id}
              image={image}
              onSelect={setSelectedId}
              isSelected={selectedId === image.id}
              onChange={data => {
                onChange(images.map(i => {
                  if (i.id !== image.id) {
                    return i;
                  }

                  return {
                    ...i,
                    ...data,
                  };
                }));
              }}
            />
          ))}
      </Layer>
    </Stage>
  );
});
