import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';

export default function InputWithIcon({
  icon,
  ...props
}) {
  return (
    <InputGroup className="input-group-with-right-icon">
      <Form.Control
        {...props}
      />
      <InputGroup.Text>
        {icon}
      </InputGroup.Text>
    </InputGroup>
  );
}
