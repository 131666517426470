import { Storage } from 'auto-design-common';
import { STORAGE_PREFIX } from 'constants/common';

export const storage = new Storage(STORAGE_PREFIX);

export const getStorageKey = storage.getStorageKey;

export const getItem = storage.getItem;

export const setItem = storage.setItem;

export const removeItem = storage.removeItem;

export const getJson = storage.getJson;

export const setJson = storage.setJson;

export const setCookie = storage.setCookie;

export const getCookie = storage.getCookie;

export const removeCookie = storage.removeCookie;
