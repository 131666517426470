import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';
import * as api from 'utils/api';
import useTranslation from '../../hooks/useTranslation';

export default function EditLocation({
  location,
  onClose,
  onDone,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tEditLocationModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.editLocation' });

  const [submitting, setSubmitting] = useState(false);
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [data, setData] = useState({
    name: '',
  });

  useEffect(() => {
    setData(location);
  }, [location]);

  return (
    <Modal
      show
      backdrop="static"
      id="modal-edit-location"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tEditLocationModal('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            {tEditLocationModal('name')}
          </Form.Label>
          <Form.Control
            value={data.name}
            onChange={e => setData({
              ...data,
              name: e.target.value,
            })}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);
            const { success, error } = await request(api.updateLocation(location.id, {
              name: data.name,
            }));

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onDone && onDone();
              onClose();
            }
          }}
        >
          {tCommon('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
