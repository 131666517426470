import React from 'react';

export default function PanoZoomButtons({
  onZoomIn,
  onZoomOut,
}) {
  return (
    <div className="pano-zoom-buttons">
      <div className="zoom-button u-noSelect" onClick={onZoomIn}>
        +
      </div>
      <div className="zoom-button u-noSelect" onClick={onZoomOut}>
        -
      </div>
    </div>
  );
}
