import React from 'react';
// import shareIcon from 'assets/images/ic-line-share.svg';
// import pdfIcon from 'assets/images/ic-fill-pdf.svg';
// import { Button } from 'react-bootstrap';
// import { useAppContext } from 'components/AppContext';

export default function CatalogHeader({
  renderHeader,
}) {
  // const { configs } = useAppContext();

  return (
    <div className="header">
      {renderHeader?.()}
      <div className="actions">
        {/* <Button
          variant="secondary"
        >
          <img src={shareIcon} alt="share" />
          Share
        </Button>
        <Button
          onClick={() => {
            window.open(configs.catalog_pdf, '_blank');
          }}
        >
          <img
            src={pdfIcon}
            alt="share"
          />
          Download as PDF
        </Button> */}
      </div>
    </div>
  );
}
