import ReactDOM from 'react-dom';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ShepherdTourContext } from 'react-shepherd';
import { getItem, setItem } from 'utils/storage';
import resultEditIntroImage from 'assets/images/result-edit-intro-image.svg';
import useTranslation, { i18n } from '../../hooks/useTranslation';

export const tourSteps = [
  {
    id: 'select-version',
    attachTo: {
      element: '.show-history',
      on: 'top',
    },
    text: [i18n.t('imagesView.tourSteps.selectVersion.text')],
    nextOnHide: true,
  },
  {
    id: 'start-edit',
    attachTo: {
      element: '.start-edit',
      on: 'top',
    },
    text() {
      const tour = this.tour;

      function Content() {
        const { tImagesResult } = useTranslation();
        const [dontShowAgain, setDontShowAgain] = useState(false);

        return (
          <div className="content">
            <img
              alt="Start Edit"
              src={resultEditIntroImage}
              style={{
                maxWidth: 'calc(100vw - 64px)',
              }}
            />
            <div className="title">
              {tImagesResult('tourSteps.startEdit.title')}
            </div>
            <div className="description">
              {tImagesResult('tourSteps.startEdit.description')}
            </div>
            <Button
              onClick={() => {
                tour.hide();

                if (dontShowAgain) {
                  setItem('dont-show-edit-intro', 'true');
                }
              }}
            >
              {tImagesResult('tourSteps.startEdit.button')}
            </Button>
            <Form.Check
              checked={dontShowAgain}
              type="checkbox"
              id="dont-show-again"
              label={tImagesResult('dontShowAgain')}
              onChange={() => setDontShowAgain(!dontShowAgain)}
            />
          </div>
        );
      }

      const el = document.createElement('div');
      ReactDOM.render(<Content />, el);
      return el;
    },
    when: {
      show () {
        const tour = this.tour;
        tour._setupModal();
        tour.modal.show();
        tour.modal.positionModal(0, 0, document.body, tour.getCurrentStep().getTarget());
      },
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [10, 16],
          },
        },
      ],
    },
    buttons: [],
  },
  {
    id: 'change-color',
    attachTo: {
      element: '.adjust .content',
      on: 'left',
    },
    text: [i18n.t('imagesView.tourSteps.changeColor.text')],
    nextOnHide: true,
  },
  {
    id: 'open-eraser',
    attachTo: {
      element: '.erase-button',
      on: 'bottom',
    },
    text: [i18n.t('imagesView.tourSteps.openEraser.text')],
  },
  {
    id: 'eraser-size',
    attachTo: {
      element: '.eraser-size',
      on: 'left',
    },
    text: [i18n.t('imagesView.tourSteps.eraserSize.text')],
    nextOnHide: true,
  },
  {
    id: 'erase',
    attachTo: {
      element: '.canvas-container',
      on: 'right',
    },
    text: [i18n.t('imagesView.tourSteps.erase.text')],
    nextOnHide: true,
  },
  {
    id: 'switch-to-keep',
    attachTo: {
      element: '.Keep',
      on: 'left',
    },
    text: [i18n.t('imagesView.tourSteps.switchToKeep.text')],
  },
  {
    id: 'keep',
    attachTo: {
      element: '.canvas-container',
      on: 'right',
    },
    text: [i18n.t('imagesView.tourSteps.keep.text')],
    nextOnHide: true,
  },
  {
    id: 'save',
    attachTo: {
      element: '.save-button',
      on: 'top',
    },
    text: [i18n.t('imagesView.tourSteps.save.text')],
  },
];

const doShowStep = (tour, step, force) => {
  const currentStepIndex = parseInt(getItem('viewer-tour-step') || -1);
  const findIndex = (step) => tourSteps.findIndex(s => s.id === step);
  const index = findIndex(step);

  if (force) {
    tour.show(step);
    return true;
  }

  if (currentStepIndex < index) {
    setItem('viewer-tour-step', index);
    tour.show(step);
    return true;
  }

  return false;
};

export const tourOptions = {
  defaultStepOptions: {
    buttons: [
      {
        text: i18n.t('imagesView.tourOptions.text'),
        label: i18n.t('imagesView.tourOptions.label'),
        action() {
          this.hide();

          if (this.getCurrentStep()?.options.nextOnHide) {
            this.next();
          }
        },
      },
    ],
  },
};

export const TourEvent = {
  OPEN_VIEWER: 'OPEN_VIEWER',
  FORCE_SHOW_START_EDIT: 'FORCE_SHOW_START_EDIT',
  SHOW_HISTORY: 'SHOW_HISTORY',
  OPEN_EDITOR: 'OPEN_EDITOR',
  CHANGE_SATURATION: 'CHANGE_SATURATION',
  CHANGE_CONTRAST: 'CHANGE_CONTRAST',
  CHANGE_BRIGHTNESS: 'CHANGE_BRIGHTNESS',
  CHANGE_HUE: 'CHANGE_HUE',
  USE_ERASER: 'USE_ERASER',
  CHANGE_ERASER_SIZE: 'CHANGE_ERASER_SIZE',
  ERASING_END: 'ERASING_END',
  KEEP_END: 'KEEP_END',
  SWITCH_TO_KEEP: 'SWITCH_TO_KEEP',
  SAVE: 'SAVE',
};

export const useTourContext = () => {
  const tour = useContext(ShepherdTourContext);

  const emit = useCallback((eventName) => {
    const showStep = (step, force) => doShowStep(tour, step, force);

    switch (eventName) {
      case TourEvent.OPEN_VIEWER: {
        return showStep('select-version');
      }

      case TourEvent.SHOW_HISTORY: {
        return showStep('start-edit');
      }

      case TourEvent.FORCE_SHOW_START_EDIT: {
        return showStep('start-edit', true);
      }

      case TourEvent.OPEN_EDITOR: {
        if (showStep('change-color')) {
          return true;
        }

        // Hide start-edit
        tour.hide();
        return false;
      }

      case TourEvent.CHANGE_SATURATION:
      case TourEvent.CHANGE_CONTRAST:
      case TourEvent.CHANGE_BRIGHTNESS:
      case TourEvent.CHANGE_GAMMA: {
        return showStep('open-eraser');
      }

      case TourEvent.USE_ERASER: {
        return showStep('eraser-size');
      }

      case TourEvent.CHANGE_ERASER_SIZE: {
        return showStep('erase');
      }

      case TourEvent.ERASING_END: {
        return showStep('switch-to-keep');
      }

      case TourEvent.SWITCH_TO_KEEP: {
        return showStep('keep');
      }

      case TourEvent.KEEP_END: {
        return showStep('save');
      }

      case TourEvent.SAVE: {
        tour.complete();
        return setItem('viewer-tour-step', 999);
      }

      default: {
        return null;
      }
    }
  }, [tour]);

  const value = useMemo(() => ({
    tour,
    emit,
  }), [tour, emit]);

  return value;
};
