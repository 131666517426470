import React from 'react';
import LandingHeader from 'components/Common/LandingHeader';
import LandingFooter from 'components/Common/LandingFooter';
import TermsOfServicesContent from 'components/Common/TermsOfServicesContent';

export default function TermsOfServices() {
  return (
    <div className="terms-of-services">
      <LandingHeader />
      <div className="content-wrapper">
        <TermsOfServicesContent />
      </div>
      <LandingFooter />
    </div>
  );
}
