import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import QueryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useToastContext } from 'auto-design-common';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { getTranslation } from 'hooks/useTranslation';
import CatalogHeader from './CatalogHeader';

const LIMIT = 100;

function Item({
  item,
  onClick,
}) {
  return (
    <div
      className="col-xxl-3 col-xl-4 col-lg-6 item-wrapper"
      onClick={onClick}
    >
      <div className="item">
        <img
          className="thumbnail"
          src={item.thumbnail}
          alt="thumbnail"
        />
        <div className="name">
          {getTranslation(item.translation.name, item.name)}
        </div>
      </div>
    </div>
  );
}

export default function ItemList({
  renderHeader,
  fetchFunc,
}) {
  const { showModal } = useModalContext();
  const history = useHistory();
  const location = useLocation();
  const { toastError } = useToastContext();
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const listRef = useRef();
  const lastItemIdRef = useRef();
  const fetchingRef = useRef(false);

  const selectedItemId = useMemo(() => {
    const { itemId } = QueryString.parse(location.search);

    return itemId;
  }, [location.search]);

  useEffect(() => {
    if (selectedItemId) {
      showModal(ModalKey.ITEM_DETAIL, {
        itemId: selectedItemId,
        onClose: () => {
          history.replace(location.pathname);
        },
      });
    }
  }, [selectedItemId, history, location.pathname, showModal]);

  const fetchMoreItems = useCallback(async () => {
    if (fetchingRef.current) {
      return;
    }

    fetchingRef.current = true;

    try {
      const newItems = await fetchFunc({
        after: lastItemIdRef.current,
        limit: LIMIT,
      });

      if (newItems.length === 0) {
        setHasMore(false);
      } else {
        setItems(items => [...items, ...newItems]);
        lastItemIdRef.current = newItems[newItems.length - 1].id;
      }
    } catch (error) {
      toastError(error);
    }

    fetchingRef.current = false;
  }, [fetchFunc, toastError]);

  const selectItem = (item) => {
    history.push(`${location.pathname}?itemId=${item.id}`);
  };

  useEffect(() => {
    fetchMoreItems();
  }, [fetchMoreItems]);

  return (
    <div className="items">
      <CatalogHeader
        renderHeader={renderHeader}
      />
      <div
        className="list"
        ref={listRef}
        id="list"
      >
        <InfiniteScroll
          hasMore={hasMore}
          dataLength={items.length}
          next={fetchMoreItems}
          scrollableTarget="list"
          className="row"
          loader={<h4>Loading...</h4>}
        >
          {items.map(item => (
            <Item
              key={item.id}
              item={item}
              onClick={() => selectItem(item)}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}
