import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import designImg from 'assets/images/design.svg';
import uploadIcon from 'assets/images/ic-fill-upload.svg';
import { Icon } from 'auto-design-common';
import useTranslation from '../../hooks/useTranslation';

export default function Empty() {
  const { tLocations } = useTranslation();
  const history = useHistory();

  return (
    <div className="locations-empty">
      <img
        alt="design"
        src={designImg}
      />
      <div className="title">
        {tLocations('empty.title')}
      </div>
      <div className="sub-title">
        {tLocations('empty.subTitle')}
      </div>
      <Button
        className="add-new-design"
        onClick={() => {
          history.push('/add-design');
        }}
      >
        <Icon
          src={uploadIcon}
          fill="white"
        />
        {tLocations('empty.addANewDesign')}
      </Button>
    </div>
  );
}
