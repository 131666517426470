import React, { useMemo, useCallback, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import checkIcon from 'assets/images/ic-line-check.svg';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import { Icon } from 'auto-design-common';
import { useModalContext } from './ModalContext';
import useTranslation from '../hooks/useTranslation';

const NotificationContext = React.createContext();

const TIMEOUT = 60 * 1000;

export function NotificationContextProvider({
  children,
}) {
  const [messages, setMessages] = useState([]);

  const notification = useCallback((message, type, onClick) => {
    const id = uuidv4();

    setMessages(messages => [{
      id,
      type,
      message,
      onClick,
    }, ...messages]);

    setTimeout(() => {
      setMessages(messages => messages.filter(message => message.id !== id));
    }, TIMEOUT);
  }, []);

  const notifyError = useCallback((error, onClick) => {
    notification(error.data?.message || error.message || error || 'Something went wrong', 'error', onClick);
  }, [notification]);

  const notifySuccess = useCallback((message, onClick) => {
    notification(message, 'success', onClick);
  }, [notification]);

  const contextValue = useMemo(() => ({
    messages,
    notifyError,
    notifySuccess,
    setMessages,
  }), [
    messages,
    notifyError,
    notifySuccess,
    setMessages,
  ]);

  return (
    <NotificationContext.Provider
      value={contextValue}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NotificationContext);
}

export function Notification({
  onModal,
}) {
  const { tCommon } = useTranslation();
  const { messages, setMessages } = useNotificationContext();
  const { modal } = useModalContext();

  if (modal && !onModal) {
    return null;
  }

  return (
    <div className="notification-container">
      {messages.map(message => (
        <div
          key={message.id}
          className={`notification-message ${message.type}`}
          onClick={() => {
            if (message.onClick) {
              message.onClick();
            }

            setMessages(messages => messages.filter(m => message.id !== m.id));
          }}
        >
          {message.type === 'success' && (
            <Icon src={checkIcon} />
          )}
          {message.type === 'error' && (
            <Icon
              src={xIcon}
              fill="#f5222d"
            />
          )}
          {message.message}
          <div
            className="close"
            onClick={(e) => {
              e.stopPropagation();
              setMessages(messages => messages.filter(m => message.id !== m.id));
            }}
          >
            {tCommon('close')}
          </div>
        </div>
      ))}
    </div>
  );
}
