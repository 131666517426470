import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import Sidebar from 'components/Common/Sidebar';
import Footer from 'components/Common/Footer';
import creditCardPaymentImage from 'assets/images/credit-card-payment.svg';
import useTranslation from '../../hooks/useTranslation';

export default function PaymentSuccessful() {
  const { tPaymentSuccessful } = useTranslation();
  const history = useHistory();

  return (
    <div className="main payment-successful">
      <Sidebar />
      <div className="right-part">
        <div className="content">
          <img
            src={creditCardPaymentImage}
            alt="Payment"
          />
          <div className="title">
            {tPaymentSuccessful('title')}
          </div>
          <div className="sub-title">
            {tPaymentSuccessful('subTitle')}
          </div>
          <Button
            onClick={() => {
              history.push('/location');
            }}
          >
            {tPaymentSuccessful('button')}
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
}
