import React, { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import ReactLoading from 'components/Common/ReactLoading';
import chevronIcon from 'assets/images/ic-fill-chevron-down.svg';
import { Key, KeyCharacter } from 'auto-design-common';
import { InputGroup } from 'react-bootstrap';
import InputWithIcon from './InputWithRightIcon';

const Suggestion = ({ suggestion, isSelected, onClick }) => (
  <div
    className={classNames(
      'suggestion',
      isSelected && 'selected',
    )}
    onClick={() => onClick(suggestion)}
  >
    {suggestion.name}
  </div>
);

function InputWithDropdown({
  suggestions,
  onKeyDown,
  onSuggestionClick,
  inputRef,
  renderSuggestion,
  loading,
  rightPadding,
  onFocus,
  ...rest
}) {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (!showSuggestions || suggestions.length === 0) {
      setSelectedIndex(null);
    }
  }, [showSuggestions, suggestions]);

  return (
    <InputGroup className="input-with-dropdown-group">
      <InputWithIcon
        {...rest}
        style={{
          paddingRight: rightPadding || 16,
        }}
        ref={inputRef}
        onKeyDown={(e) => {
          if (!showSuggestions || suggestions.length === 0) {
            onKeyDown && onKeyDown(e);
            return;
          }

          if (e.keyCode === Key.DOWN) {
            if (selectedIndex === null) {
              setSelectedIndex(0);
            } else if (selectedIndex < suggestions.length - 1) {
              setSelectedIndex(selectedIndex + 1);
            }
          }

          if (e.keyCode === Key.UP) {
            if (selectedIndex > 0) {
              setSelectedIndex(selectedIndex - 1);
            }
          }

          if (e.key === KeyCharacter.ENTER && selectedIndex !== null) {
            onSuggestionClick(suggestions[selectedIndex]);
            return;
          }

          onKeyDown && onKeyDown(e);
        }}
        icon={suggestions.length > 0 && (
          <img src={chevronIcon} alt="suggestions" />
        )}
        onFocus={() => {
          onFocus?.();
          setShowSuggestions(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowSuggestions(false);
          }, 100);
        }}
      />

      {loading && showSuggestions && (
        <div
          className="suggestions"
        >
          <div className="full-div-loading">
            <ReactLoading />
          </div>
        </div>
      )}

      {!loading && showSuggestions && suggestions.length > 0 && (
        <div
          className="suggestions"
        >
          {suggestions.map((suggestion, index) => {
            if (renderSuggestion) {
              return renderSuggestion({
                suggestion,
                onClick: () => {
                  onSuggestionClick(suggestion);
                  setShowSuggestions(false);
                },
                isSelected: selectedIndex === index,
              });
            }

            return (
              <Suggestion
                key={suggestion.id}
                suggestion={suggestion}
                onClick={(suggestion) => {
                  onSuggestionClick(suggestion);
                  setShowSuggestions(false);
                }}
                isSelected={selectedIndex === index}
              />
            );
          })}
        </div>
      )}
    </InputGroup>
  );
}

export default forwardRef(InputWithDropdown);
