import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function ButtonTooltip({
  placement,
  children,
  id,
  tooltip,
  show,
}) {
  return (
    <OverlayTrigger
      show={show}
      placement={placement || 'bottom'}
      delay={{ show: 1000, hide: 400 }}
      overlay={(props) => (
        <Tooltip
          id={id}
          {...props}
        >
          {tooltip}
        </Tooltip>
      )}
    >
      {children}
    </OverlayTrigger>
  );
}
