import bellSound from 'assets/sounds/success-bell.wav';
import jingleSound from 'assets/sounds/success-jingle.wav';
import errorSound from 'assets/sounds/error.wav';

const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;

const context = AudioContext && new AudioContext();

let soundEnabled = true;

export const enableSound = (soundStatus) => {
  soundEnabled = soundStatus;
};

export class Sound {
  constructor(file) {
    this.file = file;
    this.context = context;
    this.buffer = null;
    if (context === null) {
      this.audio = new Audio(file);
    }

    this.load();
  }

  load = () => {
    if (context === null) {
      return;
    }

    fetch(this.file)
      .then(res => res.arrayBuffer && res.arrayBuffer())
      .then((buffer) => {
        buffer && this.context.decodeAudioData(buffer, (decodedData) => {
          this.buffer = decodedData;
        });
      });
  }

  play = () => {
    if (!soundEnabled) return;

    if (!context) {
      this.audio.play();
    } else if (this.buffer) {
      const bufferClone = this.context.createBufferSource();
      bufferClone.buffer = this.buffer;
      bufferClone.connect(this.context.destination);
      bufferClone.start();
    }
  }
}

export const bell = new Sound(bellSound);
export const jingle = new Sound(jingleSound);
export const error = new Sound(errorSound);
