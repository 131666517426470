import React from 'react';
import ReactSlider from 'react-slider';
import styles from './Slider.module.scss';

export default function Slider({
  onChange,
  value,
  min,
  max,
}) {
  return (
    <div className={styles.sliderWrapper}>
      <ReactSlider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
