import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getCategories, getItems } from 'utils/api';
import { useToastContext } from 'auto-design-common';
import ItemList from './Shared/ItemList';
import CategorySelect from './Shared/CategorySelect';

export default function RoomItems() {
  const history = useHistory();
  const { categoryId } = useParams();
  const { toastError } = useToastContext();
  const [categories, setCategories] = useState([]);

  const currentCategory = useMemo(() => categories.find(c => c.id === parseInt(categoryId)), [categories, categoryId]);

  useEffect(() => {
    getCategories()
      .then(setCategories)
      .catch(toastError);
  }, [toastError]);

  if (!currentCategory) {
    return null;
  }

  return (
    <ItemList
      fetchFunc={(params) => getItems({
        ...params,
        categoryId,
      })}
      renderHeader={() => (
        <CategorySelect
          currentItem={currentCategory}
          items={categories}
          onBack={() => history.replace('/catalog/categories')}
          onSelectItem={(category) => history.push(`/catalog/categories/${category.id}/items/`)}
        />
      )}
    />
  );
}
