import React from 'react';
import { Button } from 'react-bootstrap';
import nextArrow from 'assets/images/ic-fill-arrow-right.svg';

export default function NextButton({
  text,
  ...props
}) {
  return (
    <Button {...props}>
      {text}
      <img
        alt="Next"
        src={nextArrow}
      />
    </Button>
  );
}
