import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'components/AppContext';
import { useToastContext, Icon } from 'auto-design-common';
import * as api from 'utils/api';
import { getToken } from 'utils/auth';
import furnitureIcon from 'assets/images/ic-fill-arm-chair.svg';
import downloadIcon from 'assets/images/ic-line-download.svg';
import config from 'configuration';
import useTranslation from '../../hooks/useTranslation';

export default function VersionHistory({
  onClose,
  imageId,
}) {
  const { useTranslationOrigin } = useTranslation();
  const { t: tVersionHistoryModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.versionHistory' });

  const history = useHistory();
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    (async () => {
      const { success, error, result } = await request(api.getImage(imageId));

      if (success) {
        setVersions(result.progresses);
      } else {
        toastError(error);
      }
    })();
  }, [request, imageId, toastError]);

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      size="md"
      id="modal-version-history"
    >
      <Modal.Header closeButton>
        <Modal.Title>{tVersionHistoryModal('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {versions.map(version => (
          <div
            className="version"
            onClick={() => {
              history.push(`/images/${imageId}/view?progressId=${version.id}`);
              onClose();
            }}
          >
            <img
              className="preview"
              src={version.preview || `${config.apiUrl}/files/${version.metadata.outputFileId}?accessToken=${getToken()}`}
              alt="Preview"
            />
            <div className="name">
              {moment.utc(version.createdAt).local().format('MM-DD-yyyy HH:mm')}
            </div>
            <div className="actions">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`${config.apiUrl}/files/${version.metadata.furnitureOutputFileId}?accessToken=${getToken()}`);
                }}
              >
                <Icon
                  fill="#575762"
                  src={furnitureIcon}
                />
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`${config.apiUrl}/files/${version.metadata.outputFileId}?accessToken=${getToken()}`);
                }}
              >
                <Icon
                  fill="#575762"
                  src={downloadIcon}
                />
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}
