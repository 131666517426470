import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import ReactCompareImage from 'react-compare-image';
import introBackground from 'assets/images/landing/modern-living-room-interior-with-sofa-green-plants-lamp-table-dark-wall-background.png';
import badgeBorder from 'assets/images/landing/badge-border.svg';
import image1 from 'assets/images/landing/image_1.png';
import image2 from 'assets/images/landing/image_2.png';
import image3 from 'assets/images/landing/image_3.png';
import image4 from 'assets/images/landing/image_4.png';
import image5 from 'assets/images/landing/image_5.png';
import image6 from 'assets/images/landing/image_6.png';
import image7 from 'assets/images/landing/image_7.png';
import image8 from 'assets/images/landing/image_8.png';
import image9 from 'assets/images/landing/image_9.png';
import image10 from 'assets/images/landing/image_10.png';
import leftArrow from 'assets/images/ic-line-arrow-left.svg';
import rightArrow from 'assets/images/ic-line-arrow-right.svg';
import moneybackBackground from 'assets/images/landing/moneyback-background.svg';
import serviceImg15 from 'assets/images/landing/services/image_15.jpg';
import serviceImg16 from 'assets/images/landing/services/image_16.jpg';
import bed from 'assets/images/landing/services/bed.jpg';
import blurredImg from 'assets/images/landing/services/blurred.jpg';
import neatImg from 'assets/images/landing/services/neat.jpg';
import beforePainImg from 'assets/images/landing/services/before_pain.jpg';
import afterPainImg from 'assets/images/landing/services/after_pain.jpg';
import result1Before from 'assets/images/landing/results/bed_before.jpg';
import result1After from 'assets/images/landing/results/bed_after.jpg';
import result2Before from 'assets/images/landing/results/liv_before.jpg';
import result2After from 'assets/images/landing/results/liv_after.jpg';
import result3Before from 'assets/images/landing/results/liv_2_before.jpg';
import result3After from 'assets/images/landing/results/liv_2_after.jpg';
import result4Before from 'assets/images/landing/results/liv_3_before.jpg';
import result4After from 'assets/images/landing/results/liv_3_after.jpg';
import result5Before from 'assets/images/landing/results/liv_kitchen_before.jpg';
import result5After from 'assets/images/landing/results/liv_kitchen_after.jpg';
import normalPhoto from 'assets/images/landing/normal-photo.svg';
import panoPhoto from 'assets/images/landing/pano-photo.svg';
import vrPhoto from 'assets/images/landing/vr-photo.svg';
import magicWandPhoto from 'assets/images/landing/magic-wand.png';
import tourServicePhoto from 'assets/images/landing/tour-service.jpg';
import { useAppContext } from 'components/AppContext';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import LandingHeader from 'components/Common/LandingHeader';
import LandingFooter from 'components/Common/LandingFooter';
import { ReactComponent as Coin } from 'assets/images/Auto-Coin.svg';
import { ReactComponent as TwoCoins } from 'assets/images/two-coins.svg';
import city1 from 'assets/images/city-1.png';
import city2 from 'assets/images/city-2.png';
import city3 from 'assets/images/city-3.png';
import illu from 'assets/images/illu.jpg';
import vrGlass from 'assets/images/landing/vr-glass.png';
import cleanBefore from 'assets/images/landing/clean-before.jpg';
import cleanAfter from 'assets/images/landing/clean-after.jpg';
import useTranslation from '../../hooks/useTranslation';

const RESULTS = [
  {
    id: 1,
    before: result1Before,
    after: result1After,
  },
  {
    id: 2,
    before: result2Before,
    after: result2After,
  },
  {
    id: 3,
    before: result3Before,
    after: result3After,
  },
  {
    id: 4,
    before: result4Before,
    after: result4After,
  },
  {
    id: 5,
    before: result5Before,
    after: result5After,
  },
];

export default function Landing() {
  const { tHomePage, tCommon } = useTranslation();

  const history = useHistory();
  const { configs } = useAppContext();
  const { showModal } = useModalContext();
  const [resultImageIndex, setResultImageIndex] = useState(0);

  const login = () => {
    history.push('/get-started');
  };

  const nextResultImage = () => {
    if (resultImageIndex < RESULTS.length - 1) {
      setResultImageIndex(resultImageIndex + 1);
    }
  };

  const prevResultImage = () => {
    if (resultImageIndex > 0) {
      setResultImageIndex(resultImageIndex - 1);
    }
  };

  return (
    <div className="landing">
      <div className="intro">
        <LandingHeader />
        <img
          alt="intro-background"
          className="intro-background"
          src={introBackground}
        />
        <div className="slogan">
          <div className="first">
            {tHomePage('intro.VIRTUAL')}
          </div>
          <div className="second">
            {tHomePage('intro.STAGING')}
          </div>
          <div className="third">
            <div className="left">
              {tHomePage('intro.SLOGAN_1')}
            </div>
            <div className="right">
              {tHomePage('intro.SLOGAN_2')}
            </div>
          </div>
          <div className="fourth">
            <div className="badge">
              <img
                alt="border"
                src={badgeBorder}
              />
              <div className="inner">
                <div>
                  {tHomePage('intro.TRY_NOW')}
                </div>
                <div>
                  {tHomePage('intro.FREE_FOR_1ST')}
                </div>
                <div>
                  {tHomePage('intro.IMAGE')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="intro-video">
        <iframe
          className="video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/cO6su8TdBQY?rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      <div className="pricing">
        <div className="title">
          <div>{tHomePage('pricing.title1')}</div>
          <div>{tHomePage('pricing.title2')}</div>
        </div>
        <div className="coin-lines">
          <div className="coin-line">
            <div className="coins">
              <Coin />
            </div>
            {tHomePage('pricing.2D_image')}
          </div>
          <div className="coin-line">
            <div className="coins">
              <TwoCoins />
            </div>
            {tHomePage('pricing.360_degree_image')}
          </div>
        </div>
        <div className="container">
          <div className="prices row">
            <div className="col-lg-4 price-wrapper">
              <div className="price">
                <div className="price-content">
                  <div
                    className="price-title"
                    style={{
                      background: '#23B16D',
                      color: 'white',
                    }}
                  >
                    {tHomePage('pricing.STARTING_OUT')}
                  </div>
                  <div className="price-line">
                    <div className="price-figure">
                      {tHomePage('pricing.$')}
                      {configs.coin_rate_21_up / 100}
                    </div>
                    {' '}
                    <div>
                      <Coin />
                      {' '}
                      {tHomePage('pricing.perCoin')}
                    </div>
                  </div>
                  <div className="separator" />
                  <div className="explanation">
                    {tHomePage('pricing.moreThan20Images')}
                  </div>
                </div>
                <img className="background-image" src={city1} alt="$5" />
              </div>
            </div>
            <div className="col-lg-4 price-wrapper">
              <div className="price">
                <div className="price-content">
                  <div
                    className="price-title"
                    style={{
                      background: '#F5222D',
                      color: 'white',
                    }}
                  >
                    {tHomePage('pricing.SCALING_UP')}
                  </div>
                  <div className="price-line">
                    <div className="price-figure">
                      {tHomePage('pricing.$')}
                      {configs.coin_rate_10_20 / 100}
                    </div>
                    {' '}
                    <div>
                      <Coin />
                      {' '}
                      {tHomePage('pricing.perCoin')}
                    </div>
                  </div>
                  <div className="separator" />
                  <div className="explanation">
                    {tHomePage('pricing.10_20_images')}
                  </div>
                </div>
                <img className="background-image" src={city2} alt="$5" />
              </div>
            </div>
            <div className="col-lg-4 price-wrapper">
              <div className="price">
                <div className="price-content">
                  <div
                    className="price-title"
                    style={{
                      background: '#549CE9',
                      color: 'white',
                    }}
                  >
                    {tHomePage('pricing.PRO_DEAL')}
                  </div>
                  <div className="price-line">
                    <div className="price-figure">
                      {tHomePage('pricing.$')}
                      {configs.coin_rate_1_9 / 100}
                    </div>
                    {' '}
                    <div>
                      <Coin />
                      {' '}
                      {tHomePage('pricing.perCoin')}
                    </div>
                  </div>
                  <div className="separator" />
                  <div className="explanation">
                    {tHomePage('pricing.upTo9Images')}
                  </div>
                </div>
                <img className="background-image" src={city3} alt="$5" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="support">
        <div className="container">
          <div className="support-content">
            <div className="left">
              <div className="title-description">
                <div className="title">
                  {tHomePage('support.title')}
                </div>
                <div className="description">
                  {tHomePage('support.description')}
                </div>
              </div>
              <img src={illu} alt="We are providing these services" />
            </div>
            <div className="formats">
              <div className="formats-row">
                <div className="format normal">
                  <img src={normalPhoto} alt="2d images" />
                  <div className="title">
                    {tHomePage('support.normalTitle')}
                  </div>
                  <div className="subtitle">
                    {tHomePage('support.normalSubtitle')}
                  </div>
                </div>
                <div className="format pano">
                  <img src={panoPhoto} alt="360 images" />
                  <div className="title">
                    {tHomePage('support.panoTitle')}
                  </div>
                  <div className="subtitle">
                    {tHomePage('support.panoSubtitle')}
                  </div>
                </div>
              </div>
              <div className="formats-row">
                <div className="format tour">
                  <img src={vrPhoto} alt="Panoramic Walk-Throughs" />
                  <div className="title">
                    {tHomePage('support.tourTitle')}
                  </div>
                  <div className="subtitle">
                    {tHomePage('support.tourSubtitle')}
                  </div>
                </div>
                <div className="format enhance">
                  <img src={magicWandPhoto} alt="Panoramic Walk-Throughs" />
                  <div className="title">
                    {tHomePage('support.enhanceTitle')}
                  </div>
                  <div className="subtitle">
                    {tHomePage('support.enhanceSubtitle')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="embed">
        <div className="content">
          <div className="title">
            {tHomePage('embed.title')}
          </div>
          <Button onClick={() => {
            window.open('https://betates-diy.tawk.help/article/embed-our-virtual-staging-service-into-your-own-website');
          }}
          >
            {tCommon('readMore')}
          </Button>
        </div>
      </div>

      <div className="why">
        <div className="why-content">
          <div className="title-section">
            <div className="title">{tHomePage('why.title')}</div>
            <div className="separator" />
            <Button
              variant="primary"
              className="try-it-now"
              onClick={login}
            >
              {tCommon('tryItNow')}
            </Button>
          </div>
          <div className="sections">
            <div className="section">
              <div className="block">
                <div className="index">01</div>
                <div className="block-content">
                  <div className="block-title">
                    {tHomePage('why.01_title')}
                  </div>
                  <div className="block-description">{tHomePage('why.01_description')}</div>
                </div>

              </div>

              <div className="block">
                <div className="index">02</div>
                <div className="block-content">
                  <div className="block-title">{tHomePage('why.02_title')}</div>
                  <div className="block-description">{tHomePage('why.02_description')}</div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="block">
                <div className="index">03</div>
                <div className="block-content">
                  <div className="block-title">{tHomePage('why.03_title')}</div>
                  <div className="block-description">{tHomePage('why.03_description')}</div>
                </div>

              </div>

              <div className="block">
                <div className="index">04</div>
                <div className="block-content">
                  <div className="block-title">{tHomePage('why.04_title')}</div>
                  <div className="block-description">{tHomePage('why.04_description')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="steps">
        <div className="steps-content">
          <div className="step">
            <div className="images-container">
              <div className="images">
                <div className="images-line align-bottom">
                  <img
                    alt="image1"
                    src={image1}
                  />
                  <img
                    alt="image3"
                    src={image3}
                  />
                </div>
                <div className="images-line">
                  <img
                    alt="image2"
                    src={image2}
                  />
                  <img
                    alt="image4"
                    src={image4}
                  />
                </div>
                <div className="images-line align-top">
                  <img
                    alt="image5"
                    src={image5}
                  />
                  <img
                    alt="image6"
                    src={image6}
                  />
                </div>
              </div>
            </div>

            <div className="step-content">
              <div className="title">{tHomePage('steps.1_title')}</div>
              <div className="description" />
              <Button
                variant="dark"
                onClick={login}
              >
                {tCommon('tryItNow')}
              </Button>
            </div>
          </div>

          <div className="step reversed non-scaled">
            <div className="step-content">
              <div className="title">
                {tHomePage('steps.2_title')}
              </div>
              <div className="description">{tHomePage('steps.2_description')}</div>
              <Button
                variant="dark"
                onClick={login}
              >
                {tCommon('tryItNow')}
              </Button>
            </div>
            <ReactCompareImage
              sliderLineWidth={2}
              leftImage={cleanBefore}
              rightImage={cleanAfter}
            />
          </div>

          <div className="step non-scaled">
            <iframe
              className="video"
              src="https://www.youtube.com/embed/jjuVCVwlGng?rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <div className="step-content">
              <div className="title">{tHomePage('steps.3_title')}</div>
              <div className="description" />
              <Button
                variant="dark"
                onClick={login}
              >
                {tCommon('tryItNow')}
              </Button>
            </div>
          </div>

          <div className="step reversed last">
            <div className="step-content">
              <div className="title">
                {tHomePage('steps.4_title')}
              </div>
              <div className="description">
                {tHomePage('steps.4_description')}
              </div>
              <Button
                variant="dark"
                onClick={login}
              >
                {tCommon('tryItNow')}
              </Button>
            </div>
            <div className="images-container">
              <div className="images">
                <div className="images-line align-bottom">
                  <img
                    alt="image7"
                    src={image7}
                  />
                  <img
                    alt="image8"
                    src={image8}
                  />
                </div>
                <div className="images-line align-top">
                  <img
                    alt="image10"
                    src={image10}
                  />
                  <img
                    alt="image9"
                    src={image9}
                  />
                </div>

              </div>
            </div>

          </div>

        </div>

      </div>

      <div className="tour-service">
        <div className="background">
          <div className="wrapper">
            <img width={160} height={160} src={vrGlass} alt="VR glasses" />
            <div className="supported">
              {tHomePage('tourService.supported')}
            </div>
            <div className="description">
              {tHomePage('tourService.description')}
              {' '}
              <b>{tHomePage('tourService.forFree')}</b>
            </div>

          </div>

        </div>
        <img src={tourServicePhoto} alt="3D Walk-through Virtual Tour" />
      </div>

      <div className="services">
        <div className="content">
          <div className="title">
            {tHomePage('services.title')}
          </div>
          <div className="service-items">
            <div className="service-items-row">
              <div className="service-item-wrapper">
                <div className="compare thumbnail">
                  <ReactCompareImage
                    sliderLineWidth={2}
                    leftImage={blurredImg}
                    rightImage={neatImg}
                  />
                </div>
                <div className="service-item-title">
                  {tHomePage('services.1_title')}
                </div>
                <div className="service-item-sub-title">
                  {tHomePage('services.1_subtitle')}
                  <span
                    className="view-detail-button"
                    onClick={() => {
                      showModal(ModalKey.WHAT_WE_DO);
                    }}
                  >
                    {tCommon('viewDetail')}
                  </span>
                </div>
              </div>

              <div className="service-item-wrapper">
                <div className="compare thumbnail">
                  <ReactCompareImage
                    sliderLineWidth={2}
                    leftImage={beforePainImg}
                    rightImage={afterPainImg}
                  />
                </div>
                <div className="service-item-title">
                  {tHomePage('services.2_title')}
                </div>
                <div className="service-item-sub-title">
                  {tHomePage('services.2_subtitle')}
                </div>
              </div>

              <div className="service-item-wrapper">
                <div className="thumbnail">
                  <img src={serviceImg15} alt="Changing color of walls, ceiling, type of floors" />
                </div>
                <div className="service-item-title">
                  {tHomePage('services.3_title')}
                </div>
                <div className="service-item-sub-title">
                  {tHomePage('services.3_subtitle')}
                </div>
              </div>
            </div>

            <div className="service-items-row second">
              <div className="service-item-wrapper">
                <div className="thumbnail">
                  <img src={serviceImg16} alt="Changing color of walls, ceiling, type of floors" />
                </div>
                <div className="service-item-title">
                  {tHomePage('services.4_title')}
                </div>
                <div className="service-item-sub-title">
                  {tHomePage('services.4_subtitle')}
                </div>
              </div>
              <div className="service-item-wrapper">
                <div className="thumbnail">
                  <img src={bed} alt="Cutomized 3D model." />
                </div>
                <div className="service-item-title">
                  {tHomePage('services.5_title')}
                </div>
                <div className="service-item-sub-title">
                  {tHomePage('services.5_subtitle')}
                </div>
              </div>
              <div className="service-item-wrapper contact-us">
                <div className="contact-us-title">
                  {tHomePage('services.contactTitle')}
                </div>
                <div className="contact-subtitle">
                  {tHomePage('services.contactSubtitle')}
                </div>
                <Button
                  className="contact-us-button"
                  onClick={() => {
                    showModal(ModalKey.CONTACT_FOR_SERVICE);
                  }}
                >
                  {tCommon('contactUs')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing-result">
        <div className="content">
          <div className="header">
            <div className="title">{tHomePage('ladingResult.title')}</div>
            <div className="quotes">
              <div className="quote">{tHomePage('ladingResult.quote')}</div>
              <div className="arrows">
                <div
                  className={classNames('arrow left', resultImageIndex === 0 && 'disabled')}
                  onClick={prevResultImage}
                >
                  <img
                    alt="left-arrow"
                    src={leftArrow}
                  />
                </div>
                <div
                  className={classNames('arrow right',
                    resultImageIndex === RESULTS.length - 1 && 'disabled',
                  )}
                  onClick={nextResultImage}
                >
                  <img
                    alt="right-arrow"
                    src={rightArrow}

                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="compare"
            style={{
              aspectRatio: '3 / 2',
            }}
          >
            <ReactCompareImage
              sliderLineWidth={2}
              leftImage={RESULTS[resultImageIndex].before}
              rightImage={RESULTS[resultImageIndex].after}
            />
            <div className="badge before">
              {tHomePage('ladingResult.BEFORE')}
            </div>
            <div className="badge after">
              {tHomePage('ladingResult.AFTER')}
            </div>
          </div>
        </div>

      </div>

      <div className="money-back">
        <img
          alt="Money back"
          src={moneybackBackground}
        />
        <div className="content">
          <div className="title">
            {tHomePage('moneyBack.title')}
          </div>
          <div className="description">
            {tHomePage('moneyBack.description')}
          </div>
          <Button
            onClick={login}
          >
            {tCommon('tryItNow')}
          </Button>
        </div>

      </div>

      <LandingFooter />
    </div>
  );
}
