import { EventEmitter } from 'fbemitter';

export const Events = {
  PROGRESS_ADDED: 'PROGRESS_ADDED',
  PROGRESS_UPDATED: 'PROGRESS_UPDATED',
  IMAGE_STATUS_CHANGED: 'IMAGE_STATUS_CHANGED',
  LOCATION_UPDATED: 'LOCATION_UPDATED',
  LOCATION_DELETED: 'LOCATION_DELETED',
  IMAGE_CLEAN_PROCESS_UPDATED: 'image_clean_process_updated',
  FORCE_LOGOUT: 'FORCE_LOGOUT',
};

export default new EventEmitter();
