import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Button, Table } from 'react-bootstrap';
import Sidebar from 'components/Common/Sidebar';
import Footer from 'components/Common/Footer';
import { useToastContext } from 'auto-design-common';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import styles from './Webhooks.module.scss';
import useTranslation from '../../hooks/useTranslation';

export default function Webhooks() {
  const { tWebhooks, tCommon } = useTranslation();

  const [webhooks, setWebhooks] = useState([]);
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();

  const fetchWebhooks = useCallback(async () => {
    const { success, error, result } = await request(api.getWebhooks());

    if (!success) {
      toastError(error);
    } else {
      setWebhooks(result);
    }
  }, [request, toastError]);

  useEffect(() => {
    fetchWebhooks();
  }, [fetchWebhooks]);

  return (
    <div className="main webhooks">
      <Sidebar />
      <div className="right-part">
        <div className="content">
          <div className={styles.title}>
            {tWebhooks('title')}
          </div>
          <div>
            <div className={styles.wrapper}>
              <div className={styles.wrapperInner}>
                <div className={styles.buttonWrapper}>
                  <Button onClick={() => {
                    showModal(ModalKey.ADD_WEBHOOK, {
                      onDone: () => {
                        fetchWebhooks();
                      },
                    });
                  }}
                  >
                    {tCommon('add')}
                  </Button>
                </div>
                <Table striped bordered hover className={styles.table}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{tCommon('description')}</th>
                      <th>{tCommon('URL')}</th>
                      <th>{tCommon('createdAt')}</th>
                      <th>
                        {' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {webhooks.map((webhook, index) => (
                      <tr key={webhook.id}>
                        <td>{index + 1}</td>
                        <td>{webhook.description}</td>
                        <td>{webhook.url}</td>
                        <td>
                          {moment.utc(webhook.createdAt).local().format('MM-DD-yyyy HH:mm')}
                        </td>
                        <td style={{
                          width: 80,
                        }}
                        >
                          <Button
                            variant="danger"
                            onClick={async () => {
                            // eslint-disable-next-line no-restricted-globals
                              if (confirm(tWebhooks('deleteConfirmation'))) {
                                const { success } = await request(api.deleteWebhook(webhook.id));

                                if (success) {
                                  fetchWebhooks();
                                }
                              }
                            }}
                          >
                            {tCommon('delete')}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
