import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';
import * as api from 'utils/api';
import isURL from 'validator/lib/isURL';
import useTranslation from '../../hooks/useTranslation';

export default function AddWebhook({
  onClose,
  onDone,
}) {
  const { useTranslationOrigin, tCommon, tError } = useTranslation();
  const { t: tAddWebhookModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.addWebhook' });

  const [submitting, setSubmitting] = useState(false);
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [data, setData] = useState({
    description: '',
    url: '',
  });

  return (
    <Modal
      show
      backdrop="static"
      id="modal-add-webhook"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tAddWebhookModal('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            {tCommon('description')}
          </Form.Label>
          <Form.Control
            value={data.description}
            onChange={e => setData({
              ...data,
              description: e.target.value,
            })}
          />
          <Form.Label>
            {tCommon('URL')}
          </Form.Label>
          <Form.Control
            value={data.url}
            onChange={e => setData({
              ...data,
              url: e.target.value,
            })}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={submitting}
          onClick={async () => {
            if (!isURL(data.url)) {
              toastError(tError('invalidURL'));
              return;
            }

            setSubmitting(true);
            const { success, error } = await request(api.createWebhook({
              description: data.description,
              url: data.url,
            }));

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onDone();
              onClose();
            }
          }}
        >
          {tCommon('add')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
