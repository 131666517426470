export const dataURItoBlob = (dataURL) => {
  if (!window || window.window !== window) {
    throw new Error('This module is only available in browser');
  }

  const Blob = window.Blob || window.MozBlob || window.WebKitBlob;
  if (!Blob) {
    throw new Error('Blob was not supported');
  }

  const dataURLPattern = /^data:((.*?)(;charset=.*?)?)(;base64)?,/;

  // parse the dataURL components as per RFC 2397
  const matches = dataURL.match(dataURLPattern);
  if (!matches) {
    throw new Error('invalid dataURI');
  }

  // default to text/plain;charset=utf-8
  const mediaType = matches[2]
    ? matches[1]
    : `text/plain${matches[3] || ';charset=utf-8'}`;

  const isBase64 = !!matches[4];
  const dataString = dataURL.slice(matches[0].length);
  const byteString = isBase64
    // convert base64 to raw binary data held in a string
    ? atob(dataString)
    // convert base64/URLEncoded data component to raw binary
    : decodeURIComponent(dataString);

  const array = [];
  for (let i = 0; i < byteString.length; i++) {
    array.push(byteString.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: mediaType });
};

export const imgToBlob = (img) => {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(blob => {
        resolve(blob);
      });
    } catch (e) {
      reject(e);
    }
  });
};
