import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import xMark from 'assets/images/ic-line-x-mark.svg';
import ReactLoading from 'components/Common/ReactLoading';
import { getItem } from 'utils/api';
import useTranslation, { getTranslation } from '../../hooks/useTranslation';

export default function ItemDetail({
  onClose,
  itemId,
}) {
  const { tCommon } = useTranslation();
  const [item, setItem] = useState(null);

  useEffect(() => {
    getItem(itemId).then((item) => {
      setItem(item);
    });
  }, [itemId]);

  return (
    <Modal
      show
      backdrop="static"
      id="modal-item-detail"
    >
      <Modal.Header>
        <Modal.Title>
          {item ? getTranslation(item.translation.name, item.name) : `${tCommon('loading')}...`}
        </Modal.Title>
        <div
          className="close"
          onClick={onClose}
        >
          <img
            alt="close"
            src={xMark}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        {item ? (
          <img
            className="item-image"
            src={item.image}
            alt={item.name}
          />
        ) : (
          <div className="full-div-loading">
            <ReactLoading />
          </div>
        )}

      </Modal.Body>
    </Modal>
  );
}
