import { useState, useCallback } from 'react';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';

export default function useMutate(fn) {
  const [submitting, setSubmitting] = useState(false);
  const { request } = useAppContext();
  const { toastError } = useToastContext();

  const mutate = useCallback((...args) => {
    setSubmitting(true);

    return request(fn(...args))
      .then(res => {
        setSubmitting(false);
        if (!res.success) {
          toastError(res.error);
        }

        return res;
      });
  }, [fn, request, toastError]);

  return {
    submitting,
    mutate,
  };
}
