import React, { useState, useRef, useCallback, useEffect } from 'react';
import * as THREE from 'three';
import { useResizeDetector } from 'react-resize-detector';
import { useParams } from 'react-router-dom';
import * as api from 'utils/api';
import { useToastContext } from 'auto-design-common';
import { useAppContext } from 'components/AppContext';
import { setupTourEditor, createAnimator, calculateMousePosition, createHotpot, zoomTo, removeAllHotpots } from 'utils/tourEditorHelper';
import PanoZoomButtons from 'components/Common/PanoZoomButtons';
import { TOUR_ZOOM_DURATION } from 'constants/common';
import VRButton from './VRButton';
import useTranslation from '../../hooks/useTranslation';

export default function PublishedTour() {
  const { tError } = useTranslation();
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const canvasData = useRef({});
  const containerRef = useRef();
  const { tourVersionId } = useParams();
  const [webGLError, setWebGLError] = useState(null);
  const [renderer, setRenderer] = useState(null);
  const { ref: backgroundRef, height, width } = useResizeDetector();
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [tour, setTour] = useState(null);

  const goToDestination = useCallback(async (predefinedHotpot = null) => {
    const hotpot = predefinedHotpot;

    removeAllHotpots(canvasData);

    if (canvasData.current.renderer.xr.isPresenting) {
      setSelectedImageId(hotpot.destination);
    } else {
      setTimeout(() => {
        setSelectedImageId(hotpot.destination);
      }, TOUR_ZOOM_DURATION * 1000);
      await zoomTo(hotpot.sphere.position, canvasData);
    }
  }, []);

  useEffect(() => {
    request(api.getTourVersion(tourVersionId))
      .then(({ success, result, error }) => {
        if (!success) {
          toastError(error);
        } else {
          setTour(result);
          setSelectedImageId(result.metadata.entry);
        }
      });
  }, [tourVersionId, request, toastError]);

  useEffect(() => {
    if (!tour) {
      return null;
    }

    const { animate, cancel } = createAnimator();

    let canvas;
    let cancelled = false;

    (async () => {
      try {
        const data = await setupTourEditor({
          width,
          height,
          images: tour.metadata.results,
        });

        if (!cancelled) {
          canvas = data.renderer.domElement;
          containerRef.current?.appendChild(canvas);

          canvasData.current = {
            ...canvasData.current,
            ...data,
          };

          setRenderer(data.renderer);

          data.renderer.xr.enabled = true;

          animate({
            canvasData,
          });
        }
      } catch (e) {
        setWebGLError(e);
      }
    })();

    return () => {
      cancel();
      cancelled = true;
      const scene = canvasData.current?.scene;

      if (scene) {
        for (let i = scene.children.length - 1; i >= 0; i--) {
          const obj = scene.children[i];
          scene.remove(obj);
        }
      }

      canvasData.current?.renderer?.dispose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      canvas && containerRef.current?.removeChild(canvas);
    };
  }, [tour, width, height]);

  useEffect(() => {
    if (!renderer) {
      return;
    }

    const { spheres } = canvasData.current;

    Object.keys(spheres).forEach(id => {
      const sphere = spheres[id];
      sphere.visible = parseInt(id) === selectedImageId;
    });
  }, [selectedImageId, renderer]);

  useEffect(() => {
    if (!renderer) {
      return () => { };
    }

    const hotpots = tour.metadata.hotpots?.filter(hotpot => hotpot.source === selectedImageId) || [];

    hotpots
      .forEach(hotpot => {
        const [x, y, z] = hotpot.position;
        createHotpot({
          id: hotpot.id,
          position: new THREE.Vector3(x, y, z),
          source: hotpot.source,
          destination: hotpot.destination,
          canvasData,
        });
      });

    return () => {
      removeAllHotpots(canvasData);
    };
  }, [selectedImageId, tour, renderer]);

  useEffect(() => {
    renderer?.setSize(width, height);
  }, [width, height, renderer]);

  useEffect(() => {
    if (!renderer || webGLError) {
      return () => { };
    }

    const { panoViewer, controllers } = canvasData.current;

    const onMouseMove = (e) => {
      panoViewer.handleMouseMove(e);

      e.preventDefault();
      e.stopPropagation();
    };

    const onMouseDown = (e) => {
      const { raycaster, camera, hotpots } = canvasData.current;

      const { x, y } = calculateMousePosition({
        event: e,
        containerRef,
        width,
        height,
      });

      raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

      const placeHolders = hotpots
        .filter(hotpot => hotpot.source === selectedImageId)
        .map(hotpot => hotpot.placeHolder);

      const intersects = raycaster.intersectObjects(placeHolders);

      if (intersects.length > 0) {
        const placeHolder = intersects[0].object;
        const hotpot = canvasData.current.hotpots.find(h => h.id === placeHolder.objectId);
        goToDestination(hotpot);
      } else {
        panoViewer.handleMouseDown(e);
      }
    };

    const onDocumentMouseWheel = (event) => {
      event.preventDefault();
      const { camera } = canvasData.current;
      camera.fov = panoViewer.calculateFov(event, camera.fov);
      camera.updateProjectionMatrix();
    };

    const onMouseUp = () => {
      panoViewer.handleMouseUp();
    };

    const xrOnSelectEnd = (event) => {
      const controller = event.target;
      const tempMatrix = new THREE.Matrix4();
      tempMatrix.identity().extractRotation(controller.matrixWorld);
      const { controllerRaycaster, hotpots } = canvasData.current;
      controllerRaycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      controllerRaycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);
      const intersects = controllerRaycaster.intersectObjects(hotpots.map(h => h.placeHolder), false);

      if (intersects.length > 0) {
        const placeHolder = intersects[0].object;
        const hotpot = canvasData.current.hotpots.find(h => h.id === placeHolder.objectId);
        goToDestination(hotpot);
      }
    };

    containerRef.current.addEventListener('mousemove', onMouseMove, false);
    containerRef.current.addEventListener('mousedown', onMouseDown, false);
    containerRef.current.addEventListener('mouseup', onMouseUp, false);
    containerRef.current.addEventListener('touchmove', onMouseMove, false);
    containerRef.current.addEventListener('touchstart', onMouseDown, false);
    containerRef.current.addEventListener('touchend', onMouseUp, false);
    containerRef.current.addEventListener('touchcancel', onMouseUp, false);
    containerRef.current.addEventListener('wheel', onDocumentMouseWheel, false);

    controllers.forEach(controller => {
      controller.addEventListener('selectend', xrOnSelectEnd);
    });

    return () => {
      containerRef.current?.removeEventListener('mousemove', onMouseMove, false);
      containerRef.current?.removeEventListener('mousedown', onMouseDown, false);
      containerRef.current?.removeEventListener('mouseup', onMouseUp, false);
      containerRef.current?.removeEventListener('touchmove', onMouseMove, false);
      containerRef.current?.removeEventListener('touchstart', onMouseDown, false);
      containerRef.current?.removeEventListener('touchend', onMouseUp, false);
      containerRef.current?.removeEventListener('touchcancel', onMouseUp, false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current?.removeEventListener('wheel', onDocumentMouseWheel, false);

      controllers.forEach(controller => {
        controller.removeEventListener('selectend', xrOnSelectEnd);
      });
    };
  }, [renderer, height, width, webGLError, goToDestination, selectedImageId]);

  return (
    <div
      className="published-tour"
      ref={backgroundRef}
    >
      <PanoZoomButtons
        onZoomIn={() => {
          canvasData.current.panoViewer.zoomIn(canvasData.current.camera);
        }}
        onZoomOut={() => {
          canvasData.current.panoViewer.zoomOut(canvasData.current.camera);
        }}
      />
      {webGLError ? (
        <div className="error">
          <div className="title">
            {tError('webGLError.title')}
          </div>
          <div className="description">
            {tError('webGLError.descriptionPrefix')}
            {' '}
            {webGLError.message}
          </div>
        </div>
      ) : (
        <div
          ref={containerRef}
          className="viewer"
        />
      )}
      <VRButton renderer={renderer} />
    </div>
  );
}
