import React, { useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import NextButton from 'components/Common/NextButton';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';
import { resetPassword } from 'utils/api';
import { validatePassword } from 'utils/password';
import AuthWrapper from './AuthWrapper';
import useTranslation from '../../hooks/useTranslation';

export default function PasswordResetConfirmation() {
  const { tResetPassword } = useTranslation();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [token] = useQueryParam('token', StringParam);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { toastError, toastSuccess } = useToastContext();
  const { request, logout } = useAppContext();
  const [error, setError] = useState({});

  const submit = async () => {
    if (!validatePassword(password)) {
      setError({
        password: tResetPassword('passwordValidation'),
      });
      return;
    }

    if (password !== passwordConfirmation) {
      setError({
        passwordConfirmation: tResetPassword('passwordNotMatch'),
      });
      return;
    }

    setError({});
    setSubmitting(true);
    const { success, error } = await request(resetPassword(token, password));

    if (!success) {
      toastError(error);
    } else {
      toastSuccess(tResetPassword('passwordResetSuccess'));
      logout();
      history.push('/get-started');
    }

    setSubmitting(false);
  };

  return (
    <AuthWrapper>
      <div className="header">
        <div className="title">{tResetPassword('enterYourNewPassword')}</div>
      </div>

      <div className="body">
        <Form.Group className="form-group" hasValidation>
          <Form.Label>{(tResetPassword('newPassword'))}</Form.Label>
          <Form.Control
            type="password"
            isInvalid={!!error.password}
            disabled={submitting}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {error.password && (
            <Form.Text className="u-textNegative">
              {error.password}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group className="form-group" hasValidation>
          <Form.Label>{tResetPassword('newPasswordConfirmation')}</Form.Label>
          <Form.Control
            type="password"
            isInvalid={!!error.passwordConfirmation}
            disabled={submitting}
            value={passwordConfirmation}
            onChange={e => setPasswordConfirmation(e.target.value)}
          />
          {error.passwordConfirmation && (
          <Form.Text className="u-textNegative">
            {error.passwordConfirmation}
          </Form.Text>
          )}
        </Form.Group>

        <NextButton
          text={tResetPassword('setPassword')}
          className="submit-button"
          disabled={submitting}
          onClick={submit}
        />
      </div>
    </AuthWrapper>
  );
}
