import { Auth } from 'auto-design-common';
import { storage } from './storage';

export const auth = new Auth(storage);

export const getToken = auth.getToken;

export const isAuth = auth.isAuth;

export const setAuth = auth.setAuth;

export const logout = auth.logout;
