import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import copy from 'copy-to-clipboard';
import { useToastContext } from 'auto-design-common';
import * as api from 'utils/api';
import referImg from 'assets/images/referral-marketing.webp';
import styles from './InviteFriends.module.scss';
import useTranslation from '../../hooks/useTranslation';

export default function InviteFriends({
  onClose,
}) {
  const { tCommon, useTranslationOrigin } = useTranslation();
  const { t: tModalInviteFriends } = useTranslationOrigin('translation', { keyPrefix: 'modal.inviteFriends' });

  const { request } = useAppContext();
  const { toastError, toastSuccess } = useToastContext();
  const [referToken, setReferToken] = useState(null);

  const inviteUrl = `${window.origin}/get-started?referToken=${referToken}`;

  const copyUrl = () => {
    copy(inviteUrl);
    toastSuccess(tCommon('copiedURL'));
  };

  useEffect(() => {
    request(api.getReferToken())
      .then(res => {
        if (!res.success) {
          toastError(res.error);
        } else {
          setReferToken(res.result.referToken);
        }
      });
  }, [request, toastError]);

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-invite-friends"
    >
      <Modal.Body>
        <div className={styles.container}>
          <img
            className={styles.img}
            src={referImg}
            alt="Intro"
          />
          <div className={styles.title}>
            {tModalInviteFriends('title')}
          </div>
          <div className={styles.description}>
            {tModalInviteFriends('description_1')}
            {' '}
            <span style={{
              fontWeight: 'bold',
              fontSize: 20,
            }}
            >
              {tModalInviteFriends('description_2')}
            </span>
          </div>
          <div className={styles.description}>
            {tModalInviteFriends('description_3')}
          </div>
          <div className={styles.link} onClick={copyUrl}>
            {inviteUrl}
          </div>
          <Button
            className={styles.close}
            onClick={onClose}
          >
            {tCommon('gotIt')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
