import React from 'react';
import { useHistory } from 'react-router-dom';
import backArrow from 'assets/images/ic-line-arrow-left.svg';
import AuthWrapper from './AuthWrapper';
import useTranslation from '../../hooks/useTranslation';

export default function Login() {
  const { tCommon, tSignUp } = useTranslation();
  const history = useHistory();

  return (
    <AuthWrapper>
      <div className="actions">
        <img
          alt="back"
          src={backArrow}
          className="u-cursorPointer"
          onClick={() => {
            history.replace('/get-started');
          }}
        />
      </div>
      <div className="header">
        <div className="title">{tCommon('sorry')}</div>
        <div className="sub-title">{tSignUp('weCantFindYourAccount')}</div>
      </div>
    </AuthWrapper>
  );
}
