import React from 'react';
import { useHistory } from 'react-router-dom';
import configs from 'configuration';
import useTranslation from '../../hooks/useTranslation';

export default function Footer() {
  const { tCommon } = useTranslation();
  const history = useHistory();

  return (
    <div className="footer">
      <div className="copy-right">
        ©
        {' '}
        {new Date().getFullYear()}
        .
        {' '}
        {tCommon('allRightsReserved')}
      </div>
      <div className="nav-items">
        <div
          className="nav-item"
          onClick={() => {
            window.location.href = configs.termsOfServicesUrl;
          }}
        >
          {tCommon('termsOfServices')}
        </div>
        <div className="separator" />
        <div
          className="nav-item"
          onClick={() => {
            if (configs.instructionUrl) {
              window.open(configs.instructionUrl);
            } else {
              history.push(configs.instructionUrl, '/instructions');
              window.scroll(0, 0);
            }
          }}
        >
          {tCommon('instructionsForUse')}
        </div>
        <div className="separator" />
        <div
          className="nav-item"
          onClick={() => {
            window.location.href = configs.privacyPolicyUrl;
          }}
        >
          {tCommon('privacyPolicy')}
        </div>
      </div>
    </div>
  );
}
