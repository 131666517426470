import React, { useState, useRef, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { setupPanoViewer, createAnimator } from 'utils/panoViewerHelpers';
import useTranslation from '../../hooks/useTranslation';

export default function PanoViewer({
  imageUrl,
}) {
  const { tPanoViewer } = useTranslation();
  const [webGLError, setWebGLError] = useState(null);
  const containerRef = useRef();
  const canvasData = useRef({});
  const [renderer, setRenderer] = useState(null);
  const { ref: backgroundRef, height, width } = useResizeDetector();

  useEffect(() => {
    const { animate, cancel } = createAnimator();

    let canvas;

    (async () => {
      try {
        const data = await setupPanoViewer({
          imageUrl,
          width,
          height,
        });

        canvas = data.renderer.domElement;
        containerRef.current?.appendChild(canvas);

        canvasData.current = {
          ...canvasData.current,
          ...data,
        };

        setRenderer(data.renderer);

        animate({
          canvasData,
        });
      } catch (e) {
        setWebGLError(e);
      }
    })();

    return () => {
      cancel();
      const scene = canvasData.current?.scene;

      if (scene) {
        for (let i = scene.children.length - 1; i >= 0; i--) {
          const obj = scene.children[i];
          scene.remove(obj);
        }
      }

      canvasData.current?.renderer?.dispose();
      canvas && containerRef.current?.removeChild(canvas);
    };
  }, [width, height, imageUrl]);

  useEffect(() => {
    renderer?.setSize(width, height);
  }, [width, height, renderer]);

  useEffect(() => {
    if (!renderer) {
      return () => { };
    }

    const { panoViewer } = canvasData.current;

    const onMouseMove = (e) => {
      panoViewer.handleMouseMove(e);
      e.preventDefault();
      e.stopPropagation();
    };

    const onMouseDown = (e) => {
      panoViewer.handleMouseDown(e);
    };

    const onDocumentMouseWheel = (event) => {
      event.preventDefault();
      const { camera, panoViewer } = canvasData.current;
      camera.fov = panoViewer.calculateFov(event, camera.fov);
      camera.updateProjectionMatrix();
    };

    const onMouseUp = () => {
      panoViewer.handleMouseUp();
    };

    containerRef.current.addEventListener('mousemove', onMouseMove, false);
    containerRef.current.addEventListener('mousedown', onMouseDown, false);
    containerRef.current.addEventListener('mouseup', onMouseUp, false);
    containerRef.current.addEventListener('touchmove', onMouseMove, false);
    containerRef.current.addEventListener('touchstart', onMouseDown, false);
    containerRef.current.addEventListener('touchend', onMouseUp, false);
    containerRef.current.addEventListener('touchcancel', onMouseUp, false);
    containerRef.current.addEventListener('wheel', onDocumentMouseWheel, false);

    return () => {
      containerRef.current?.removeEventListener('mousemove', onMouseMove, false);
      containerRef.current?.removeEventListener('mousedown', onMouseDown, false);
      containerRef.current?.removeEventListener('mouseup', onMouseUp, false);
      containerRef.current?.removeEventListener('touchmove', onMouseMove, false);
      containerRef.current?.removeEventListener('touchstart', onMouseDown, false);
      containerRef.current?.removeEventListener('touchend', onMouseUp, false);
      containerRef.current?.removeEventListener('touchcancel', onMouseUp, false);
      containerRef.current?.removeEventListener('wheel', onDocumentMouseWheel, false);
    };
  }, [renderer]);

  return (
    <div
      className="pano-viewer"
      ref={backgroundRef}
    >
      {webGLError ? (
        <div className="error">
          <div className="title">
            {tPanoViewer('title')}
          </div>
          <div className="description">
            {tPanoViewer('error')}
            {' '}
            {webGLError.message}
          </div>
        </div>
      ) : (
        <div
          ref={containerRef}
          className="viewer"
        />
      )}
    </div>
  );
}
