import React from 'react';
import LandingHeader from 'components/Common/LandingHeader';
import LandingFooter from 'components/Common/LandingFooter';
import ReactMarkdown from 'react-markdown';
import RemarkGFM from 'remark-gfm';
import { INSTRUCTIONS_MARKDOWN } from '../../i18n/generated/en/common';
import useTranslation from '../../hooks/useTranslation';

export default function Instructions() {
  const { tCommon } = useTranslation();
  return (
    <div className="instructions">
      <LandingHeader />
      <div className="content-wrapper">
        <div className="instructions-content">
          <h1>
            {tCommon('instructionsForUse')}
          </h1>
          <br />
          <ReactMarkdown remarkPlugins={[RemarkGFM]}>
            {INSTRUCTIONS_MARKDOWN}
          </ReactMarkdown>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
}
