import React from 'react';
import notFoundImg from 'assets/images/page-not-found.svg';
import useTranslation from '../../hooks/useTranslation';

export default function Empty() {
  const { tProgress } = useTranslation();
  return (
    <div className="progress-empty">
      <img
        alt="design"
        src={notFoundImg}
      />
      <div className="title">
        {tProgress('empty.title')}
      </div>
      <div className="sub-title">
        {tProgress('empty.subTitle')}
      </div>
    </div>
  );
}
