import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import introImg from 'assets/images/pano-image-intro.svg';
import { useAppContext } from 'components/AppContext';
import useTranslation from '../../hooks/useTranslation';

export default function PanoImageIntro({
  onClose,
}) {
  const { useTranslationOrigin, tCommon, tBetates } = useTranslation();
  const { t: tPanoImageIntroModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.panoImageIntro' });

  const { configs } = useAppContext();
  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-pano-image-intro"
    >
      <Modal.Body>
        <img
          src={introImg}
          alt="Intro"
        />
        <div className="title">
          {tPanoImageIntroModal('title')}
        </div>
        <div className="description">
          {tPanoImageIntroModal('description')}
          {' '}
          {configs.pano_image_price}
          {' '}
          {' '}
          {tBetates('coin', {
            count: configs.pano_image_price,
          })}
          {' '}
          {tPanoImageIntroModal('perImage')}
        </div>
        <Button onClick={onClose}>
          {tCommon('gotIt')}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
