import React from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkGFM from 'remark-gfm';
import LandingHeader from 'components/Common/LandingHeader';
import LandingFooter from 'components/Common/LandingFooter';
import _ from 'lodash';
import { PRIVACY_POLICY_MARKDOWN } from '../../i18n/generated/en/common';
import useTranslation from '../../hooks/useTranslation';

export default function PrivacyPolicy() {
  const { tCommon } = useTranslation();

  return (
    <div className="privacy-policy">
      <LandingHeader />
      <div className="content-wrapper">
        <div className="content">
          <h1>{_.startCase(tCommon('privacyPolicy'))}</h1>
          <br />
          <ReactMarkdown remarkPlugins={[RemarkGFM]}>
            {PRIVACY_POLICY_MARKDOWN}
          </ReactMarkdown>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
}
