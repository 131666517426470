import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import { useToastContext, ImageType, withMinimumScreenWidth } from 'auto-design-common';
import emitter, { Events } from 'utils/event';
import { getFileUrl } from 'utils/file';
import Slider from 'components/Common/Slider';
import { ReactComponent as UndoSVG } from 'assets/images/undo.svg';
import { ReactComponent as RedoSVG } from 'assets/images/redo.svg';
import { ReactComponent as ResetSVG } from 'assets/images/reset.svg';
import { Button } from 'react-bootstrap';
import { imgToBlob } from 'utils/blob';
import { getBase64Strings } from 'exif-rotate-js';
import styles from './ImageCleaner.module.scss';
import { Screen } from './constants';
import useTranslation from '../../hooks/useTranslation';

const INITIAL_BRUSH_SIZE = 100;

function ImageCleaner({
  backgroundFile,
  setScreen,
  onSubmit,
  submitting,
}) {
  const { tAddDesign, tCommon } = useTranslation();

  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [brushSize, setBrushSize] = useState(INITIAL_BRUSH_SIZE);
  const [currentProcessId, setCurrentProcessId] = useState(null);
  const [processing, setProcessing] = useState(true);
  const [steps, setSteps] = useState([]);
  const backgroundWrapperRef = useRef();
  const currentImg = useMemo(() => steps.find(step => step.isCurrent)?.img, [steps]);
  const currentStepIndex = useMemo(() => steps.findIndex((step => step.isCurrent)), [steps]);
  const autoCleanedRef = useRef(false);
  const [mousePosition, setMousePosition] = useState(null);

  const canvasRef = useRef();
  const overlayCanvasRef = useRef();
  const draggingRef = useRef(false);
  const lastMousePositionRef = useRef(null);

  const { ref: photoAreaRef, height: photoAreaHeight, width: photoAreaWidth } = useResizeDetector();
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const widthScale = size.width / photoAreaWidth;
  const heightScale = size.height / photoAreaHeight;
  const scale = 1 / (widthScale > heightScale ? widthScale : heightScale);

  const realBrushSize = useMemo(() => brushSize / scale, [brushSize, scale]);

  const canvasBoundingRect = canvasRef.current?.getBoundingClientRect();
  const photoAreaRect = photoAreaRef.current?.getBoundingClientRect();

  const offsetX = canvasBoundingRect?.x - photoAreaRect?.x;
  const offsetY = canvasBoundingRect?.y - photoAreaRect?.y;

  const getMouseCoordinate = (e) => {
    const canvasBoundingRect = canvasRef.current.getBoundingClientRect();

    const x = e.pageX - canvasBoundingRect.x;
    const y = e.pageY - canvasBoundingRect.y;

    return { x: x / scale, y: y / scale };
  };

  const drawCircle = (x, y) => {
    const ctx = overlayCanvasRef.current.getContext('2d');
    ctx.beginPath();
    ctx.fillStyle = 'rgba(228,0,0,1)';
    ctx.arc(x, y, realBrushSize / 2, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.closePath();
  };

  const drawLine = (oldX, oldY, x, y) => {
    const ctx = overlayCanvasRef.current.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(oldX, oldY);
    ctx.lineTo(x, y);
    ctx.lineWidth = realBrushSize;
    ctx.strokeStyle = 'rgba(228,0,0,1)';
    ctx.stroke();
    ctx.closePath();
  };

  const onMouseDown = (e) => {
    const { x, y } = getMouseCoordinate(e);
    draggingRef.current = true;
    drawCircle(x, y);
  };

  const onMouseUp = () => {
    window.gtag('event', 'clean_image_manually');
    const { width, height } = size;
    const overlayCtx = overlayCanvasRef.current.getContext('2d');
    draggingRef.current = false;
    lastMousePositionRef.current = null;

    const maskBlackCanvas = document.createElement('canvas');
    maskBlackCanvas.width = width;
    maskBlackCanvas.height = height;
    const maskBlackCtx = maskBlackCanvas.getContext('2d');
    maskBlackCtx.fillStyle = 'rgb(0, 0, 0)';
    maskBlackCtx.fillRect(0, 0, width, height);
    maskBlackCtx.globalCompositeOperation = 'destination-out';
    maskBlackCtx.drawImage(overlayCanvasRef.current, 0, 0);
    const maskWhiteCanvas = document.createElement('canvas');
    maskWhiteCanvas.width = width;
    maskWhiteCanvas.height = height;
    const maskWhiteCtx = maskWhiteCanvas.getContext('2d');
    maskWhiteCtx.fillStyle = 'rgb(255, 255, 255)';
    maskWhiteCtx.fillRect(0, 0, width, height);
    maskWhiteCtx.drawImage(maskBlackCanvas, 0, 0);

    setProcessing(true);

    maskWhiteCanvas.toBlob(async (maskBlob) => {
      const imageBlob = await imgToBlob(currentImg);
      overlayCtx.clearRect(0, 0, width, height);
      const { success, result, error } = await request(api.cleanImageManually(imageBlob, maskBlob));

      if (success) {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(result);
        const img = new Image();
        img.crossOrigin = true;
        img.src = imageUrl;
        img.onload = () => {
          setSteps(steps => {
            const newSteps = steps.slice(0, currentStepIndex + 1).map((step) => ({
              ...step,
              isCurrent: false,
            }));
            newSteps.push({
              img,
              isCurrent: true,
            });
            return newSteps;
          });
          setProcessing(false);
        };
      } else {
        toastError(error);
        setProcessing(false);
      }
    });
  };

  const onMouseMove = (e) => {
    const { x, y } = getMouseCoordinate(e);

    setMousePosition({
      x,
      y,
    });

    const lastMousePosition = lastMousePositionRef.current;

    if (draggingRef.current) {
      if (!lastMousePosition) {
        drawCircle(x, y);
      } else {
        drawCircle(lastMousePosition.x, lastMousePosition.y);
        drawLine(lastMousePosition.x, lastMousePosition.y, x, y);
        drawCircle(x, y);
      }

      lastMousePositionRef.current = {
        x,
        y,
      };
    }
  };

  const undo = () => {
    setSteps(steps => steps.map((step, index) => {
      if (index === currentStepIndex - 1) {
        return {
          ...step,
          isCurrent: true,
        };
      }
      return {
        ...step,
        isCurrent: false,
      };
    }));
  };

  const redo = () => {
    setSteps(steps => steps.map((step, index) => {
      if (index === currentStepIndex + 1) {
        return {
          ...step,
          isCurrent: true,
        };
      }
      return {
        ...step,
        isCurrent: false,
      };
    }));
  };

  const reset = () => {
    setSteps(steps => [
      ...steps.map(step => ({
        ...step,
        isCurrent: false,
      })),
      {
        ...steps[0],
        isCurrent: true,
      },
    ]);
  };

  const finish = async () => {
    const blob = await imgToBlob(currentImg);
    blob.name = backgroundFile.name;
    const [data] = await getBase64Strings([blob], {
      maxSize: 10000,
      quality: 1,
    });
    await onSubmit({
      background: {
        file: blob,
        data,
      },
      type: ImageType.NORMAL,
    });
    setScreen(Screen.INFORMATION);
  };

  useEffect(() => {
    if (autoCleanedRef.current) {
      return;
    }

    request(api.cleanImageAutomatically(backgroundFile.file))
      .then(res => {
        if (!res.success) {
          toastError(res.error);
        } else {
          setCurrentProcessId(res.result.processId);
          autoCleanedRef.current = true;
        }
      });
  }, [backgroundFile, request, toastError]);

  useEffect(() => {
    const img = new Image();
    img.crossOrigin = true;
    img.src = backgroundFile.data;
    img.onload = () => {
      setSteps([{
        img,
        isCurrent: true,
      }]);
    };
  }, [backgroundFile]);

  useEffect(() => {
    if (currentImg) {
      const { height, width } = currentImg;
      setSize({
        height,
        width,
      });
      overlayCanvasRef.current.height = height;
      overlayCanvasRef.current.width = width;
    }
  }, [currentImg]);

  useEffect(() => {
    if (!currentProcessId) {
      return () => {};
    }

    const listener = emitter.addListener(Events.IMAGE_CLEAN_PROCESS_UPDATED, ({ processId, resultFileId, status }) => {
      if (processId === currentProcessId) {
        if (status === 'user_modifying') {
          const img = new Image();
          img.crossOrigin = true;
          img.src = getFileUrl(resultFileId);
          img.onload = function onload() {
            setSteps(steps => {
              const newSteps = steps.map((step) => ({
                ...step,
                isCurrent: false,
              }));
              newSteps.push({
                img,
                isCurrent: true,
              });
              return newSteps;
            });
            setProcessing(false);
          };
        }

        if (status === 'failed') {
          setProcessing(false);
          toastError('Something went wrong when AI processing your image. Please remove furniture items manually.');
        }
      }
    });

    return () => {
      listener.remove();
    };
  }, [currentProcessId, toastError]);

  useEffect(() => {
    backgroundWrapperRef.current.innerHTML = '';
    if (currentImg) {
      backgroundWrapperRef.current.appendChild(currentImg);
    }
  }, [currentImg]);

  useEffect(() => {
    window.gtag('event', 'open_image_cleaner');
  }, []);

  return (
    <div className={styles.imageCleaner}>
      <div
        ref={photoAreaRef}
        className={styles.photoArea}
      >
        <div
          ref={canvasRef}
          className={styles.canvas}
          style={{
            transform: `scale(${scale})`,
            position: 'relative',
            pointerEvents: processing ? 'none' : undefined,
          }}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseEnter={(e) => {
            const { x, y } = getMouseCoordinate(e);
            setMousePosition({
              x,
              y,
            });
          }}
          onMouseLeave={(e) => {
            setMousePosition(null);
            if (draggingRef.current) {
              onMouseUp(e);
            }
          }}
        >
          <canvas
            style={{
              zIndex: 3,
              pointerEvents: 'none',
              opacity: 0.7,
            }}
            ref={overlayCanvasRef}
          />
          <div
            ref={backgroundWrapperRef}
          />
          {processing && (
            <div
              className={styles.processing}
            />
          ) }
        </div>
        <div
          className={styles.cursor}
          style={{
            zIndex: 3,
            left: mousePosition ? mousePosition.x * scale + offsetX - brushSize / 2 : 0,
            top: mousePosition ? mousePosition.y * scale + offsetY - brushSize / 2 : 0,
            height: brushSize,
            width: brushSize,
            borderRadius: brushSize,
            display: mousePosition ? 'block' : 'none',
          }}
        />
      </div>
      <div className={styles.toolsWrapper}>
        <div
          className={styles.tools}
          style={{
            pointerEvents: (submitting || processing) ? 'none' : undefined,
            opacity: (submitting || processing) ? '0.5' : 1,
          }}
        >
          <div className={styles.brushSize}>
            <span>
              {tAddDesign('brush')}
            </span>
            <Slider
              min={5}
              max={500}
              value={brushSize}
              onChange={(value) => {
                setBrushSize(value);
              }}
            />
          </div>
          <div className={styles.undoRedoButtons}>
            <button type="button" onClick={undo} className={styles.undoRedoButton} disabled={currentStepIndex === 0}>
              <UndoSVG />
            </button>
            <button type="button" onClick={redo} className={styles.undoRedoButton} disabled={currentStepIndex === steps.length - 1}>
              <RedoSVG />
            </button>
          </div>
          <Button
            variant="secondary"
            className={styles.backButton}
            onClick={() => {
              setScreen(Screen.INFORMATION);
            }}
          >
            {tCommon('back')}
          </Button>
          <Button
            className={styles.finishButton}
            onClick={finish}
          >
            {tCommon('finish')}
          </Button>
          <button
            className={styles.resetButton}
            type="button"
            onClick={reset}
          >
            <ResetSVG />
            {tCommon('reset')}
          </button>
        </div>

      </div>
    </div>
  );
}

export default withMinimumScreenWidth(1180)(ImageCleaner);
