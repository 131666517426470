import React, { useEffect, useState, useCallback } from 'react';
import { useToastContext } from 'auto-design-common';
import { getTranslation } from 'hooks/useTranslation';
import CatalogHeader from './CatalogHeader';

function CategoryItem({
  category,
  renderChildrenCount,
  onClick,
}) {
  return (
    <div className="col-xxl-3 col-xl-4 col-lg-6 category-item-wrapper">
      <div
        className="category-item"
        onClick={() => onClick(category)}
      >
        <img className="thumbnail" src={category.thumbnail} alt="thumbnail" />
        <div className="info">
          <div className="name">
            {getTranslation(category.translation.name, category.name)}
          </div>
          <div className="children-count">
            {renderChildrenCount(category)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CategoryList({
  fetchFunc,
  renderHeader,
  renderChildrenCount,
  onClick,
}) {
  const { toastError } = useToastContext();
  const [categories, setCategories] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const categories = await fetchFunc();
      setCategories(categories);
    } catch (error) {
      toastError(error);
    }
  }, [fetchFunc, toastError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="categories">
      <CatalogHeader
        renderHeader={renderHeader}
      />
      <div
        className="list row"
      >
        {categories.map(category => (
          <CategoryItem
            key={category.id}
            category={category}
            renderChildrenCount={renderChildrenCount}
            onClick={onClick}
          />
        ))}
      </div>

    </div>
  );
}
