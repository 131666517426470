import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import penIcon from 'assets/images/ic-line-pen.svg';
import checkIcon from 'assets/images/ic-line-check.svg';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import { Icon } from 'auto-design-common';

export default function TransparentInput({
  value,
  onChange,
  label,
  onSubmit,
  error,
  readOnly,
}) {
  const inputRef = useRef();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  return (
    <Form.Group className="form-group transparent-form-group">
      <Form.Label>
        {!readOnly && (
          <img
            alt="edit"
            src={penIcon}
            onClick={() => {
              !readOnly && setEditing(true);
            }}
          />
        )}
        <span>
          {label}
        </span>
      </Form.Label>
      <div className="right">
        <div className="input">
          <Form.Control
            className={error && 'invalid'}
            ref={inputRef}
            value={value}
            onChange={onChange}
            disabled={!editing}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setEditing(false);
                !error && onSubmit?.();
              }
            }}
            onBlur={() => {
              setEditing(false);
              !error && onSubmit?.();
            }}
          />
          {editing && !error && (
            <Icon
              src={checkIcon}
            />
          )}

          {error && (
            <Icon
              src={xIcon}
              stroke="red"
            />
          )}
        </div>

        {error && (
          <Form.Text className="u-textNegative">
            {error}
          </Form.Text>
        )}
      </div>

    </Form.Group>
  );
}
