import React, { useEffect, useState, useCallback } from 'react';
import arrowIcon from 'assets/images/ic-line-arrow-right.svg';
import { useToastContext, Icon } from 'auto-design-common';
import { getTranslation } from 'hooks/useTranslation';
import CatalogHeader from './CatalogHeader';

function RoomItem({
  room,
  renderChildrenCount,
  onClick,
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <div className="col-xxl-3 col-xl-4 col-lg-6">
      <div
        className="room-item"
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        onClick={() => onClick(room)}
      >
        <img className="thumbnail" src={room.thumbnail} alt="thumbnail" />
        <div className="info-container">
          <div className="info">
            <div className="name">
              { getTranslation(room.translation.name, room.name)}
            </div>
            <div className="children-count">
              {renderChildrenCount(room)}
            </div>
          </div>
          {hovered && (
            <div className="view-button">
              <Icon src={arrowIcon} fill="white" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function RoomList({
  fetchFunc,
  renderHeader,
  renderChildrenCount,
  onClick,
  orderRooms,
}) {
  const { toastError } = useToastContext();
  const [rooms, setCategories] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const rooms = await fetchFunc();
      setCategories(rooms);
    } catch (error) {
      toastError(error);
    }
  }, [fetchFunc, toastError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const items = orderRooms ? orderRooms(rooms) : rooms;

  return (
    <div className="rooms">
      <CatalogHeader
        renderHeader={renderHeader}
      />
      <div
        className="list row"
      >
        {items.map(room => (
          <RoomItem
            key={room.id}
            room={room}
            renderChildrenCount={renderChildrenCount}
            onClick={onClick}
          />
        ))}
      </div>

    </div>
  );
}
