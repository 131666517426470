import React, { useState, useRef } from 'react';
import { Overlay, Tooltip, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Icon } from 'auto-design-common';
import plusIcon from 'assets/images/ic-line-plus.svg';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import useTranslation from '../../hooks/useTranslation';

export default function CreateTourButton({
  locationId,
}) {
  const { tLocations, tError, tCommon, tApiError } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const { request } = useAppContext();
  const [error, setError] = useState(null);
  const buttonRef = useRef();
  const history = useHistory();

  const onClick = async () => {
    setSubmitting(true);
    setError(null);

    const { success, result, error } = await request(api.createTour(locationId));

    if (!success) {
      setError(error);
    } else {
      history.push(`/tours/${result.id}/edit`);
    }

    setSubmitting(false);
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        onClick={onClick}
      >
        <Icon
          src={plusIcon}
          fill="white"
        />
        {tLocations('createTour.button')}
      </Button>
      <Overlay
        target={buttonRef}
        placement="bottom-start"
        delay={{ show: 250, hide: 400 }}
        show={!!error}
      >
        <Tooltip id="create-tour-error">
          <div className="title">{tLocations('createTour.youCannotCreateTour')}</div>
          <div className="content">{tApiError(error?.data?.errorCode) || tError('somethingWentWrong')}</div>
          <Button
            className="close-button"
            onClick={() => setError(null)}
            disabled={submitting}
          >
            {tCommon('gotIt')}
          </Button>
        </Tooltip>
      </Overlay>
    </div>
  );
}
