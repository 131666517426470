/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip, Button, Form } from 'react-bootstrap';
import exifr from 'exifr';
import { AUTO_STAGING_DEFAULT_PREFERENCES, useToastContext, Icon, ImageType } from 'auto-design-common';
import { getBase64Strings } from 'exif-rotate-js';
import coinIcon from 'assets/images/Auto-Coin.svg';
import infoIcon from 'assets/images/ic-line-info.svg';
import plusIcon from 'assets/images/ic-line-plus.svg';
import uploadImg from 'assets/images/upload.svg';
import uploadIcon from 'assets/images/ic-fill-upload.svg';
import xMark from 'assets/images/ic-line-x-mark.svg';
import locationIcon from 'assets/images/ic-line-location.svg';
import transparentX from 'assets/images/transparent-x.svg';
import normalImageIcon from 'assets/images/image-type-normal.svg';
import panoImageIcon from 'assets/images/image-type-360.svg';
import { useAppContext } from 'components/AppContext';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import InputWithDropdown from 'components/Common/InputWithDropdown';
import DropFileContainer from 'components/Common/DropFileContainer';
import PanoViewer from 'components/Common/PanoViewer';
import { getItem, setItem } from 'utils/storage';
import config from 'configuration';
import styles from './Information.module.scss';
import { Screen } from './constants';
import AutoStaging from './AutoStaging';
import useTranslation from '../../hooks/useTranslation';

export default function Information({
  backgroundFile,
  setBackgroundFile,
  setScreen,
  onSubmit,
  locationName,
  setLocationName,
  selectedLocation,
  setSelectedLocation,
  locations,
  submitting,
  imageName,
  setImageName,
  autoStaging,
  setAutoStaging,
}) {
  const { tAddDesign, tCommon, tError, tBetates } = useTranslation();

  const fileInputRef = useRef();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();
  const { user, configs, getInfo } = useAppContext();

  const [type, setType] = useState(ImageType.NORMAL);
  const [acceptAutoClean, setAcceptAutoClean] = useState(false);

  const hasEnoughBalance = user.balance >= configs.image_price;
  const disableUpload = !backgroundFile || !imageName || !locationName || submitting || !hasEnoughBalance;

  const handleSubmit = async () => {
    if (acceptAutoClean) {
      setScreen(Screen.IMAGE_CLEANER);
      return;
    }

    try {
      await onSubmit({
        background: backgroundFile,
        type,
      });

      getInfo();
      setImageName('');
      setLocationName('');
    } catch (e) {
      toastError(e);
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      setLocationName(selectedLocation.name);
    }
  }, [selectedLocation, setLocationName]);

  useEffect(() => {
    if (!config.disablePanoImage && configs.show_pano_image_intro && !getItem('pano_image_intro_shown')) {
      showModal(ModalKey.PANO_IMAGE_INTRO);
      setItem('pano_image_intro_shown', true);
    }
  }, [configs, showModal]);

  useEffect(() => {
    if (backgroundFile && !config.disablePanoImage) {
      (async () => {
        const output = await exifr.parse(backgroundFile.file, { xmp: true });
        if (output?.ProjectionType === 'equirectangular') {
          setType(ImageType.PANO);
        } else {
          setType(ImageType.NORMAL);
        }
      })();
    }
  }, [backgroundFile]);

  useEffect(() => {
    if (type === ImageType.PANO) {
      setAcceptAutoClean(false);
    }
  }, [type]);

  const backgroundUrl = backgroundFile?.data;

  return (
    <>
      <div className="header">
        <div className="balance">
          <img
            alt="balance"
            src={coinIcon}
          />
          <span className="text">
            {tAddDesign('yourCoin')}
            :
          </span>
          <span className="number">
            {user.balance}
          </span>
          <OverlayTrigger
            placement="bottom-start"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip id="coin-info-tooltip" {...props}>
                <div className="tooltip-content">
                  <div className="tooltip-title">
                    {tAddDesign('whatAreCoins')}
                  </div>
                  <ul>
                    <li>
                      {tAddDesign('spendCoin')}
                    </li>
                    <li>
                      {tAddDesign('everyImageCosts')}
                      {' '}
                      {configs.image_price}
                      {' '}
                      {tBetates('coin', {
                        count: configs.image_price,
                      })}
                    </li>
                    <li>
                      {tAddDesign('earlyBeta')}
                    </li>
                  </ul>
                </div>
              </Tooltip>
            )}
          >
            <img
              alt="info"
              src={infoIcon}
              className="info"
            />
          </OverlayTrigger>

          <Button
            variant="secondary"
            className="small"
            onClick={() => {
              showModal(ModalKey.TOP_UP);
            }}
          >
            <img
              alt="add"
              src={plusIcon}
            />
            {tCommon('addMore')}
          </Button>
        </div>
        {!hasEnoughBalance && (
        <div className="insufficient-balance">
          {tAddDesign('insufficientBalance')}
        </div>
        )}
      </div>
      <div className="body">
        {backgroundFile ? (
          <>
            <div className="row">
              <div className="image-area col-lg-6 col-md-12">
                {type === ImageType.NORMAL ? (
                  <img
                    className="background-image"
                    alt={backgroundFile.file.name}
                    src={backgroundUrl}
                  />
                ) : (
                  <div className="pano-background-image">
                    <PanoViewer
                      imageUrl={backgroundUrl}
                    />
                  </div>
                )}
                <img
                  className="reset-button"
                  alt="reset"
                  src={transparentX}
                  onClick={() => {
                    setBackgroundFile(null);
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="info-form">
                  <div className="form-header">
                    {tAddDesign('yourInformation')}
                  </div>
                  <div className="form-content">
                    <Form.Group className="form-group">
                      <Form.Label>{tAddDesign('enterLocation')}</Form.Label>
                      <InputWithDropdown
                        placeholder={tAddDesign('enterOrSelect')}
                        suggestions={locations}
                        value={locationName}
                        onChange={e => {
                          setLocationName(e.target.value);
                          setSelectedLocation(null);
                        }}
                        onSuggestionClick={(location) => {
                          setSelectedLocation(location);
                        }}
                        renderSuggestion={({
                          suggestion: location,
                          onClick,
                          isSelected,
                        }) => (
                          <div
                            key={location.id}
                            className={classNames(
                              'suggestion location',
                              isSelected && 'selected',
                            )}
                            onClick={() => {
                              onClick();
                            }}
                          >
                            <img
                              alt="Location"
                              src={locationIcon}
                            />
                            {' '}
                            {location.name}
                          </div>
                        )}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>{tAddDesign('imageName')}</Form.Label>
                      <Form.Control
                        placeholder={tAddDesign('egLivingRoom')}
                        value={imageName}
                        onChange={e => setImageName(e.target.value)}
                      />
                    </Form.Group>

                    <div className="image-types">
                      <div
                        className={classNames('image-type',
                          type === ImageType.NORMAL && 'selected',
                        )}
                        onClick={() => {
                          setType(ImageType.NORMAL);
                        }}
                      >
                        <img
                          className="icon"
                          src={normalImageIcon}
                          alt="2D"
                        />
                        <div className="title">
                          {tAddDesign('2DImage')}
                        </div>
                        <div className="coins">
                          {autoStaging ? configs.auto_image_price : configs.image_price}
                          {/* eslint-disable-next-line i18next/no-literal-string */}
                          {' '}
                          x
                          {' '}
                          <img
                            alt="coin"
                            className="coin"
                            src={coinIcon}
                          />
                        </div>
                      </div>
                      {!config.disablePanoImage && (
                        <div
                          className={classNames('image-type pano',
                            type === ImageType.PANO && 'selected')}
                          onClick={() => {
                            setType(ImageType.PANO);
                          }}
                        >
                          <img
                            className="icon"
                            src={panoImageIcon}
                            alt="360°"
                          />
                          <div className="title">
                            {tAddDesign('360°Image')}
                          </div>
                          <div className="coins">
                            {autoStaging ? configs.auto_pano_image_price : configs.pano_image_price}
                            {/* eslint-disable-next-line i18next/no-literal-string */}
                            {' '}
                            x
                            {' '}
                            <img
                              alt="coin"
                              className="coin"
                              src={coinIcon}
                            />
                          </div>
                        </div>
                      )}

                    </div>
                    {type === ImageType.NORMAL && (
                      <div className={styles.acceptAutoCleanSection}>
                        <Form.Check
                          className={styles.acceptAutoCleanCheckbox}
                          checked={acceptAutoClean}
                          onChange={() => {
                            setAcceptAutoClean(!acceptAutoClean);
                          }}
                        />
                        <div>
                          <div className={styles.acceptAutoCleanTitle}>
                            {tAddDesign('removeFurnitureWithAI')}
                          </div>
                          <div className={styles.acceptAutoCleanSubtitle}>
                            {tAddDesign('removeFurnitureWithAIInfo')}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={styles.autoStagingSection}>
                      <div className={styles.autoStagingCheck}>
                        <Form.Check
                          className={styles.acceptAutoCleanCheckbox}
                          checked={!!autoStaging}
                          onChange={() => {
                            if (autoStaging) {
                              setAutoStaging(null);
                            } else {
                              setAutoStaging({
                                rooms: [],
                                preferences: AUTO_STAGING_DEFAULT_PREFERENCES,
                              });
                            }
                          }}
                        />
                        <div>
                          <div className={styles.acceptAutoCleanTitle}>
                            {tAddDesign('autoVirtualStaging')}
                          </div>
                          <div className={styles.acceptAutoCleanSubtitle}>
                            {tAddDesign('autoVirtualStagingInfo')}
                          </div>
                        </div>
                      </div>
                      {autoStaging && (
                        <AutoStaging
                          autoStaging={autoStaging}
                          setAutoStaging={setAutoStaging}
                        />
                      )}
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <Button
              className="upload-button"
              disabled={disableUpload}
              onClick={() => {
                handleSubmit();
              }}
            >
              <Icon
                src={uploadIcon}
                fill={disableUpload ? 'rgba(15, 15, 31, 0.45)' : 'white'}
              />
              {submitting ? tCommon('uploading') : tCommon('upload')}
            </Button>
          </>
        ) : (
          <div className="upload">
            <div className="title">
              {tAddDesign('turnYourIdeas')}
            </div>
            <div className="subtitle">
              {tAddDesign('bringYourIdeas')}
            </div>
            {!config.disablePanoImage && (
              <div className="support">
                {tAddDesign('support')}
              </div>
            )}

            <DropFileContainer
              className="image-drop-area"
              onDropFile={async (file) => {
                if (file.size > 20000000) {
                  toastError(tError('fileTooLarge'));
                  return;
                }

                const [data] = await getBase64Strings([file[0]], {
                  maxSize: 10000,
                  quality: 1,
                });

                setBackgroundFile({
                  data,
                  file: file[0],
                });
              }}
              acceptMIMETypes="image/png,image/jpeg"
            >
              {({ dragging, invalid }) => (
                <>
                  {!dragging && (
                  <>
                    <img
                      src={uploadImg}
                      alt="img"
                    />
                    <div className="title">
                      {tAddDesign('dragYourImages')}
                    </div>
                    <div className="separator">
                      <div />
                      <span>{tCommon('or')}</span>
                      <div />
                    </div>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        fileInputRef.current?.click();
                      }}
                    >
                      <Icon
                        src={uploadIcon}
                      />
                      {' '}
                      <span>{tAddDesign('browseFiles')}</span>
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{
                        display: 'none',
                      }}
                      accept="image/png,image/jpeg"
                      onChange={async (e) => {
                        if (e.target.files[0].size > 20000000) {
                          toastError(tError('fileTooLarge'));
                          return;
                        }

                        const [data] = await getBase64Strings([e.target.files[0]], {
                          maxSize: 10000,
                          quality: 1,
                        });

                        setBackgroundFile({
                          data,
                          file: e.target.files[0],
                        });
                        e.target.value = '';
                      }}
                    />
                    <div className="format">
                      {tAddDesign('fileFormat')}
                    </div>
                  </>
                  )}
                  {dragging && !invalid && (
                  <>
                    <div className="title">
                      {tAddDesign('dropHere')}
                    </div>
                    <div className="sub-title">
                      <Icon
                        src={uploadIcon}
                        fill="#FF9C34"
                      />
                    </div>
                  </>
                  )}
                  {dragging && invalid && (
                  <>
                    <div className="title">
                      {tAddDesign('invalidFileType')}
                    </div>
                    <div className="sub-title">
                      <Icon
                        src={xMark}
                        fill="#f5222d"
                      />
                    </div>
                  </>
                  )}
                </>
              )}
            </DropFileContainer>
          </div>
        )}

      </div>
    </>
  );
}
