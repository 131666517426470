import React from 'react';
import background from 'assets/images/login-background.png';
import logo from 'assets/generated/images/logo.png';
import darkLogo from 'assets/generated/images/logo-dark.png';
import useTranslation from '../../hooks/useTranslation';

export default function AuthWrapper({
  children,
}) {
  const { tBetates } = useTranslation();
  return (
    <div className="login">
      <div className="background-container">
        <img
          className="background"
          src={background}
          alt="background"
        />
        <img
          className="logo"
          src={logo}
          alt="logo"
          height={56}
        />

        <div className="slogan">
          {tBetates('slogan')}
        </div>
      </div>

      <img
        className="small-logo"
        src={darkLogo}
        alt="logo"
        height={56}
      />

      <div className="form-container">
        {children}
      </div>
    </div>
  );
}
