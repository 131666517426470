import { Request } from 'auto-design-common';
import config from 'configuration';
import { auth } from 'utils/auth';

export const request = new Request(config.apiUrl, auth, config.tenantId);

export const get = request.get;

export const post = request.post;

export const put = request.put;

export const del = request.del;
