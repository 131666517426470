import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { getEventCoords } from './mouse';

const loader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('/draco/');
dracoLoader.setDecoderConfig({ type: 'js' });
dracoLoader.preload();
loader.setDRACOLoader(dracoLoader);

export const getGLTFLoader = () => loader;

export const createPanoViewer = () => {
  let data = {
    lat: 0,
    lon: 0,
    onPointerDownPointerX: 0,
    onPointerDownPointerY: 0,
    onPointerDownLon: 0,
    onPointerDownLat: 0,
    theta: 0,
    phi: 0,
    isUserInteracting: false,
  };

  const getProtectedFOV = fov => {
    if (fov < 0) {
      return 1;
    }

    if (fov > 150) {
      return 150;
    }

    return fov;
  };

  const handleMouseMove = (e) => {
    const { isUserInteracting, onPointerDownPointerX, onPointerDownPointerY, onPointerDownLon, onPointerDownLat } = data;

    if (isUserInteracting === true) {
      const { x, y } = getEventCoords(e);
      const lon = (onPointerDownPointerX - x) * 0.1 + onPointerDownLon;
      const lat = (y - onPointerDownPointerY) * 0.1 + onPointerDownLat;

      data = {
        ...data,
        lon,
        lat,
      };
    }
  };

  const handleMouseDown = e => {
    const { x, y } = getEventCoords(e);

    data = {
      ...data,
      onPointerDownPointerX: x,
      onPointerDownPointerY: y,
      onPointerDownLon: data.lon,
      onPointerDownLat: data.lat,
      isUserInteracting: true,
    };
  };

  const handleMouseUp = () => {
    data.isUserInteracting = false;
  };

  const handleAnimate = () => {
    const { lat, lon } = data;

    const lat1 = Math.max(-85, Math.min(85, lat));
    const phi = THREE.MathUtils.degToRad(90 - lat1);
    const theta = THREE.MathUtils.degToRad(lon);
    data.lat = lat1;
    data.phi = phi;
    data.theta = theta;
  };

  const calculateCameraPosition = () => {
    const { phi, theta } = data;
    const x = 500 * Math.sin(phi) * Math.cos(theta);
    const y = 500 * Math.cos(phi);
    const z = 500 * Math.sin(phi) * Math.sin(theta);

    return {
      x, y, z,
    };
  };

  const calculateFov = (event, inputFov) => {
    let fov = inputFov;

    const delta = event.deltaY * 0.05;

    fov += delta * 0.05;

    return getProtectedFOV(fov);
  };

  const zoomIn = (camera) => {
    camera.fov = getProtectedFOV(camera.fov - 10);
    camera.updateProjectionMatrix();
  };

  const zoomOut = (camera) => {
    camera.fov = getProtectedFOV(camera.fov + 10);
    camera.updateProjectionMatrix();
  };

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleAnimate,
    calculateCameraPosition,
    calculateFov,
    zoomIn,
    zoomOut,
  };
};
