import React from 'react';
import useTranslation from '../hooks/useTranslation';

export default function Broken() {
  const { tError } = useTranslation();
  return (
    <div>
      {tError('somethingWentWrong')}
    </div>
  );
}
