import React, { useState, useEffect, useRef } from 'react';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import useTranslation from '../../hooks/useTranslation';

export default function VRButton({
  renderer,
}) {
  const { tTourEditor } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [supported, setSupported] = useState(false);
  const { showModal } = useModalContext();
  const currentSessionRef = useRef(null);
  const [running, setRunning] = useState(false);

  const onSessionStarted = async (session) => {
    const onEnd = () => {
      session.removeEventListener('end', onEnd);
      currentSessionRef.current = null;
      setRunning(false);
    };

    session.addEventListener('end', onEnd);
    await renderer.xr.setSession(session);
    currentSessionRef.current = session;
    setRunning(true);
  };

  const onClick = () => {
    if (!supported) {
      showModal(ModalKey.VR_NOT_SUPPORTED);
    } else if (!currentSessionRef.current) {
      setLoading(true);
      const sessionInit = { optionalFeatures: ['local-floor', 'bounded-floor', 'hand-tracking', 'layers'] };
      navigator.xr.requestSession('immersive-vr', sessionInit).then(onSessionStarted)
        .then(() => {
          setLoading(false);
        });
    } else {
      currentSessionRef.current.end();
    }
  };

  useEffect(() => {
    if ('xr' in navigator) {
      navigator.xr.isSessionSupported('immersive-vr')
        .then((supported) => {
          setSupported(supported);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div className="vr-button" onClick={onClick}>
      {running ? tTourEditor('vrButton.exitVR') : tTourEditor('vrButton.enterVR')}
    </div>
  );
}
