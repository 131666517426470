///////////////////////////////////////////////////////////
//                           _                           //
//                        _ooOoo_                        //
//                       o8888888o                       //
//                       88" . "88                       //
//                       (| -_- |)                       //
//                       O\  =  /O                       //
//                    ____/`---'\____                    //
//                  .'  \\|     |//  `.                  //
//                 /  \\|||  :  |||//  \                 //
//                /  _||||| -:- |||||_  \                //
//                |   | \\\  -  /'| |   |                //
//                | \_|  `\`---'//  |_/ |                //
//                \  .-\__ `-. -'__/-.  /                //
//              ___`. .'  /--.--\  `. .'___              //
//           ."" '<  `.___\_<|>_/___.' _> \"".           //
//          | | :  `- \`. ;`. _/; .'/ /  .' ; |          //
//          \  \ `-.   \_\_`. _.'_/_/  -' _.' /          //
//===========`-.`___`-.__\ \___  /__.-'_.'_.-'===========//
//
//
//
//
//                      _.-/`)
//                     // / / )
//                  .=// / / / )
//                 //`/ / / / /
//                // /     ` /
//               ||         /
//                \\       /
//                 ))    .'
//                //    /
//                     /

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import QueryString from 'query-string';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ToastContextProvider, Toast } from 'auto-design-common';
import { QueryParamProvider } from 'use-query-params';
import './i18n/generated/config';
import App from 'components/App';
import Broken from 'components/Broken';
import config from 'configuration';
import { AppContextProvider } from 'components/AppContext';
import 'assets/styles/index.scss';
import 'shepherd.js/dist/css/shepherd.css';
import 'auto-design-editor/build/style.css';
import { ModalContainer, ModalContextProvider } from 'components/ModalContext';
import { ProgressContextProvider } from 'components/ProgressContext';
import { NotificationContextProvider, Notification } from 'components/Notification';
import { setReferToken } from 'utils/refer';
import { TawkContextProvider } from 'components/TawkContext';
import { setMode } from 'utils/mode';
import { setAuth } from 'utils/auth';

Sentry.init({
  dsn: config.sentryDNS,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (process.env.NODE_ENV === 'production') {
  document.addEventListener('contextmenu', event => event.preventDefault());
}

const { referToken, mode, access_token } = QueryString.parse(window.location.search);

if (referToken) {
  setReferToken(referToken);
}

if (mode) {
  setMode(mode);
}

if (access_token) {
  setAuth({
    accessToken: access_token,
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={(<Broken />)}>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppContextProvider>
          <ToastContextProvider id="app-toast">
            <NotificationContextProvider>
              <ModalContextProvider>
                <TawkContextProvider>
                  <ProgressContextProvider>
                    <App />
                    <ModalContainer />
                    <Notification />
                    <Toast />
                  </ProgressContextProvider>
                </TawkContextProvider>
              </ModalContextProvider>
            </NotificationContextProvider>
          </ToastContextProvider>
        </AppContextProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
