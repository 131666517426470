import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getRoom, getItems } from 'utils/api';
import { useToastContext } from 'auto-design-common';
import ItemList from './Shared/ItemList';
import CategorySelect from './Shared/CategorySelect';

export default function RoomItems() {
  const history = useHistory();
  const { roomId, childRoomId } = useParams();
  const { toastError } = useToastContext();
  const [childRoom, setChildRoom] = useState(null);
  const [childRooms, setChildRooms] = useState([]);

  useEffect(() => {
    getRoom(childRoomId)
      .then(setChildRoom)
      .catch(toastError);
  }, [childRoomId, toastError]);

  useEffect(() => {
    getRoom(roomId)
      .then(room => {
        setChildRooms(room.children);
      })
      .catch(toastError);
  }, [roomId, toastError]);

  if (!childRoom) {
    return null;
  }

  return (
    <ItemList
      fetchFunc={(params) => getItems({
        ...params,
        roomId: childRoomId,
      })}
      renderHeader={() => (
        <CategorySelect
          currentItem={childRoom}
          items={childRooms}
          onBack={() => history.replace(`/catalog/rooms/${roomId}`)}
          onSelectItem={(childRoom) => history.push(`/catalog/rooms/${roomId}/categories/${childRoom.id}/items`)}
        />
      )}
    />
  );
}
