import Pusher from 'pusher-js';
import config from 'configuration';
import { getToken } from 'utils/auth';

let pusher;
const channels = {};

Pusher.logToConsole = true;

export const connect = () => {
  const accessToken = getToken();
  pusher = new Pusher(config.pusherKey, {
    authEndpoint: `${config.apiUrl}/pusher/auth`,
    cluster: config.pusherCluster,
    auth: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-tenant-id': config.tenantId,
      },
    },
  });
};

export const subscribe = (channelName) => {
  channels[channelName] = pusher.subscribe(channelName);
};

export const unsubscribe = (channelName) => {
  const channel = channels[channelName];
  if (channels[channelName] !== undefined) {
    channel.unbind();
    pusher.unsubscribe(channel.name);
    channels[channelName] = undefined;
  }
};

export const bind = (channelName, eventName, callback) => {
  const channel = channels[channelName];
  channel
    ? channel.bind(eventName, (state) => {
      callback(state);
    })
    : console.log('No channel with name', channelName);
};

export const unbind = (channelName, eventName) => {
  const channel = channels[channelName];
  channel !== undefined && channel.unbind(eventName);
};

export const disconnect = () => {
  if (pusher) {
    Object.keys(channels).forEach((channelType) => {
      unsubscribe(channelType);
    });
    pusher.disconnect();
    pusher = null;
  }
};
