import React from 'react';
import smallLogo from 'assets/generated/images/logo-small.png';
import { useHistory } from 'react-router-dom';
import configs from 'configuration';
import useTranslation from '../../hooks/useTranslation';

export default function LandingFooter() {
  const { tBetates, tCommon } = useTranslation();
  const history = useHistory();

  return (
    <div className="landing-footer">
      <div className="info">
        <img
          alt="logo"
          src={smallLogo}
          height={96}
        />
        <div className="description">{tBetates('description')}</div>
        <div className="lets-talk">
          {tCommon('letsTalk')}
        </div>
        <a className="email" href="mailto:support@auto-design.io">
          {tBetates('email')}
        </a>
      </div>
      <div className="nav">
        <div className="copy-right">
          ©
          {' '}
          {new Date().getFullYear()}
          .
          {' '}
          {tCommon('allRightsReserved')}
        </div>
        <div className="nav-items">
          <div
            className="nav-item"
            onClick={() => {
              window.location.href = configs.termsOfServicesUrl;
            }}
          >
            {tCommon('termsOfServices')}
          </div>
          <div className="separator" />
          <div
            className="nav-item"
            onClick={() => {
              history.push('/instructions');
              window.scroll(0, 0);
            }}
          >
            {tCommon('instructionsForUse')}
          </div>
          <div className="separator" />
          <div
            className="nav-item"
            onClick={() => {
              history.push('/privacy-policy');
              window.scroll(0, 0);
            }}
          >
            {tCommon('privacyPolicy')}
          </div>
        </div>
      </div>
    </div>
  );
}
