import * as THREE from 'three';
import { createPanoViewer } from './3d';

export const setupPanoViewer = async ({
  imageUrl,
  width,
  height,
}) => {
  const scene = new THREE.Scene();
  const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);

  const canvasData = {
    renderer,
    scene,
    panoViewer: createPanoViewer(),
  };

  const geometry = new THREE.SphereGeometry(1000, 100, 100);
  geometry.scale(-1, 1, 1);
  const material = new THREE.MeshBasicMaterial({
    map: new THREE.TextureLoader().load(imageUrl),
  });

  const camera = new THREE.PerspectiveCamera(75, width / height, 1, 1100);
  camera.target = new THREE.Vector3(0, 0, 0);
  canvasData.camera = camera;

  const mesh = new THREE.Mesh(geometry, material);
  mesh.rotateY(-Math.PI / 2);
  mesh.position.copy(canvasData.camera.position);
  scene.add(mesh);

  return canvasData;
};

export const createAnimator = () => {
  let canceled = false;

  const animate = ({
    canvasData,
  }) => {
    if (canceled) {
      return;
    }

    requestAnimationFrame(() => animate({
      canvasData,
    }));

    const { panoViewer, camera, scene, renderer } = canvasData.current;

    panoViewer.handleAnimate();
    const { x, y, z } = panoViewer.calculateCameraPosition();

    camera.target.x = x;
    camera.target.y = y;
    camera.target.z = z;

    camera.lookAt(camera.target);

    renderer.render(scene, camera);
  };

  const cancel = () => {
    canceled = true;
  };

  return {
    animate,
    cancel,
  };
};
