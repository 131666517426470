import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { useToastContext, Icon } from 'auto-design-common';
import * as api from 'utils/api';
import trashIcon from 'assets/images/ic-line-trash.svg';
import useTranslation from '../../hooks/useTranslation';

export default function TourVersions({
  onClose,
  tourId,
}) {
  const { useTranslationOrigin } = useTranslation();
  const { t: tTourVersions } = useTranslationOrigin('translation', { keyPrefix: 'modal.tourVersions' });

  const [loading, setLoading] = useState(false);
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [versions, setVersions] = useState([]);

  const getVersions = useCallback(async () => {
    setLoading(true);
    const { success, error, result } = await request(api.getTourVersions(tourId));

    if (success) {
      setVersions(result);
    } else {
      toastError(error);
    }

    setLoading(false);
  }, [request, tourId, toastError]);

  useEffect(() => {
    getVersions();
  }, [getVersions]);

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      size="md"
      id="modal-tour-versions"
    >
      <Modal.Header closeButton>
        <Modal.Title>{tTourVersions('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {versions.map(version => (
          <div
            className="version"
            onClick={() => {
              window.open(`${window.origin}/published-tours/${version.slug}`);
            }}
          >
            <div className="name">
              {moment.utc(version.createdAt).local().format('MM-DD-yyyy HH:mm')}
            </div>
            <div className="actions">
              <div
                onClick={async (e) => {
                  e.stopPropagation();

                  const { success, error } = await request(api.deleteTourVersion(tourId, version.id));

                  if (!success) {
                    toastError(error);
                  } else {
                    getVersions();
                  }
                }}
              >
                <Icon
                  fill="#575762"
                  src={trashIcon}
                />
              </div>
            </div>
          </div>
        ))}
        {!loading && versions.length === 0 && (
          <div className="no-item">
            {tTourVersions('noItem')}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
