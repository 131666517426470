import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { getLocations } from 'utils/api';

export default function useSearchLocations({
  searchText,
  onError,
}) {
  const [locations, setLocations] = useState([]);

  const [debouncedSearchText] = useDebounce(searchText, 500, {
    leading: true,
    trailing: true,
  });

  const fetchLocations = useCallback(() => {
    getLocations(debouncedSearchText)
      .then(locations => {
        setLocations(locations);
      })
      .catch(e => {
        onError && onError(e);
      });
  }, [debouncedSearchText, onError]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  return {
    locations,
    setLocations,
    fetchLocations,
  };
}
