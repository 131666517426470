/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import darkLogo from 'assets/generated/images/logo-dark.png';
import uploadIcon from 'assets/images/ic-fill-upload.svg';
import emptyAvatar from 'assets/images/empty-avatar.jpeg';
import coinIcon from 'assets/images/Auto-Coin.svg';
import arrowIcon from 'assets/images/ic-line-chevron-right.svg';
import plusIcon from 'assets/images/ic-line-plus.svg';
import menuIcon from 'assets/images/ic-line-menu.svg';
import closeIcon from 'assets/images/ic-line-x-mark.svg';
import rightArrowIcon from 'assets/images/ic-line-arrow-right.svg';
import { Icon } from 'auto-design-common';
import { INSTRUCTION_2D_VIDEO, INSTRUCTION_360_VIDEO } from 'constants/common';
import autoPhotoEditingLogo from 'assets/generated/images/auto-photo-editing-logo.svg';
import { useAppContext } from 'components/AppContext';
import { useModalContext } from 'components/ModalContext';
import { useProgressContext } from 'components/ProgressContext';
import { ModalKey } from 'constants/modal';
import { ReactComponent as BubbleIcon } from 'assets/images/ic-line-bubble-single.svg';
import configs from 'configuration';
import Locations from './Locations';
import useSearchLocations from '../useSearchLocations';
import useTranslation from '../../../hooks/useTranslation';

const COLLAPSED = 1;
const MENU = 2;
const INFO = 3;
const DETAILS = 4;

export default function Sidebar() {
  const { tSidebar, tBetates, tCommon } = useTranslation();
  const { progresses } = useProgressContext();
  const location = useLocation();
  const { locations } = useSearchLocations({
    searchText: '',
  });
  const history = useHistory();
  const { user, logout } = useAppContext();
  const [menuState, setMenuState] = useState(COLLAPSED);
  const [details, setDetails] = useState(null);
  const { showModal } = useModalContext();

  const path = location.pathname;

  const isActiveItem = (item) => path.startsWith(item.path);

  const NAV_ITEMS = useMemo(() => [
    {
      key: 'locations',
      path: '/locations',
      title: tSidebar('navItems.locations'),
      hasDetails: locations.length > 0,
      onClick: () => {
        history.push('/locations/empty');
      },
    },
    !configs.disablePanoImage && {
      key: 'tours',
      path: '/tours',
      title: tSidebar('navItems.tours'),
      badge: (
        <div className="badge new">
          {tSidebar('new')}
        </div>
      ),
      onClick: () => {
        history.push('/tours');
      },
    },
    {
      key: 'progress',
      path: '/progress',
      title: tSidebar('navItems.progress'),
      badge: (
        <div className="badge count">
          {progresses.length}
        </div>
      ),
      onClick: () => {
        history.push('/progress');
      },
    },
    {
      key: 'catalog',
      path: '/catalog',
      title: tSidebar('navItems.catalog'),
      onClick: () => {
        history.push('/catalog');
      },
    },
    {
      key: '2d-instructions',
      title: tSidebar('navItems.2d-instructions'),
      onClick: () => {
        window.open(INSTRUCTION_2D_VIDEO);
      },
    },
    !configs.disablePanoImage && {
      key: '360-instructions',
      title: tSidebar('navItems.360-instructions'),
      onClick: () => {
        window.open(INSTRUCTION_360_VIDEO);
      },
    },
  ].filter(i => !!i), [history, locations.length, progresses.length, tSidebar]);

  const toggleMenu = () => {
    if (menuState !== MENU) {
      setMenuState(MENU);
    } else {
      setMenuState(COLLAPSED);
    }
  };

  const toggleInfo = () => {
    if (menuState !== INFO) {
      setMenuState(INFO);
    } else {
      setMenuState(COLLAPSED);
    }
  };

  useEffect(() => {
    if (menuState === INFO) {
      setDetails(null);
    }
  }, [menuState]);

  useEffect(() => {
    if (menuState === COLLAPSED) {
      setDetails(null);
    }
  }, [menuState]);

  useEffect(() => {
    if (location.pathname === '/locations/empty' && locations.length > 0) {
      setDetails('locations');
      setMenuState(DETAILS);
    }
  }, [location.pathname, locations.length]);

  return (
    <div className="sidebar">
      <div className="header">
        <img
          src={menuState === COLLAPSED ? menuIcon : closeIcon}
          alt="Menu"
          className="menu"
          onClick={() => {
            toggleMenu();
          }}
        />
        <img
          src={darkLogo}
          alt="Logo"
          className="logo"
          height={56}
        />
        <img
          className="avatar"
          alt="Avatar"
          src={user.avatar || emptyAvatar}
          onClick={toggleInfo}
        />
      </div>

      <div className="separator" />
      <div className={classNames(
        'content',
        menuState === COLLAPSED && 'collapsed',
      )}
      >
        <div className="nav-items">
          {NAV_ITEMS.map(item => (
            <div
              className={classNames(
                'nav-item',
                isActiveItem(item) && 'active',
              )}
              key={item.key}
            >
              {isActiveItem(item) && (
                <div className="active-dot" />
              )}
              <span
                className="title"
                onClick={item.onClick}
              >
                {item.title}
                {item.badge ? item.badge : null}
              </span>
              {item.hasDetails && (
                <img
                  alt="open-details"
                  className="open-details"
                  src={rightArrowIcon}
                  onClick={() => {
                    setDetails(item.key);
                    setMenuState(DETAILS);
                  }}
                />
              )}
            </div>
          ))}
        </div>

        <Button
          className="add-new-design"
          variant="secondary"
          onClick={() => {
            history.push('/add-design');
          }}
        >
          <img
            src={uploadIcon}
            alt="upload"
          />
          {tSidebar('button.addANewDesign')}
        </Button>

        {configs.tenantId === '1' && (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 32,
          }}
          >
            <div
              style={{
                fontWeight: 'bold',
                padding: 8,
                textAlign: 'center',
              }}
            >
              {tSidebar('enhancement')}
            </div>
            {' '}
            <img
              style={{
                margin: 32,
                objectFit: 'contain',
                width: 'calc(100% - 32px)',
              }}
              src={autoPhotoEditingLogo}
              alt="Auto Photo Editing"
            />
            <Button
              style={{
                width: 'calc(100% - 32px)',
                marginLeft: 16,
                marginRight: 16,
              }}
              variant="primary"
              onClick={() => {
                window.open('https://www.auto-photoediting.ai');
              }}
            >
              {tSidebar('button.tryNow!')}
            </Button>
          </div>
        )}

      </div>
      <div className="user-info">
        <img
          className="avatar"
          alt="Avatar"
          src={user.avatar || emptyAvatar}
        />
        <div className="name-and-balance">
          <div className="name">
            {user.name || 'Unknown'}
          </div>
          <div className="balance">
            <img
              alt="balance"
              src={coinIcon}
            />
            {user.balance}
            {' '}
            {tBetates('coin', {
              count: user.balance,
            })}
          </div>
        </div>
        <img
          className="open-info-button"
          alt="Open info"
          src={arrowIcon}
          onClick={toggleInfo}
        />
      </div>
      <div
        style={{
          padding: 16,
          paddingTop: 0,
        }}
        className="add-coin-button"
      >
        <Button
          variant="primary"
          className="small"
          onClick={() => {
            showModal(ModalKey.TOP_UP);
          }}
          style={{
            width: '100%',
          }}
        >
          <Icon
            fill="white"
            src={plusIcon}
          />
          {tSidebar('button.addMoreCoins')}
        </Button>
        <Button
          variant="secondary"
          className="small"
          onClick={() => {
            showModal(ModalKey.INVITE_FRIENDS);
          }}
          style={{
            width: '100%',
            marginTop: 16,
          }}
        >
          <BubbleIcon />
          {tSidebar('button.inviteFriends')}
        </Button>
      </div>
      {details === 'locations' && (
        <Locations
          onClose={() => {
            setDetails(null);
            setMenuState(COLLAPSED);
          }}
        />
      )}
      {menuState === INFO && (
        <div className="info-menu">
          <div
            className="info-menu-item"
            onClick={() => {
              history.push('/profile');
            }}
          >
            {tSidebar('info.viewProfile')}
          </div>
          <div
            className="info-menu-item"
            onClick={() => {
              history.push('/api-keys');
            }}
          >
            {tSidebar('info.apiKeys')}
          </div>
          <div
            className="info-menu-item"
            onClick={() => {
              history.push('/webhooks');
            }}
          >
            {tSidebar('info.webhooks')}
          </div>
          <div
            className="info-menu-item"
            onClick={() => {
              window.location.href = configs.privacyPolicyUrl;
            }}
          >
            {tCommon('privacyPolicy')}
          </div>
          <div
            className="info-menu-item negative"
            onClick={logout}
          >
            {tCommon('logOut')}
          </div>
        </div>
      )}

      {menuState !== COLLAPSED && (
        <div className="backdrop" />
      )}
    </div>
  );
}
