import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react';

const TawkContext = React.createContext();

export function TawkContextProvider({
  children,
}) {
  const [loaded, setLoaded] = useState(window.Tawk_API?.loaded);

  const hideWidget = useCallback(() => {
    if (loaded) {
      window.Tawk_API?.hideWidget?.();
    }
  }, [loaded]);

  const showWidget = useCallback(() => {
    if (loaded) {
      window.Tawk_API?.showWidget?.();
    }
  }, [loaded]);

  useEffect(() => {
    window.onTawkLoaded = () => {
      setLoaded(true);
    };
  }, []);

  const value = useMemo(() => ({
    loaded,
    hideWidget,
    showWidget,
  }), [
    loaded,
    hideWidget,
    showWidget,
  ]);

  return (
    <TawkContext.Provider value={value}>
      {children}
    </TawkContext.Provider>
  );
}

export function useTawkContext() {
  return useContext(TawkContext);
}

export function useHideTawkWidget() {
  const { showWidget, hideWidget } = useTawkContext();

  useEffect(() => {
    hideWidget();

    return () => {
      showWidget();
    };
  }, [showWidget, hideWidget]);
}
