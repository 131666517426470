import React from 'react';
import { useHistory } from 'react-router-dom';
import { getCategories } from 'utils/api';
import CategoryList from './Shared/CategoryList';
import TypeSwitch from './Shared/TypeSwitch';
import useTranslation from '../../hooks/useTranslation';

export default function RoomCategories() {
  const { tCatalog } = useTranslation();
  const history = useHistory();

  return (
    <CategoryList
      fetchFunc={getCategories}
      renderHeader={() => (
        <TypeSwitch page="categories" />
      )}
      renderChildrenCount={(category) => (
        <>
          {category.itemsCount}
          {' '}
          {tCatalog('items')}
        </>
      )}
      onClick={(category) => {
        history.push(`/catalog/categories/${category.id}/items`);
      }}
    />
  );
}
