import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ModalKey } from 'constants/modal';
import { Icon } from 'auto-design-common';
import backArrow from 'assets/images/ic-line-arrow-left.svg';
import searchIcon from 'assets/images/ic-search.svg';
import locationIcon from 'assets/images/ic-line-location.svg';
import penIcon from 'assets/images/ic-line-pen.svg';
import trashIcon from 'assets/images/ic-line-trash.svg';
import { useModalContext } from 'components/ModalContext';
import emitter, { Events } from 'utils/event';
import useSearchLocations from '../useSearchLocations';
import useTranslation from '../../../hooks/useTranslation';

function LocationItem({
  location,
  onSelect,
  onDelete,
  onEdit,
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="list-item"
      key={location.id}
      onClick={onSelect}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        alt="Location"
        src={locationIcon}
      />
      <div className="list-item-title">
        {location.name}
      </div>

      {hovered && (
        <div className="actions">

          <div
            className="action"
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            <Icon src={penIcon} fill="#212529" />
          </div>
          <div
            className="action"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Icon src={trashIcon} fill="#F5222D" />
          </div>
        </div>
      )}
    </div>
  );
}

export default function Location({
  onClose,
}) {
  const { tSidebar } = useTranslation();
  const history = useHistory();
  const [text, setText] = useState('');
  const { showModal } = useModalContext();
  const { locations, fetchLocations } = useSearchLocations({
    searchText: text,
  });

  return (
    <div className="menu-details">
      <div className="title">
        <img
          alt="Back"
          src={backArrow}
          className="back"
          onClick={onClose}
        />
        {tSidebar('navItems.locations')}
      </div>

      <Form.Group className="form-group search">
        <InputGroup className="search-input-group">
          <InputGroup.Text>
            <img
              alt="showPassword"
              className="u-cursorPointer"
              src={searchIcon}
            />
          </InputGroup.Text>
          <Form.Control
            placeholder={tSidebar('enterLocation')}
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </InputGroup>
      </Form.Group>

      <div className="list">
        {locations.map(location => (
          <LocationItem
            location={location}
            key={location.id}
            onSelect={() => {
              history.push(`/locations/${location.id}`);
              onClose();
            }}
            onEdit={() => {
              showModal(ModalKey.EDIT_LOCATION, {
                location,
                onDone: () => {
                  emitter.emit(Events.LOCATION_UPDATED);
                  fetchLocations();
                },
              });
            }}
            onDelete={() => {
              showModal(ModalKey.DELETE_LOCATION, {
                id: location.id,
                onDone: () => {
                  emitter.emit(Events.LOCATION_DELETED, location.id);
                  fetchLocations();
                },
              });
            }}
          />
        ))}
      </div>
    </div>
  );
}
