import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { Overlay, Tooltip } from 'react-bootstrap';
import arrowIcon from 'assets/images/ic-line-arrow-left.svg';
import chevron from 'assets/images/ic-fill-chevron-down.svg';
import checkIcon from 'assets/images/ic-line-check.svg';
import { Icon } from 'auto-design-common';
import { getTranslation } from 'hooks/useTranslation';

export default function CategorySelect({
  currentItem,
  items,
  onBack,
  onSelectItem,
}) {
  const [showItems, setShowItems] = useState(false);
  const selectRef = useRef();

  console.log({
    currentItem,
  });

  return (
    <div className="category-select">
      <img
        src={arrowIcon}
        alt="back"
        onClick={onBack}
        className="back"
      />
      <div
        ref={selectRef}
        onClick={() => setShowItems(!showItems)}
        className="current-item"
      >
        {getTranslation(currentItem.translation.name, currentItem.name)}
        <img src={chevron} alt="select" />
      </div>
      <Overlay
        target={selectRef.current}
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        show={showItems}
        popperConfig={{
          modifiers: [
            {
              name: 'arrow',
              options: {
                padding: {
                  right: 220,
                },
              },
            },
            {
              name: 'offset',
              options: {
                offset: [40, 12],
              },
            },
          ],
        }}
      >
        {(props) => (
          <Tooltip
            {...props}
            id="category-select-tooltip"

          >
            <div className="category-select-content">
              {items.map((item) => (
                <div
                  key={item.id}
                  className={classNames(
                    'category-select-item',
                    currentItem.id === item.id && 'active',
                  )}
                  onClick={() => {
                    onSelectItem(item);
                    setShowItems(false);
                  }}
                >
                  <span>
                    {getTranslation(item.translation.name, item.name)}
                  </span>
                  {currentItem.id === item.id && (
                  <Icon
                    src={checkIcon}
                    stroke="white"
                  />
                  )}
                </div>
              ))}
            </div>
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
}
