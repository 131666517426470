import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import useTranslation from '../../hooks/useTranslation';

export default function ConfirmSwitchingVersion({
  onClose,
  onAccept,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tConfirmSwitchingVersionModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.confirmSwitchingVersion' });

  return (
    <Modal
      show
      backdrop="static"
      id="modal-delete-location"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tConfirmSwitchingVersionModal('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tConfirmSwitchingVersionModal('content')}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onClose}
        >
          {tCommon('cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onAccept();
            onClose();
          }}
        >
          {tConfirmSwitchingVersionModal('switchVersion')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
