import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';
import * as api from 'utils/api';
import useTranslation from '../../hooks/useTranslation';

export default function ResetPassword({
  onClose,
  defaultEmail,
}) {
  const { tLogin, tCommon } = useTranslation();
  const { request } = useAppContext();
  const { toastSuccess, toastError } = useToastContext();
  const [email, setEmail] = useState(defaultEmail || '');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    setSubmitting(true);

    const { success, error } = await request(api.requestPasswordReset(email));

    if (success) {
      toastSuccess(tLogin('sendEmailSuccess'));
      setSubmitting(false);
      setSubmitted(true);
    } else {
      setSubmitting(false);
      toastError(error);
    }
  };

  if (submitted) {
    return (
      <Modal
        show
        backdrop="static"
        id="modal-forgot-password"
        size="md"
        onHide={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {tLogin('resetYourPassword')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tLogin('sentARecoveryLink')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={onClose}
          >
            {tCommon('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal
      show
      backdrop="static"
      id="modal-forgot-password"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tLogin('resetYourPassword')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group
          className="form-group"
        >
          <Form.Label>
            {tLogin('yourEmail')}
          </Form.Label>
          <Form.Control
            value={email}
            type="email"
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onClose}
          disabled={submitting}
        >
          {tCommon('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            submit();
          }}
          disabled={submitting}
        >
          {tLogin('emailMeARecoveryLink')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
