import React, { forwardRef, useEffect } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

export default forwardRef(({
  src,
  onLoad,
  ...props
}, ref) => {
  const [image] = useImage(src);

  useEffect(() => {
    if (image) {
      onLoad(image);
    }
  }, [image, onLoad]);

  return (
    <Image
      ref={ref}
      image={image}
      {...props}
    />
  );
});
