import baseConfigs from './base';

export default {
  ...baseConfigs,
  paypalClientId: 'AaGRPAKmMBgHMGo2LDNwenUgLX7qjc0qNoXibl80zflmNhOaH1EEmJU7A5BuWvXgPOaymM0s3Nk4W6ad',
  googleClientId: '283356322414-ipmaqhvfp69g47m7dvhi8346g4in76i0.apps.googleusercontent.com',
  pusherKey: 'ccd57d3fe67f0f316a8c',
  pusherCluster: 'ap1',
  apiUrl: 'https://diy.betates.com/api',
};
