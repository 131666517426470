import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import slashIcon from 'assets/images/slash.svg';
import _ from 'lodash';
import useTranslation from '../../../hooks/useTranslation';

export default function TypeSwitch({
  page,
}) {
  const { tCatalog } = useTranslation();
  const history = useHistory();

  return (
    <div className="type-switch">
      <div
        className={classNames(
          'type-item',
          page === 'rooms' && 'active',
        )}
        onClick={() => history.push('/catalog/rooms')}
      >
        {_.capitalize(tCatalog('rooms'))}
      </div>
      <img src={slashIcon} alt="slash" />
      <div
        className={classNames(
          'type-item',
          page === 'categories' && 'active',
        )}
        onClick={() => history.push('/catalog/categories')}
      >
        {_.capitalize(tCatalog('categories'))}
      </div>
    </div>
  );
}
