import React, { useCallback, useRef, useEffect } from 'react';
import { Transformer } from 'react-konva';
import UrlImage from './UrlImage';

export default function WatermarkItem({
  image,
  onSelect,
  isSelected,
  onChange,
  stageSize,
}) {
  const shapeRef = useRef();
  const trRef = React.useRef();

  const select = () => {
    onSelect(image.id);
  };

  const onLoad = useCallback((imageEl) => {
    if (!image.initialized) {
      const expectedHeight = stageSize.height * 0.1;
      const scale = expectedHeight / imageEl.height;
      onChange({
        ...image,
        scale,
        initialized: true,
        x: 20,
        y: 20,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, stageSize]);

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <UrlImage
        onLoad={onLoad}
        ref={shapeRef}
        draggable
        key={image.id}
        src={image.url}
        scaleX={image.scale}
        scaleY={image.scale}
        x={image.x}
        y={image.y}
        rotation={image.rotation}
        onTap={select}
        onClick={select}
        onDragEnd={e => {
          onChange({
            ...image,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();

          onChange({
            ...image,
            x: node.x(),
            y: node.y(),
            // set minimal value
            scale: scaleX,
            rotation: node.rotation(),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
}
