import React, { useCallback, useState } from 'react';
import Sidebar from 'components/Common/Sidebar';
import Footer from 'components/Common/Footer';
import { uploadImage, createLocation } from 'utils/api';
import { useAppContext } from 'components/AppContext';
import { useModalContext } from 'components/ModalContext';
import { useToastContext } from 'auto-design-common';
import { ModalKey } from 'constants/modal';
import useSearchLocations from 'components/Common/useSearchLocations';
import Information from './Information';
import ImageCleaner from './ImageCleaner';
import { Screen } from './constants';

export default function AddDesign() {
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [screen, setScreen] = useState(Screen.INFORMATION);
  const [submitting, setSubmitting] = useState(false);
  const { getInfo, request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationName, setLocationName] = useState('');
  const { locations, setLocations } = useSearchLocations({
    searchText: locationName,
    onError: toastError,
  });
  const [imageName, setImageName] = useState('');
  const [autoStaging, setAutoStaging] = useState(null);

  const onSubmit = useCallback(async ({
    background,
    type,
  }) => {
    try {
      let locationId;

      if (!selectedLocation) {
        const matchedLocation = locations.find(l => l.name === locationName,
        );

        if (matchedLocation) {
          locationId = matchedLocation.id;
        } else {
          const { result: location } = await request(createLocation(locationName));
          setLocations([...locations, location]);
          locationId = location.id;
        }
      } else {
        locationId = selectedLocation.id;
      }

      setSubmitting(true);
      await uploadImage({
        locationId,
        name: imageName,
        background,
        type,
        autoStaging,
      });

      getInfo();
      setBackgroundFile(null);
      showModal(ModalKey.IMAGE_UPLOADED);
    } catch (e) {
      toastError(e);
    }

    setSubmitting(false);
  }, [getInfo, imageName, locationName, locations, request, selectedLocation, setLocations, showModal, toastError, autoStaging]);

  const renderContent = () => {
    if (screen === Screen.INFORMATION) {
      return (
        <Information
          backgroundFile={backgroundFile}
          setBackgroundFile={setBackgroundFile}
          setScreen={setScreen}
          onSubmit={onSubmit}
          locationName={locationName}
          setLocationName={setLocationName}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          locations={locations}
          submitting={submitting}
          imageName={imageName}
          setImageName={setImageName}
          autoStaging={autoStaging}
          setAutoStaging={setAutoStaging}
        />
      );
    }

    if (backgroundFile) {
      return (
        <ImageCleaner
          backgroundFile={backgroundFile}
          setScreen={setScreen}
          onSubmit={onSubmit}
          submitting={submitting}
        />
      );
    }

    return null;
  };

  return (
    <div className="main add-design">
      <Sidebar />
      <div className="right-part">
        <div
          className="content"
          style={{
            overflow: 'hidden',
          }}
        >
          {renderContent()}
        </div>
        <Footer />
      </div>
    </div>
  );
}
