export const TERMS_OF_SERVICE_MARKDOWN = `
Bestates Co., Ltd. respect user privacy. We are committed to protecting the privacy of our Visitors and users of our owned websites, available at: https://Betates.com/ (the "Service").

This Privacy Policy discloses the privacy practices of Betates.com (referred to in this Privacy Policy as "Betates.com", "we", "our", or "us") in this Internet Privacy Policy. By using this site, you agree to the Internet Privacy Policy of this web site ("the web site"), which is set out on this web site page. The Internet Privacy Policy relates to the collection and use of personal information you may supply to us through your conduct on the web site.

We reserve the right, at our discretion, to modify or remove portions of this Internet Privacy Policy at any time. This Internet Privacy Policy is in addition to any other terms and conditions applicable to the web site. We do not make any representations about third party web sites that may be linked to the web site.

We recognise the importance of protecting the privacy of information collected about visitors to our web site, in particular information that is capable of identifying an individual ("personal information"). This Internet Privacy Policy governs the manner in which your personal information, obtained through the web site, will be dealt with.

**1. SCOPE OF PRIVACY POLICY**

This Privacy Policy applies to the Betates.com website and any features and online services provided by us. It does not apply to our services that our customers may use on their own websites, to other companies or organizations to which we link, or to companies that we do not own or control, even if such companies are contractors or business partners of BoxBrownie.com.

**2. INFORMATION Betates.com COLLECTS**

**2.1 Information You Provide**

We collect information that you voluntarily provide to us while using the Services, such as when you register an account, make a purchase of one of our services, or communicate with our customer service team.

The information we collect includes information relating to identified or identifiable natural persons. Some examples of information we collect include the following: contact data, including your first and last name, telephone number, email address; and billing information; credentials, including your password; and content data, including the content of the messages you send to us. We also use third party payment processes to collect and process payment data, including your payment instrument and security code associated with your payment instrument, on our behalf.

You may choose to voluntarily submit other information to us that we do not request, and in such instances you are solely responsible for such information.

**2.2. Information Collected Automatically**

We automatically collect information about your device and how your device interacts with our Service. The types of data we collect and the methods by which we may collect such data include the following:

**A/ Cookies, Web Beacons, And Embedded Scripts**

The Services use "cookies" and similar technologies, including HTML5 local storage. A cookie is a file stored on your device that may be used to identify an individual as a unique user by storing certain personal preferences and user data. Betates.com uses cookies and other technologies to identify your device, identify logged-in users of the Betates.com Member Portal, track affiliate referrals, complete online purchases, and similar Services monitoring activities.

Betates.com may also use web beacons, small graphic images or other web programming code (also known as "1x1 GIFs" or "clear GIFs"), which may be included in our web pages and e-mail messages. Web beacons may be invisible to you, but any electronic image or other web programming code inserted into a web page or e-mail can act as a web beacon. Web beacons or similar technologies may be used for a number of purposes, including, without limitation, to count visitors to the Services, to monitor how users navigate the Services, to count how many e-mails that were sent were actually opened or to count how many particular links were actually viewed.

Betates.com may also use embedded scripts in connection with the provision of its Services. "Embedded scripts" are programming code designed to collect information about your interactions with a website, such as the links you click on, and may assist our customers in providing us with information used to provide the Services. The code is temporarily downloaded onto your device from our web server, or a third party service provider, is active only while you are connected to the website containing the embedded script, and is deactivated or deleted thereafter.

**B/ Log File Information**

Your web browser automatically sends information to every website you visit, including ours. For example, our server logs may receive and record information such as the pages you access on the Services, referring URLs, your browser type, your operating system, the date and time of your visit, and the duration of your visit to each page.

**C/ User Agent Strings**

Log file information may also include a user agent string, a series of characters automatically sent with your Internet requests that provide information necessary for smooth Internet communications, such as the operating system and browser you used. A user agent string might be used to identify the device originating a message.

**D/ Location Data**

Betates.com may also request access to or otherwise receive information about your device location when you access the Services. Your location data may be based on your IP address and other location-aware technologies. We use location data in connection with providing the Services and to help improve the Services.

**E/ Unique Identification Number**

Betates.com may assign your computer or mobile device a unique identification number ("Unique ID") based on log file information when you access the Services. Betates.com may set a cookie on your device containing, amongst other things, the device's Unique ID. Betates.com does not share the Unique ID or any associated data with any third parties.

**2.3. Information Betates.com.Com Receives From Third Parties**

Betates.com receives information about you from third parties. We may combine the information we receive from third parties with information we collect through the Services. If we do so, Betates.com will treat the combined information in accordance with the practices described in this Privacy Policy, plus any additional restrictions imposed by the source of the data.

**3. HOW Betates.com USES INFORMATION**

We use information for our legitimate interests, including for the following purposes:

**3.1. Providing Our Services**

As stated above, Betates.com uses information such as your first and last name, telephone number, email address, or other contact information we obtain from you, our customers, or our business partners, for the purposes of providing, enhancing, or improving our services and products.

**3.2. Communications With You**

Betates.com maintains one or more contact lists (with email addresses and other information) to allow us to communicate with individuals who do business with us or who have expressed an interest in the Services. We may contact you to confirm your purchases or respond to requests that you make, notify you of changes to your account or the Services, for marketing purposes, or to otherwise inform you of information related to our business or your account with us.

**3.3. Website Administration And Customization**

Betates.com uses the information we collect about you for a variety of website administration and customization purposes. For example, we use your information to process your registration request, provide you with services and communications that you have requested, send you email updates and other communications, customize features that appear on and off the Services, measure Services traffic, measure traffic patterns, and improve the Services.

**3.4. Consent**

We also use information with your consent, including for the following purposes: to allow you to participate in our surveys; to serve advertising tailored to your interests on our Services and third party services; and to fulfill any other purpose disclosed to you and with your consent.

Some of our lawful bases for processing your information stem from our customers on whose behalf we provide services.

Betates.com uses information that does not identify you for any purpose except as prohibited by applicable law. For information on your rights and choices regarding how we use your information, please see Section 6 below.

**4. HOW Betates.com SHARES INFORMATION**

**4.1. Sharing With Our Service Providers**

We provide your information to our third party service providers, contractors, and business partners for the purpose of delivering services to you as well as for purposes related to Services administration and operation, including conducting analytics. When sharing information for the purpose of providing you with the services you request, we will share your information only as necessary for the third party working on Betates.com's behalf to complete its work for us. For example, if you use a credit or debit card to complete a transaction on the Services, we will share your credit or debit card number with a payment processing company in order to complete your transaction.

**4.2. Security And Compliance With The Law**

We reserve the right to disclose your information to appropriate third parties if we are required to do so by law or we believe that such action is necessary in order (a) to comply with a legal process such as a search warrant, subpoena, or court order; (b) to protect the company's rights and property; (c) to investigate reports of users sending material using a false email address or users sending harassing, threatening, or abusive messages; (d) to protect against misuse or unauthorized use of the Website or Services; or (e) to respond to emergencies, such as when we believe someone's physical safety is at risk. Betates.com may be required to disclose information in a life-threatening emergency or in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.

**4.3. Transfer Of Business**

Over time, Betates.com may buy or sell various assets. In the event that we sell some or all of our assets, or our company is acquired by another company, or during the negotiation of such sale or acquisition, our databases and any information we collect may be among the transferred or negotiated assets.

**4.4 Consent**

We may share your information for any other purpose disclosed to you and with your consent.

Without limiting the foregoing, in our sole discretion, Betates.com shares information that does not identify you with third parties, for any purpose except as prohibited by applicable law. For information on your rights and choices regarding how we share your information, please see Section 6 below.

**5. THIRD PARTY ANALYTICS AND ONLINE TRACKING**

Betates.com works with certain third parties (including analytics companies) to provide us with information regarding traffic on and use of the Services. Some of these parties collect information when you visit the Services or other online websites and services. These third parties set and access their own tracking technologies (including cookies, embedded scripts, and web beacons) and may otherwise collect or have access to your IP address or other unique identifier, log information, and related information about you. These tracking technologies, including the Google Analytics, may be used to assist in providing analytics, marketing, and for other purposes. Betates.com does not control the information collection, use, or sharing practices of such third party providers, and we encourage you to consult their online policies. Some third parties may collect information about your online activities over time and across different websites when you use the Services.

**6. YOUR RIGHTS AND CHOICES**

**6.1. Opt-Out Of Email Communications**

If you are a registered member of the Services, you can make changes to your account information by logging into the Website and modifying your preferences. If you do not wish to receive email notifications from us, you may opt-out by contacting us at  **EMAIL**  with your request. In addition, certain email communications we send to you, such as newsletters and promotional announcements, contain a clearly worded "Unsubscribe" link allowing you to withdraw your permission for future mailings. Please note that we reserve the right to send you certain communications relating to your account or use of the Services and these transactional account messages may be unaffected even if you opt-out from marketing communications.

**6.2. Blocking Or Deleting Cookies**

You can manually delete cookies, which are normally located in your temporary Internet folder or cookie folder. You can also reset the preferences in your web browser to notify you when you have received a cookie or, alternatively, to refuse to accept cookies. Deleting or blocking cookies will limit your ability to make online purchases on the Services and to use and access portions of the Services that require logging in with a username and password, and may affect other functionality.

**7. SECURITY**

Betates.com implements reasonable administrative, physical, and technical security measures to help protect your data from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. However, no physical or electronic security system is impenetrable. Betates.com cannot guarantee the security of the Service's servers or databases, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet.

**8. POLICY REGARDING CHILDREN**

The Services are not intended for use by children under the age of sixteen years of age. Betates.com does not knowingly collect information from children under the age of sixteen. If you are a parent or guardian, and believe we have collected information about your child in violation of this policy, please contact us at the address set forth in Section 10 below.

**9. CHANGES TO THIS PRIVACY POLICY**

We will occasionally update this Privacy Policy in response to changing business circumstances and legal developments. If there are material changes to this Privacy Policy or in how we use your information, we will prominently post such changes prior to implementing the change. We encourage you to periodically review this Privacy Policy to be informed of how we are collecting and using your information.

**10. FOR MORE INFORMATION**

If you have any questions or complaints about this Privacy Policy, or you wish to exercise your opt-out rights hereunder or access or correct data applicable to you, please contact us
`;

export const PRIVACY_POLICY_MARKDOWN = `
Bestates Co., Ltd. respect user privacy. We are committed to protecting the privacy of our Visitors and users of our owned websites, available at: https://Betates.com/ (the "Service").

This Privacy Policy discloses the privacy practices of Betates.com (referred to in this Privacy Policy as "Betates.com", "we", "our", or "us") in this Internet Privacy Policy. By using this site, you agree to the Internet Privacy Policy of this web site ("the web site"), which is set out on this web site page. The Internet Privacy Policy relates to the collection and use of personal information you may supply to us through your conduct on the web site.

We reserve the right, at our discretion, to modify or remove portions of this Internet Privacy Policy at any time. This Internet Privacy Policy is in addition to any other terms and conditions applicable to the web site. We do not make any representations about third party web sites that may be linked to the web site.

We recognise the importance of protecting the privacy of information collected about visitors to our web site, in particular information that is capable of identifying an individual ("personal information"). This Internet Privacy Policy governs the manner in which your personal information, obtained through the web site, will be dealt with.

**1. SCOPE OF PRIVACY POLICY**

This Privacy Policy applies to the Betates.com website and any features and online services provided by us. It does not apply to our services that our customers may use on their own websites, to other companies or organizations to which we link, or to companies that we do not own or control, even if such companies are contractors or business partners of BoxBrownie.com.

**2. INFORMATION Betates.com COLLECTS**

**2.1 Information You Provide**

We collect information that you voluntarily provide to us while using the Services, such as when you register an account, make a purchase of one of our services, or communicate with our customer service team.

The information we collect includes information relating to identified or identifiable natural persons. Some examples of information we collect include the following: contact data, including your first and last name, telephone number, email address; and billing information; credentials, including your password; and content data, including the content of the messages you send to us. We also use third party payment processes to collect and process payment data, including your payment instrument and security code associated with your payment instrument, on our behalf.

You may choose to voluntarily submit other information to us that we do not request, and in such instances you are solely responsible for such information.

**2.2. Information Collected Automatically**

We automatically collect information about your device and how your device interacts with our Service. The types of data we collect and the methods by which we may collect such data include the following:

**A/ Cookies, Web Beacons, And Embedded Scripts**

The Services use "cookies" and similar technologies, including HTML5 local storage. A cookie is a file stored on your device that may be used to identify an individual as a unique user by storing certain personal preferences and user data. Betates.com uses cookies and other technologies to identify your device, identify logged-in users of the Betates.com Member Portal, track affiliate referrals, complete online purchases, and similar Services monitoring activities.

Betates.com may also use web beacons, small graphic images or other web programming code (also known as "1x1 GIFs" or "clear GIFs"), which may be included in our web pages and e-mail messages. Web beacons may be invisible to you, but any electronic image or other web programming code inserted into a web page or e-mail can act as a web beacon. Web beacons or similar technologies may be used for a number of purposes, including, without limitation, to count visitors to the Services, to monitor how users navigate the Services, to count how many e-mails that were sent were actually opened or to count how many particular links were actually viewed.

Betates.com may also use embedded scripts in connection with the provision of its Services. "Embedded scripts" are programming code designed to collect information about your interactions with a website, such as the links you click on, and may assist our customers in providing us with information used to provide the Services. The code is temporarily downloaded onto your device from our web server, or a third party service provider, is active only while you are connected to the website containing the embedded script, and is deactivated or deleted thereafter.

**B/ Log File Information**

Your web browser automatically sends information to every website you visit, including ours. For example, our server logs may receive and record information such as the pages you access on the Services, referring URLs, your browser type, your operating system, the date and time of your visit, and the duration of your visit to each page.

**C/ User Agent Strings**

Log file information may also include a user agent string, a series of characters automatically sent with your Internet requests that provide information necessary for smooth Internet communications, such as the operating system and browser you used. A user agent string might be used to identify the device originating a message.

**D/ Location Data**

Betates.com may also request access to or otherwise receive information about your device location when you access the Services. Your location data may be based on your IP address and other location-aware technologies. We use location data in connection with providing the Services and to help improve the Services.

**E/ Unique Identification Number**

Betates.com may assign your computer or mobile device a unique identification number ("Unique ID") based on log file information when you access the Services. Betates.com may set a cookie on your device containing, amongst other things, the device's Unique ID. Betates.com does not share the Unique ID or any associated data with any third parties.

**2.3. Information Betates.com.Com Receives From Third Parties**

Betates.com receives information about you from third parties. We may combine the information we receive from third parties with information we collect through the Services. If we do so, Betates.com will treat the combined information in accordance with the practices described in this Privacy Policy, plus any additional restrictions imposed by the source of the data.

**3. HOW Betates.com USES INFORMATION**

We use information for our legitimate interests, including for the following purposes:

**3.1. Providing Our Services**

As stated above, Betates.com uses information such as your first and last name, telephone number, email address, or other contact information we obtain from you, our customers, or our business partners, for the purposes of providing, enhancing, or improving our services and products.

**3.2. Communications With You**

Betates.com maintains one or more contact lists (with email addresses and other information) to allow us to communicate with individuals who do business with us or who have expressed an interest in the Services. We may contact you to confirm your purchases or respond to requests that you make, notify you of changes to your account or the Services, for marketing purposes, or to otherwise inform you of information related to our business or your account with us.

**3.3. Website Administration And Customization**

Betates.com uses the information we collect about you for a variety of website administration and customization purposes. For example, we use your information to process your registration request, provide you with services and communications that you have requested, send you email updates and other communications, customize features that appear on and off the Services, measure Services traffic, measure traffic patterns, and improve the Services.

**3.4. Consent**

We also use information with your consent, including for the following purposes: to allow you to participate in our surveys; to serve advertising tailored to your interests on our Services and third party services; and to fulfill any other purpose disclosed to you and with your consent.

Some of our lawful bases for processing your information stem from our customers on whose behalf we provide services.

Betates.com uses information that does not identify you for any purpose except as prohibited by applicable law. For information on your rights and choices regarding how we use your information, please see Section 6 below.

**4. HOW Betates.com SHARES INFORMATION**

**4.1. Sharing With Our Service Providers**

We provide your information to our third party service providers, contractors, and business partners for the purpose of delivering services to you as well as for purposes related to Services administration and operation, including conducting analytics. When sharing information for the purpose of providing you with the services you request, we will share your information only as necessary for the third party working on Betates.com's behalf to complete its work for us. For example, if you use a credit or debit card to complete a transaction on the Services, we will share your credit or debit card number with a payment processing company in order to complete your transaction.

**4.2. Security And Compliance With The Law**

We reserve the right to disclose your information to appropriate third parties if we are required to do so by law or we believe that such action is necessary in order (a) to comply with a legal process such as a search warrant, subpoena, or court order; (b) to protect the company's rights and property; (c) to investigate reports of users sending material using a false email address or users sending harassing, threatening, or abusive messages; (d) to protect against misuse or unauthorized use of the Website or Services; or (e) to respond to emergencies, such as when we believe someone's physical safety is at risk. Betates.com may be required to disclose information in a life-threatening emergency or in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.

**4.3. Transfer Of Business**

Over time, Betates.com may buy or sell various assets. In the event that we sell some or all of our assets, or our company is acquired by another company, or during the negotiation of such sale or acquisition, our databases and any information we collect may be among the transferred or negotiated assets.

**4.4 Consent**

We may share your information for any other purpose disclosed to you and with your consent.

Without limiting the foregoing, in our sole discretion, Betates.com shares information that does not identify you with third parties, for any purpose except as prohibited by applicable law. For information on your rights and choices regarding how we share your information, please see Section 6 below.

**5. THIRD PARTY ANALYTICS AND ONLINE TRACKING**

Betates.com works with certain third parties (including analytics companies) to provide us with information regarding traffic on and use of the Services. Some of these parties collect information when you visit the Services or other online websites and services. These third parties set and access their own tracking technologies (including cookies, embedded scripts, and web beacons) and may otherwise collect or have access to your IP address or other unique identifier, log information, and related information about you. These tracking technologies, including the Google Analytics, may be used to assist in providing analytics, marketing, and for other purposes. Betates.com does not control the information collection, use, or sharing practices of such third party providers, and we encourage you to consult their online policies. Some third parties may collect information about your online activities over time and across different websites when you use the Services.

**6. YOUR RIGHTS AND CHOICES**

**6.1. Opt-Out Of Email Communications**

If you are a registered member of the Services, you can make changes to your account information by logging into the Website and modifying your preferences. If you do not wish to receive email notifications from us, you may opt-out by contacting us at  **EMAIL**  with your request. In addition, certain email communications we send to you, such as newsletters and promotional announcements, contain a clearly worded "Unsubscribe" link allowing you to withdraw your permission for future mailings. Please note that we reserve the right to send you certain communications relating to your account or use of the Services and these transactional account messages may be unaffected even if you opt-out from marketing communications.

**6.2. Blocking Or Deleting Cookies**

You can manually delete cookies, which are normally located in your temporary Internet folder or cookie folder. You can also reset the preferences in your web browser to notify you when you have received a cookie or, alternatively, to refuse to accept cookies. Deleting or blocking cookies will limit your ability to make online purchases on the Services and to use and access portions of the Services that require logging in with a username and password, and may affect other functionality.

**7. SECURITY**

Betates.com implements reasonable administrative, physical, and technical security measures to help protect your data from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. However, no physical or electronic security system is impenetrable. Betates.com cannot guarantee the security of the Service's servers or databases, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet.

**8. POLICY REGARDING CHILDREN**

The Services are not intended for use by children under the age of sixteen years of age. Betates.com does not knowingly collect information from children under the age of sixteen. If you are a parent or guardian, and believe we have collected information about your child in violation of this policy, please contact us at the address set forth in Section 10 below.

**9. CHANGES TO THIS PRIVACY POLICY**

We will occasionally update this Privacy Policy in response to changing business circumstances and legal developments. If there are material changes to this Privacy Policy or in how we use your information, we will prominently post such changes prior to implementing the change. We encourage you to periodically review this Privacy Policy to be informed of how we are collecting and using your information.

**10. FOR MORE INFORMATION**

If you have any questions or complaints about this Privacy Policy, or you wish to exercise your opt-out rights hereunder or access or correct data applicable to you, please contact us
`;

export const INSTRUCTIONS_MARKDOWN = `
**Step 1:** You need to create an account by clicking “Login” button on the home page

![Home screen](/generated/instructions/1.png)

**Step 2:** Create an Account

You have 2 ways to create an account:

1. Use your Google account

2. Use your email

![Create an account](/generated/instructions/2.png)

Please read our Terms of Service and check ”I've read and accept the terms and conditions” then click "Next" to start using Betates.:

![Terms of Services](/generated/instructions/3-1.png)

**Step 3:** Click “Add a new design” to create a design:

![Add a new design](/generated/instructions/4.png)

**To have a best experience, your image should be:**

- Sharp (not blurry)
- Empty of furniture
- Floor is visible
- Well lit
- PNG/JPG are supported

You need to fill in your information to right side of page: “Enter location” and “Image name”

Drag and drop your image here or browse from device.

![Drag and drop](/generated/instructions/5.png)

![Drag and drop](/generated/instructions/6.png)

After uploading the image, you will see this screen:

![Uploaded](/generated/instructions/7.png)

Please choose “Follow progress” button if you want to start to stage immediately. Our Editors will handle within 1 – 10 minutes.

Or you can choose “Upload more images” button if you’d like to upload many images in the same time.

In Progress screen, the photo will be **in processing**. Please wait for 1 – 10 minutes.

![Progress](/generated/instructions/8.png)

Once our editor has finished processing the photo, you will see a notification on the progress screen.

![Progress Done](/generated/instructions/9.png)

**Step 4:** When the photo is in Ready. You can click to “Edit” button on the photo to start to stage by yourself.

![Location](/generated/instructions/10.png)

**Step 5:**

Go to “Room bundle” to choose the room package you want or “By category” to see a list of models

“Same location” are the models you have already placed in other images in the same location and then now you’d like to use the same one for other photos.

![Image Editing](/generated/instructions/11.png)

**Step 6:** You can stage by yourself

Please click and choose each model >> dragging them onto the image, and then place, arrange the furniture properly.

![Models](/generated/instructions/12.png)

You can use some features to make adjustments such as:

- Remove
- Replication
- Flip
  + Horizontal
  + Vertical
  + Depth
- Scale
- Rotation
…

![Controls 1](/generated/instructions/13.png)

![Controls 2](/generated/instructions/14.png)

**Step 7:** After you finish dragging and dropping and arranging, please choose “Render” button >> “Follow Progress” to see the results.

Click “Resolution” and “Output format” to choose the render setting. Wait rendering for 2 to 10 minutes, the max resolution is 12 megapixels.

![Render](/generated/instructions/15.png)

You can click “Keep Edit” to continue editing until you are satisfied with the product.

![Render sent](/generated/instructions/16.png)

After finishing the rendering, please go to the "Progress" to see results.

![Progress](/generated/instructions/17.png)

Select “Location” and click “View” to see the results were done.

![Image](/generated/instructions/18.png)

Go to “Location” if you want to continue editing.

![Locations](/generated/instructions/19.png)

`;
