import localConfigs from './generated/local';
import devConfigs from './generated/dev';
import prodConfigs from './generated/prod';

// eslint-disable-next-line import/no-mutable-exports
let configs = {};

switch (process.env.REACT_APP_ENV) {
  case 'dev':
    configs = devConfigs;
    break;
  case 'prod':
    configs = prodConfigs;
    break;
  default:
    configs = localConfigs;
}

export default configs;
