import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import Sidebar from 'components/Common/Sidebar';
import Footer from 'components/Common/Footer';
import { useToastContext, Icon, ImageType } from 'auto-design-common';
import { useProgressContext } from 'components/ProgressContext';
import crossIcon from 'assets/images/ic-line-x-mark.svg';
import checkIcon from 'assets/images/ic-line-check.svg';
import * as api from 'utils/api';
import { useAppContext } from 'components/AppContext';
import trashIcon from 'assets/images/ic-line-trash.svg';
import panoMark from 'assets/images/360-mark.svg';
import Empty from './Empty';
import useTranslation from '../../hooks/useTranslation';

function Badge({
  type,
  status,
}) {
  const { tProgress } = useTranslation();
  if (type === 'create_frame') {
    if (status === 'processing') {
      return (
        <div className="badge processing">
          {tProgress('status.processing')}
        </div>
      );
    }

    if (status === 'ready') {
      return (
        <div className="badge ready">
          <Icon
            src={checkIcon}
            width={16}
            stroke="white"
          />
          {tProgress('status.ready')}
        </div>
      );
    }

    if (status === 'failed') {
      return (
        <div className="badge failed">
          <Icon
            src={crossIcon}
            width={16}
            fill="white"
          />
          {tProgress('status.prefix.failedWhile')}
          {' '}
          {tProgress('status.processing')}
        </div>
      );
    }

    if (status === 'canceled') {
      return (
        <div className="badge failed">
          <Icon
            src={crossIcon}
            width={16}
            fill="white"
          />
          {tProgress('status.prefix.canceledWhile')}
          {' '}
          {tProgress('status.processing')}
        </div>
      );
    }
  }

  if (type === 'render') {
    if (status === 'processing') {
      return (
        <div className="badge processing">
          {tProgress('status.processing')}
        </div>
      );
    }

    if (status === 'ready') {
      return (
        <div className="badge finished-rendering">
          <Icon
            src={checkIcon}
            width={16}
            stroke="white"
          />
          {tProgress('status.prefix.finished')}
          {' '}
          {tProgress('status.rendering')}
        </div>
      );
    }

    if (status === 'failed') {
      return (
        <div className="badge failed">
          <Icon
            src={crossIcon}
            width={16}
            fill="white"
          />
          {tProgress('status.prefix.failedWhile')}
          {' '}
          {tProgress('status.rendering')}
        </div>
      );
    }

    if (status === 'canceled') {
      return (
        <div className="badge failed">
          <Icon
            src={crossIcon}
            width={16}
            fill="white"
          />
          {tProgress('status.prefix.canceledWhile')}
          {' '}
          {tProgress('status.rendering')}
        </div>
      );
    }
  }

  if (type === 'refund') {
    return (
      <div className="badge refund">
        {tProgress('status.refund')}
      </div>
    );
  }

  return null;
}

export default function Progress() {
  const { tProgress } = useTranslation();
  const { progresses, loading, markAllProgressesAsRead } = useProgressContext();
  const { toastError } = useToastContext();
  const history = useHistory();
  const { request } = useAppContext();
  const [clearing, setClearing] = useState(false);

  return (
    <div className="main progress-page">
      <Sidebar />
      <div className="right-part">
        {!loading && progresses.length === 0 ? (
          <Empty />
        ) : (
          <div className="content">
            <div className="header">
              <div className="header-left">

                <div className="title">
                  {tProgress('title')}
                </div>
                <div className="info">
                  {tProgress('info')}
                </div>
              </div>
              <Button
                variant="secondary"
                disabled={clearing}
                onClick={async () => {
                  setClearing(true);
                  const { success, error } = await markAllProgressesAsRead();

                  if (!success) {
                    toastError(error);
                  }

                  setClearing(false);
                }}
              >
                <Icon src={trashIcon} />
                {tProgress('clearNotifications')}
              </Button>
            </div>
            <div className="progress-list row">
              {progresses.map(progress => (
                <div className="col-xxl-3 col-lg-4 col-md-6" key={progress.id}>
                  <div
                    className={classNames(
                      'progress-item',
                      ((progress.type === 'create_frame' && progress.status === 'ready') || (progress.type === 'render' && progress.status === 'ready') || (progress.type === 'render' && progress.status === 'failed')) && 'selectable',
                    )}
                    onClick={() => {
                      if (progress.type === 'create_frame' && progress.status === 'ready') {
                        request(api.markProgressAsRead(progress.id));
                        history.push(`/images/${progress.image.id}/edit`);
                      }

                      if (progress.type === 'render' && progress.status === 'ready') {
                        request(api.markProgressAsRead(progress.id));
                        history.push(`/images/${progress.image.id}/view?progressId=${progress.id}`);
                      }

                      if (progress.type === 'render' && progress.status === 'failed') {
                        request(api.markProgressAsRead(progress.id));
                        history.push(`/images/${progress.image.id}/edit`);
                      }
                    }}
                  >
                    {progress.image.type === ImageType.PANO && (
                      <img
                        className="mark"
                        src={panoMark}
                        alt="360-degree"
                      />
                    )}

                    <Badge type={progress.type} status={progress.status} />
                    <img src={progress.thumbnail || progress.image.thumbnail} alt={progress.image.name} />
                    <div className="location-name">
                      {progress.image.location.name}
                    </div>
                    <div className="image-name">
                      {progress.image.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}
