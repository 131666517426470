import { useEffect, useState, useCallback } from 'react';
import { useAppContext } from 'components/AppContext';
import { useToastContext } from 'auto-design-common';

export default function useFetch(fn, defaultResult) {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(defaultResult);
  const [error, setError] = useState(null);
  const { request } = useAppContext();
  const { toastError } = useToastContext();

  const trigger = useCallback(() => {
    setLoading(true);

    request(fn())
      .then(res => {
        setLoading(false);

        if (res.success) {
          setResult(res.result);
        } else {
          setError(res.error);
          toastError(res.error);
        }
      });
  }, [fn, request, toastError]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  return {
    loading,
    result,
    error,
    trigger,
  };
}
