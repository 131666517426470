import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import metaQuestImg from 'assets/images/meta-quest.png';
import useTranslation from '../../hooks/useTranslation';

export default function VRNotSupported({
  onClose,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tVRNotSupportedModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.vrNotSupported' });

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-vr-not-supported"
    >
      <Modal.Body>
        <img
          src={metaQuestImg}
          alt="Intro"
        />
        <div className="title">
          {tVRNotSupportedModal('title')}
        </div>
        <div className="description">
          {tVRNotSupportedModal('description')}
        </div>
        <Button onClick={onClose}>
          {tCommon('gotIt')}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
