import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getRoom, getRooms } from 'utils/api';
import { useToastContext } from 'auto-design-common';
import RoomList from './Shared/RoomList';
import CategorySelect from './Shared/CategorySelect';
import useTranslation from '../../hooks/useTranslation';

export default function RoomCategories() {
  const { tCatalog } = useTranslation();
  const history = useHistory();
  const { roomId } = useParams();
  const { toastError } = useToastContext();
  const [room, setRoom] = useState(null);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    getRoom(roomId)
      .then(setRoom)
      .catch(toastError);
  }, [roomId, toastError]);

  useEffect(() => {
    getRooms()
      .then(setRooms)
      .catch(toastError);
  }, [toastError]);

  if (!room) {
    return null;
  }

  return (
    <RoomList
      fetchFunc={async () => room.children}
      renderHeader={() => (
        <CategorySelect
          currentItem={room}
          items={rooms}
          onBack={() => history.replace('/catalog/rooms')}
          onSelectItem={(item) => history.push(`/catalog/rooms/${item.id}/categories`)}
        />
      )}
      renderChildrenCount={(category) => (
        <>
          {category.itemsCount}
          {' '}
          {tCatalog('items')}
        </>
      )}
      onClick={(room) => {
        history.push(`/catalog/rooms/${roomId}/categories/${room.id}/items`);
      }}
    />
  );
}
