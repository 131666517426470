import { TOUR_ZOOM_DURATION } from 'constants/common';
import * as THREE from 'three';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { createPanoViewer } from './3d';
import { getEventCoords } from './mouse';

const setupController = (index, canvasData) => {
  const controller = canvasData.renderer.xr.getController(index);

  if (!controller) {
    return;
  }

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0, 0, 0, -10], 3));
  geometry.setAttribute('color', new THREE.Float32BufferAttribute([0.5, 0.5, 0.5, 0, 0, 0], 3));
  const material = new THREE.LineBasicMaterial({ vertexColors: true, blending: THREE.AdditiveBlending });
  const line = new THREE.Line(geometry, material);

  controller.add(line);
  canvasData.scene.add(controller);
  canvasData.controllers.push(controller);

  const controllerModelFactory = new XRControllerModelFactory();
  const controllerGrip = canvasData.renderer.xr.getControllerGrip(index);
  controllerGrip.add(controllerModelFactory.createControllerModel(controllerGrip));
  canvasData.scene.add(controllerGrip);
};

export const setupTourEditor = async ({
  width,
  height,
  images,
}) => {
  THREE.Cache.enabled = true;

  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff);
  const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);

  const canvasData = {
    renderer,
    scene,
    panoViewer: createPanoViewer(),
    raycaster: new THREE.Raycaster(),
    hotpots: [],
    spheres: {},
  };

  const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1100);
  camera.target = new THREE.Vector3(0, 0, 0);
  canvasData.camera = camera;

  Object.keys(images).map(async id => {
    const geometry = new THREE.SphereGeometry(1000, 100, 100);
    geometry.scale(-1, 1, 1);

    const material = new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(images[id]),
      transparent: false,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.rotateY(-Math.PI / 2);
    mesh.position.copy(canvasData.camera.position);
    mesh.visible = false;
    canvasData.spheres[id] = mesh;
    scene.add(mesh);
  });

  scene.add(new THREE.HemisphereLight(0xFFFFFF, 0xFFFFFF));

  canvasData.controllers = [];
  setupController(0, canvasData);
  setupController(1, canvasData);
  canvasData.controllerRaycaster = new THREE.Raycaster();

  return canvasData;
};

export const createAnimator = () => {
  let canceled = false;

  const animate = ({
    canvasData,
  }) => {
    if (canceled) {
      return;
    }

    canvasData.current.renderer.setAnimationLoop(() => animate({
      canvasData,
    }));

    const { panoViewer, camera, scene, renderer, hotpots, cameraAnimation, fadeOutAnimation, fadeInAnimation } = canvasData.current;

    panoViewer.handleAnimate();
    const { x, y, z } = panoViewer.calculateCameraPosition();

    camera.target.x = x;
    camera.target.y = y;
    camera.target.z = z;

    camera.lookAt(camera.target);

    hotpots.forEach(({ mixer, clock }) => {
      const delta = clock.getDelta();
      mixer.update(delta);
    });

    if (cameraAnimation) {
      const { clock, mixer } = cameraAnimation;
      const delta = clock.getDelta();
      mixer.update(delta);
      camera.lookAt(camera.target);
      camera.updateProjectionMatrix();
    }

    if (fadeOutAnimation) {
      const { clock, mixer } = fadeOutAnimation;
      const delta = clock.getDelta();
      mixer.update(delta);
    }

    if (fadeInAnimation) {
      const { clock, mixer } = fadeInAnimation;
      const delta = clock.getDelta();
      mixer.update(delta);
    }

    renderer.render(scene, camera);
  };

  const cancel = () => {
    canceled = true;
  };

  return {
    animate,
    cancel,
  };
};

export const calculateMousePosition = ({
  event,
  containerRef,
  width,
  height,
}) => {
  const { x, y } = getEventCoords(event);
  const containerRect = containerRef.current.getBoundingClientRect();
  return {
    x: ((x - containerRect.left) / width) * 2 - 1,
    y: -((y - containerRect.top) / height) * 2 + 1,
  };
};

export const createHotpot = ({ position, id, source, destination, canvasData }) => {
  const { scene, hotpots } = canvasData.current;

  const geometry = new THREE.SphereGeometry(50, 32, 16);
  const material = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true });

  const sphere = new THREE.Mesh(geometry, material);
  sphere.objectId = id;
  sphere.position.copy(position.clone().lerp(new THREE.Vector3(0, 0, 0), 0.01));

  const placeHolder = new THREE.Mesh(geometry.clone(), material.clone());
  placeHolder.material.opacity = 0;
  placeHolder.material.transparent = true;
  placeHolder.objectId = id;
  placeHolder.position.copy(sphere.position);

  const scaleKF = new THREE.VectorKeyframeTrack('.scale', [0, 1], [0, 0, 0, 1, 1, 1]);
  const opacityKF = new THREE.NumberKeyframeTrack('.material.opacity', [0, 0.5, 1], [1, 1, 0]);
  const clip = new THREE.AnimationClip('Action', 1, [scaleKF, opacityKF]);
  const mixer = new THREE.AnimationMixer(sphere);
  const clipAction = mixer.clipAction(clip);
  clipAction.loop = THREE.LoopRepeat;
  clipAction.play();
  const clock = new THREE.Clock();

  hotpots.push({
    id,
    source,
    destination,
    clock,
    mixer,
    sphere,
    placeHolder,
  });

  scene.add(sphere);
  scene.add(placeHolder);

  return sphere;
};

export const zoomTo = (position, canvasData) => {
  const { camera } = canvasData.current;
  const oldTarget = camera.target;

  const fovKF = new THREE.NumberKeyframeTrack('.fov', [0, TOUR_ZOOM_DURATION], [camera.fov, 20]);

  const targetKF = new THREE.VectorKeyframeTrack('.target', [0, TOUR_ZOOM_DURATION], [
    oldTarget.x,
    oldTarget.y,
    oldTarget.z,
    position.x,
    position.y,
    position.z,
  ]);

  const clip = new THREE.AnimationClip('lookAt', TOUR_ZOOM_DURATION, [targetKF, fovKF]);
  const mixer = new THREE.AnimationMixer(camera);
  const clipAction = mixer.clipAction(clip);
  clipAction.loop = THREE.LoopOnce;
  clipAction.play();
  const clock = new THREE.Clock();

  canvasData.current.cameraAnimation = {
    mixer,
    clock,
  };

  return new Promise(resolve => {
    mixer.addEventListener('finished', () => {
      canvasData.current.cameraAnimation = null;
      resolve();
    });
  });
};

export const fade = async (fromImageId, toImageId, canvasData) => {
  const { spheres } = canvasData.current;

  Object.keys(spheres).forEach(id => {
    const sphere = spheres[id];
    sphere.visible = [fromImageId, toImageId].includes(parseInt(id));
  });

  spheres[fromImageId].material.transparent = true;
  spheres[toImageId].material.transparent = true;

  const createFadeAnimation = (startOpacity, endOpacity, object) => {
    const fade = new THREE.NumberKeyframeTrack('.material.opacity', [0, 2], [startOpacity, endOpacity]);
    const clip = new THREE.AnimationClip('fadeOut', 2, [fade]);
    const mixer = new THREE.AnimationMixer(object);
    const clipAction = mixer.clipAction(clip);
    clipAction.loop = THREE.LoopOnce;
    clipAction.play();
    const clock = new THREE.Clock();

    return {
      mixer,
      clock,
    };
  };

  canvasData.current.fadeOutAnimation = createFadeAnimation(1, 0, spheres[fromImageId]);
  canvasData.current.fadeInAnimation = createFadeAnimation(0, 1, spheres[toImageId]);

  return Promise.all([
    new Promise(resolve => {
      canvasData.current.fadeOutAnimation.mixer.addEventListener('finished', () => {
        spheres[fromImageId].material.transparent = false;
        canvasData.current.fadeOutAnimation = null;
        resolve();
      });
    }),
    new Promise(resolve => {
      canvasData.current.fadeInAnimation.mixer.addEventListener('finished', () => {
        spheres[toImageId].material.transparent = false;
        canvasData.current.fadeInAnimation = null;
        resolve();
      });
    }),
  ]);
};

const removeHotpot = (hotpot, canvasData) => {
  hotpot.mixer.stopAllAction();
  canvasData.current.scene.remove(hotpot.sphere);
  canvasData.current.scene.remove(hotpot.placeHolder);
};

export const removeAllHotpots = (canvasData) => {
  canvasData.current.hotpots.forEach(hotpot => {
    removeHotpot(hotpot, canvasData);
  });

  canvasData.current.hotpots = [];
};
