import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ReactLoading from 'components/Common/ReactLoading';
import { useAppContext } from 'components/AppContext';
import config from 'configuration';
import GetStarted from './Auth/GetStarted';
import Login from './Auth/Login';
import SignUp from './Auth/SignUp';
import Locations from './Locations';
import Landing from './Landing';
import Profile from './Profile';
import AddDesign from './AddDesign';
import PaymentSuccessful from './PaymentSuccessful';
import Catalog from './Catalog';
import Progress from './Progress';
import EditImage from './EditImage';
import Result from './Result';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfServices from './TermsOfServices';
import AcceptTermsOfServices from './AcceptTermsOfServices';
import Instructions from './Instructions';
import PasswordResetConfirmation from './Auth/PasswordResetConfirmation';
import TourEditor from './Tours/TourEditor';
import PublishedTour from './Tours/PublishedTour';
import MyTours from './Tours/MyTours';
import ApiKeys from './ApiKeys';
import Webhooks from './Webhooks';

function App() {
  const { user, loading } = useAppContext();

  useEffect(() => {
    const modifyWidget = () => {
      const widget = document.querySelector('iframe[title="chat widget"]');
      if (widget) {
        widget.style.bottom = '42px';
      } else {
        setTimeout(() => {
          modifyWidget();
        }, 2000);
      }
    };

    modifyWidget();
  }, []);

  if (loading) {
    return (
      <div className="full-screen-loading">
        <ReactLoading />
      </div>
    );
  }

  if (!user) {
    return (
      <Switch>
        <Route path="/published-tours/:tourVersionId">
          <PublishedTour />
        </Route>
        <Route path="/get-started">
          <GetStarted />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/password-reset-confirmation">
          <PasswordResetConfirmation />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-of-services">
          <TermsOfServices />
        </Route>
        <Route path="/instructions">
          <Instructions />
        </Route>
        {config.tenantId === '1' && (
          <Route path="/">
            <Landing />
          </Route>
        )}

        {config.tenantId === '1' ? (
          <Redirect to="/" />
        ) : (
          <Redirect to="/get-started" />
        )}

      </Switch>
    );
  }

  if (!user.readTos) {
    return (
      <AcceptTermsOfServices />
    );
  }

  return (
    <Switch>
      <Route path="/published-tours/:tourVersionId">
        <PublishedTour />
      </Route>
      <Route path="/password-reset-confirmation">
        <PasswordResetConfirmation />
      </Route>
      <Route path="/locations">
        <Locations />
      </Route>
      <Route path="/progress">
        <Progress />
      </Route>
      <Route path="/catalog">
        <Catalog />
      </Route>
      <Route path="/profile">
        <Profile />
      </Route>
      <Route path="/api-keys">
        <ApiKeys />
      </Route>
      <Route path="/webhooks">
        <Webhooks />
      </Route>
      <Route path="/add-design">
        <AddDesign />
      </Route>
      <Route path="/images/:imageId/edit">
        <EditImage />
      </Route>
      <Route path="/images/:imageId/view">
        <Result />
      </Route>
      <Route path="/tours/:tourId/edit">
        <TourEditor />
      </Route>
      <Route path="/tours">
        <MyTours />
      </Route>
      <Route path="/payment-successful">
        <PaymentSuccessful />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/terms-of-services">
        <TermsOfServices />
      </Route>
      <Route path="/instructions">
        <Instructions />
      </Route>
      <Redirect to="/add-design" />
    </Switch>
  );
}

export default App;
