import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Sidebar from 'components/Common/Sidebar';
import Footer from 'components/Common/Footer';
import RoomCategories from './RoomCategories';
import Rooms from './Rooms';
import RoomItems from './RoomItems';
import Categories from './Categories';
import CategoryItems from './CategoryItems';

export default function Locations() {
  return (
    <div className="main catalog">
      <Sidebar />
      <div className="right-part">
        <div className="content">
          <Switch>
            <Route path="/catalog/rooms/:roomId/categories/:childRoomId/items">
              <RoomItems />
            </Route>
            <Route path="/catalog/rooms/:roomId/categories">
              <RoomCategories />
            </Route>
            <Route path="/catalog/rooms">
              <Rooms />
            </Route>
            <Route path="/catalog/categories/:categoryId/items">
              <CategoryItems />
            </Route>
            <Route path="/catalog/categories">
              <Categories />
            </Route>
            <Redirect to="/catalog/rooms" />
          </Switch>
        </div>
        <Footer />
      </div>
    </div>
  );
}
