// Settings configured here will be merged into the final config object.
export default {
  apiUrl: 'http://localhost:3001/api',
  paypalClientId: 'Ad5hzPlW8VG9SW166gMUH6vyzRp39cDytIidPNOztig3ghkvdw-vgz1k92wjxOWKPD0jzq_4Ed_nKxrR',
  googleClientId: '547053059719-ivc5i0jtqb8m5n0e1kv3i9k39n1q6us3.apps.googleusercontent.com',
  pusherKey: '18ae0063051bb0482307',
  pusherCluster: 'ap1',
  tenantId: process.env.REACT_APP_TENANT_ID,
  language: 'en',
  privacyPolicyUrl: '/privay-policy',
  termsOfServicesUrl: './terms-of-services',
  disablePanoImage: false,
  disableEmailLogin: false,
};
