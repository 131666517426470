import React from 'react';
import { useHistory } from 'react-router-dom';
import vrImg from 'assets/images/virtual-reality.svg';
import { Button } from 'react-bootstrap';
import playIcon from 'assets/images/ic-fill-play.svg';
import uploadIcon from 'assets/images/ic-fill-upload.svg';
import { Icon } from 'auto-design-common';
import { INSTRUCTION_2D_VIDEO } from 'constants/common';
import useTranslation from '../../../hooks/useTranslation';

export default function NoTour() {
  const { tCommon, tMyTours } = useTranslation();
  const history = useHistory();

  return (
    <div className="no-tour">
      <img src={vrImg} alt="Welcome" />
      <div className="title">
        {tMyTours('noTour.title')}
      </div>
      <div className="description">
        {tMyTours('noTour.description')}
      </div>
      <div className="buttons">
        <Button
          variant="secondary"
          onClick={() => {
            window.open(INSTRUCTION_2D_VIDEO);
          }}
        >
          <Icon
            src={playIcon}
          />
          {tMyTours('noTour.videoIntroduction')}
        </Button>
        <Button onClick={() => {
          history.push('/add-design');
        }}
        >
          <Icon src={uploadIcon} fill="white" />
          {tCommon('upload')}
        </Button>
      </div>
    </div>
  );
}
