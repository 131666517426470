import React from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkGFM from 'remark-gfm';
import _ from 'lodash';
import { TERMS_OF_SERVICE_MARKDOWN } from '../../i18n/generated/en/common';
import useTranslation from '../../hooks/useTranslation';

export default function TermsOfServices() {
  const { tCommon } = useTranslation();
  const title = tCommon('termsOfServices').split(' ');
  title[2] = _.capitalize(title[2]);
  return (
    <div className="terms-of-services-content">
      <h1>{title.join(' ')}</h1>
      <br />
      <ReactMarkdown remarkPlugins={[RemarkGFM]}>
        {TERMS_OF_SERVICE_MARKDOWN}
      </ReactMarkdown>
    </div>
  );
}
