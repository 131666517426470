import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import isEmail from 'validator/lib/isEmail';
import { useToastContext } from 'auto-design-common';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import { useTranslation } from 'react-i18next';
import useTranslationCustom from '../../hooks/useTranslation';

export default function TopUp({
  onClose,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'modal.contactForService' });
  const { tCommon } = useTranslationCustom();
  const SERVICES = [
    {
      id: 1,
      name: t('1'),
    },
    {
      id: 2,
      name: t('2'),
    },
    {
      id: 3,
      name: t('3'),
    },
    {
      id: 4,
      name: t('4'),
    },
    {
      id: 5,
      name: t('5'),
    },
  ];

  const [email, setEmail] = useState('');
  const { request } = useAppContext();
  const { toastSuccess, toastError } = useToastContext();
  const [message, setMessage] = useState('');
  const [service, setService] = useState(SERVICES[0].name);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const submit = async () => {
    setSubmitting(true);
    const { success, error } = await request(api.submitServiceRequest({
      email,
      message,
      recaptchaToken,
      service,
    }));

    if (success) {
      toastSuccess(t('submitServiceSuccess'));
      onClose();
    } else {
      toastError(error);
    }
    setSubmitting(false);
  };

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-contact-for-service"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content">
          <Form.Group className="form-group">
            <Form.Label>{t('label1')}</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={e => {
                setService(e.target.value);
              }}
              value={service}
            >
              {SERVICES.map(service => (
                <option
                  key={service.id}
                  value={service.name}
                >
                  {service.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              {t('label2')}
              {' '}
              <span className="required-mark">*</span>
            </Form.Label>
            <Form.Control
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              {t('label3')}
            </Form.Label>
            <Form.Control
              value={message}
              placeholder={t('label3Placeholder')}
              onChange={e => setMessage(e.target.value)}
              required
            />
          </Form.Group>
          <div className="recaptcha">
            <ReCAPTCHA
              sitekey="6Lf_z0AeAAAAADETZd71iDf0nEg61Q8w8zXpWM51"
              onChange={setRecaptchaToken}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting || !isEmail(email)}
          onClick={submit}
        >
          {tCommon('send')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
