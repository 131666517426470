import React from 'react';
import { useHistory } from 'react-router-dom';
import { getRooms } from 'utils/api';
import RoomList from './Shared/RoomList';
import TypeSwitch from './Shared/TypeSwitch';
import useTranslation from '../../hooks/useTranslation';

export default function RoomCategories() {
  const { tCatalog } = useTranslation();
  const history = useHistory();

  return (
    <RoomList
      fetchFunc={getRooms}
      renderHeader={() => (
        <TypeSwitch page="rooms" />
      )}
      renderChildrenCount={(room) => (
        <>
          {room.children.length}
          {' '}
          {tCatalog('categories')}
        </>
      )}
      onClick={(room) => {
        history.push(`/catalog/rooms/${room.id}/categories`);
      }}
    />
  );
}
