import React, { useRef } from 'react';
import ReactLoading from 'components/Common/ReactLoading';
import { Modal, Button } from 'react-bootstrap';
import { deleteWatermark as apiDeleteWatermark, getWatermarks, uploadWatermark as apiUploadWatermark } from 'utils/api';
import useFetch from 'hooks/useFetch';
import defaultWatermark from 'assets/generated/images/watermark.png';
import crossIcon from 'assets/images/ic-line-x-mark.svg';
import useMutate from 'hooks/useMutate';
import { Icon } from 'auto-design-common';
import useTranslation from '../../hooks/useTranslation';

function Watermark({
  src,
  onDelete,
  onSelect,
}) {
  return (
    <div
      className="item watermark col-sm-6"
      onClick={onSelect}
    >
      {onDelete && (
        <div
          className="delete-btn"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Icon
            src={crossIcon}
            width={16}
            fill="white"
          />
        </div>
      )}
      <img src={src} alt="Watermark" />
    </div>
  );
}

export default function AddWatermark({
  onClose,
  onSelect,
}) {
  const { useTranslationOrigin, tCommon } = useTranslation();
  const { t: tAddWatermarkModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.addWatermark' });

  const { submitting: uploading, mutate: uploadWatermark } = useMutate(apiUploadWatermark);
  const { mutate: deleteWatermark } = useMutate(apiDeleteWatermark);
  const { result: watermarks, loading, trigger: triggerFetch } = useFetch(getWatermarks, []);

  const fileInputRef = useRef();

  const upload = async (file) => {
    const res = await uploadWatermark(file);
    if (res.success) {
      triggerFetch();
    }
  };

  const del = async(id) => {
    const res = await deleteWatermark(id);
    if (res.success) {
      triggerFetch();
    }
  };

  const select = url => {
    try {
      const { pathname } = new URL(url);
      onSelect(`${window.origin}/public-files${pathname}`);
    } catch (e) {
      // Just a phat, not a full url
      onSelect(url);
    }

    onClose();
  };

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      size="md"
      id="modal-add-watermark"
    >
      <Modal.Header>
        <Modal.Title>
          {tAddWatermarkModal('title')}
        </Modal.Title>
        <div
          className="close"
          onClick={onClose}
        >
          <img
            alt="close"
            src={crossIcon}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <Watermark
            onSelect={() => select(defaultWatermark)}
            src={defaultWatermark}
          />
          {watermarks.map(watermark => (
            <Watermark
              onSelect={() => select(watermark.fileUrl)}
              key={watermark.id}
              src={watermark.fileUrl}
              onDelete={() => del(watermark.id)}
            />
          ))}
          {(loading || uploading) && (
            <div className="col-sm-6 item loading">
              <ReactLoading />
            </div>
          )}
          <div className="col-sm-6 item upload">
            <div>
              <Button
                variant="dark"
                onClick={() => {
                  fileInputRef.current.click();
                }}
                disabled={uploading || loading}
              >
                {tCommon('upload')}
              </Button>
            </div>
          </div>
        </div>
        <input
          type="file"
          hidden
          accept="image/png,image/jpeg"
          ref={fileInputRef}
          onChange={(e) => {
            upload(e.target.files[0]);
            e.target.value = '';
          }}
        />
      </Modal.Body>
    </Modal>
  );
}
