import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastContext } from 'auto-design-common';
import * as api from 'utils/api';
import emitter, { Events } from 'utils/event';
import { bell, jingle, error } from 'utils/sound';
import { useAppContext } from './AppContext';
import { useNotificationContext } from './Notification';

const ProgressContext = React.createContext();

export function ProgressContextProvider({
  children,
}) {
  const history = useHistory();
  const { user, request } = useAppContext();
  const { notifyError, notifySuccess } = useNotificationContext();
  const c = useToastContext();
  const { toastError } = c;
  const [progresses, setProgresses] = useState([]);
  const [loading, setLoading] = useState(true);

  const markProgressAsRead = useCallback(async (progressId) => request(api.markProgressAsRead(progressId)), [request]);

  const markAllProgressesAsRead = useCallback(async () => {
    const res = await request(api.markAllProgressesAsRead());
    setProgresses([]);
    return res;
  }, [request]);

  useEffect(() => {
    if (!user) {
      return () => {};
    }

    const getProgresses = async () => {
      setLoading(true);

      const { result: progresses, error } = await request(api.getProgresses());

      if (progresses) {
        setProgresses(progresses);
      } else {
        toastError(error);
      }

      setLoading(false);
    };

    getProgresses();

    const listener1 = emitter.addListener(Events.PROGRESS_ADDED, (data) => {
      if (data.type === 'refund' && !data.read) {
        notifySuccess(`You have been refunded for your image: "${data.image.name}"`, () => {
          history.push('/progress');
        });
        error.play();
      }

      getProgresses();
    });

    const listener2 = emitter.addListener(Events.PROGRESS_UPDATED, data => {
      if (data.type === 'create_frame' && !data.read) {
        if (data.status === 'ready') {
          notifySuccess(`Your image "${data.image.name}" is ready!`, () => {
            history.push(`/images/${data.image.id}/edit`);
          });
          bell.play();
        }

        if (data.status === 'failed') {
          notifyError(`Your image "${data.image.name}" has been marked as failed! You will receive a refund soon.`, () => {
            history.push('/progress');
          });
          error.play();
        }
      }

      if (data.type === 'render' && !data.read) {
        if (data.status === 'ready') {
          jingle.play();
          notifySuccess(`Your image "${data.image.name}" has been rendered successfully!`, () => {
            history.push(`/images/${data.image.id}/view?progressId=${data.id}`);
          });
        }

        if (data.status === 'failed') {
          notifyError(`Your image "${data.image.name}" has some error while rendering`, () => {
            history.push('/progress');
          });
          error.play();
        }
      }

      getProgresses();
    });
    const listener3 = emitter.addListener(Events.LOCATION_DELETED, getProgresses);

    return () => {
      listener1.remove();
      listener2.remove();
      listener3.remove();
      setProgresses([]);
    };
  }, [toastError, user, request, notifySuccess, notifyError, history]);

  const contextValue = useMemo(() => ({
    progresses,
    loading,
    markProgressAsRead,
    markAllProgressesAsRead,
  }), [
    progresses,
    loading,
    markProgressAsRead,
    markAllProgressesAsRead,
  ]);

  return (
    <ProgressContext.Provider value={contextValue}>
      {children}
    </ProgressContext.Provider>
  );
}

export function useProgressContext() {
  return React.useContext(ProgressContext);
}
